import { Form, Input, Radio, Tag, TreeSelect } from 'antd';
import {
    useHrmDeletePosition,
    useHrmPositionsPageableState,
    useHrmPositionsPagingValue,
    useHrmSavePosition,
    useSetHrmPositionsFilter,
} from 'applets/hrm/Positions/state';
import { useHrmInstitutionAppletsValue } from 'applets/hrm/state';
import { useCallback, useEffect, useMemo, useState } from 'react';
import EntityTable from 'views/components/EntityTable';

const schema = [{
    key: 'id',
    dataIndex: 'id',
    title: '#',
    width: 60,
}, {
    key: 'name',
    dataIndex: 'name',
    title: '岗位名称',
    width: 200,
}, {
    key: 'permissions',
    dataIndex: 'permissions',
    title: '授权',
    render: (permissions) => (
        <>{permissions.map((permission) => <Tag key={permission}>{permission}</Tag>)}</>
    ),
}, {
    key: 'members',
    dataIndex: 'members',
    title: '成员数量',
    width: 100,
}, {
    key: 'deletable',
    dataIndex: 'deletable',
    title: '类型',
    render: (deletable) => (deletable ? <Tag color="green">用户</Tag> : <Tag color="warning">系统</Tag>),
    width: 100,
}, {
    key: 'updatedAt',
    dataIndex: 'updatedAt',
    title: '最后更新时间',
    width: 180,
}];

export default () => {
    const { total = 0, items: positions = [] } = useHrmPositionsPagingValue();
    const setFilter = useSetHrmPositionsFilter();
    const [pageable = 0, setPageable = null] = useHrmPositionsPageableState();

    const [form] = Form.useForm();
    const institutionId = Form.useWatch('institutionId', form);
    useEffect(() => {
        form.setFieldValue('permissions', []);
    }, [institutionId]);

    const applets = useHrmInstitutionAppletsValue();
    const appletsExpandKeys = useMemo(() => applets.map(({ value }) => value), [applets]);

    const [mode, setMode] = useState(null);
    const isNewMode = useMemo(() => mode === 'new', [mode]);
    const isEditMode = useMemo(() => mode === 'edit', [mode]);
    const onNew = useCallback(() => {
        return { operation: 'INTERSECTION' };
    }, []);
    const savePosition = useHrmSavePosition();
    const onSave = useCallback(async ({ id = null, institutionId = null, name, operation, authorities }) => {
        await savePosition({
            position: {
                id,
                institutionId,
                name,
                operation,
                authorities,
            },
        });
    }, [savePosition]);
    const deletePosition = useHrmDeletePosition();
    const onDelete = useCallback(async ({ id }) => {
        await deletePosition({ id });
    }, [deletePosition]);

    return (
        <EntityTable
            form={form}
            name="岗位"
            columns={schema}
            total={total}
            pageable={pageable}
            setPageable={setPageable}
            items={positions}
            filters={[
                { value: 'words', label: '搜索', placeholder: '岗位名称' },
            ]}
            onFilter={setFilter}
            onNew={onNew}
            onSave={onSave}
            onDelete={onDelete}
            onModeChange={setMode}
        >
            {isEditMode ? (
                <Form.Item name="id" label="岗位编号">
                    <Input disabled />
                </Form.Item>
            ) : null}
            <Form.Item name="name" label="岗位名称" rules={[{ required: isNewMode }]}>
                <Input placeholder="请输入岗位名称" />
            </Form.Item>
            <Form.Item name="operation" label="授权方式" rules={[{ required: isNewMode }]}>
                <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    options={[
                        { value: 'INTERSECTION', label: '仅包含清单中的权限' },
                        { value: 'DIFFERENCE', label: '排除清单中的权限' },
                    ]}
                />
            </Form.Item>
            <Form.Item name="authorities" label="权限清单">
                {applets.length ? (
                    <TreeSelect
                        multiple
                        treeCheckable
                        treeDefaultExpandAll
                        showCheckedStrategy={TreeSelect.SHOW_CHILD}
                        placeholder="赋予或排除的权限列表"
                        treeExpandedKeys={appletsExpandKeys}
                        treeData={applets}
                    />
                ) : (
                    <Input disabled placeholder="请先选择所属机构" />
                )}
            </Form.Item>
        </EntityTable>
    );
};
