import { gql } from '@apollo/client';
import { QUERY_OPS_INSTITUTIONS_OPTIONS } from 'applets/ops/state';
import * as districts from 'helpers/districts';
import { useMutate, useQueryValue } from 'helpers/graphql';
import { useMemo } from 'react';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { usePagesLeafValue } from 'snapshots/routers';

const QUERY_OPS_INSTITUTIONS = gql`
    query OpsInstitutions($filter: FilterInput!, $pageable: PageInput!) {
        opsInstitutions(filter: $filter, pageable: $pageable) {
            total
            items {
                ... on InstitutionProjection {
                    id
                    name
                    alias
                    address
                    bio
                    authorities
                    permissions
                    percentage
                    enabled
                    remark
                    updatedAt
                }
            }
        }
    }
`;

const filter = atom({
    key: 'ops.institutions.filter',
    default: {
        words: '',
    }
});

export const useOpsInstitutionsFilterState = () => useRecoilState(filter);
export const useOpsInstitutionsFilterValue = () => useRecoilValue(filter);
export const useSetOpsInstitutionsFilter = () => useSetRecoilState(filter);

const pageable = atom({
    key: 'ops.institutions.pageable',
    default: {
        page: 1,
        size: 50,
    }
});

export const useOpsInstitutionsPageableState = () => useRecoilState(pageable);
export const useOpsInstitutionsPageableValue = () => useRecoilValue(pageable);
export const useSetOpsInstitutionsPageable = () => useSetRecoilState(pageable);

export const useOpsInstitutionsPagingValue = () => {
    const filter = useOpsInstitutionsFilterValue();
    const pageable = useOpsInstitutionsPageableValue();
    const { total, items } = useQueryValue(QUERY_OPS_INSTITUTIONS, {
        variables: {
            filter,
            pageable,
        },
        defaultValue: {
            total: 0,
            items: [],
        },
    });
    const pages = usePagesLeafValue();
    const institutions = useMemo(() => items.map(({ address = '', ...item }) => {
        const permissions = new Set(item.permissions);
        const [code = '', street = ''] = address.split(/\//, 2);
        const values = code !== '' ? districts.ancestors(code) : [];
        return {
            ...item,
            key: item.id,
            address: { code: values, street },
            pages: pages.filter(({ key }) => permissions.has(key)).map(({ label }) => label),
            $deletable: true,
        };
    }), [items, pages]);
    return {
        total,
        items: institutions,
    };
};

export const useOpsSaveInstitution = () => {
    const filter = useOpsInstitutionsFilterValue();
    const pageable = useOpsInstitutionsPageableValue();
    return useMutate(gql`
        mutation OpsSaveInstitution($institution: InstitutionInput!) {
            opsSaveInstitution(institution: $institution)
        }
    `, {
        refetchQueries: [{
            query: QUERY_OPS_INSTITUTIONS,
            variables: {
                filter,
                pageable,
            },
        }, {
            query: QUERY_OPS_INSTITUTIONS_OPTIONS,
        }]
    });
};

export const useOpsDeleteInstitution = () => {
    const filter = useOpsInstitutionsFilterValue();
    const pageable = useOpsInstitutionsPageableValue();
    return useMutate(gql`
        mutation OpsDeleteInstitution($id: ID!) {
            opsDeleteInstitution(id: $id)
        }
    `, {
        refetchQueries: [{
            query: QUERY_OPS_INSTITUTIONS,
            variables: {
                filter,
                pageable,
            },
        }, {
            query: QUERY_OPS_INSTITUTIONS_OPTIONS,
        }]
    });
};
