import { PlusOutlined } from '@ant-design/icons';
import { App, Button, Drawer, Form, Pagination, Space, Table } from 'antd';
import { createStyles } from 'antd-style';
import { chain } from 'helpers/function';
import { useCallback, useMemo, useState } from 'react';
import EntityFilter from 'views/components/EntityFilter';
import Rows, { Content, Footer } from 'views/layouts/Rows';

const useStyles = createStyles(({ css, token }) => {
    return {
        entities: css`
          display: grid;
          width: 100%;
          height: 100%;
          grid-template-columns: 400px auto;
          grid-template-rows: 48px auto 48px;
          grid-template-areas: 'search actions'
                           'table table'
                           'pagination pagination';
        `,
        search: css`
          grid-area: search;
          justify-self: stretch;
          align-self: start;
        `,
        actions: css`
          grid-area: actions;
          justify-self: end;
          align-self: start;
        `,
        table: css`
          grid-area: table;
          overflow: auto;
          border-top: ${token.colorBorder} 1px solid;
          border-bottom: ${token.colorBorder} 1px solid;
        `,
        pagination: css`
          grid-area: pagination;
          justify-self: end;
          align-self: end;
        `,
        inspector: css`
          & > div:last-child {
            padding-top: 1rem;
          }
        `,
    };
});

const useTitles = (name) => useMemo(() => ({
    searchPrompt: `搜索${name}`,
    newButtonLabel: `添加${name}`,
    inspectorTitle: `${name}详情`,
}), [name]);

/**
 * 类似多维表格的通用实体表格。
 */
export default ({
    form = null,
    name,
    columns,
    total,
    pageable,
    setPageable,
    items,
    filters = [],
    onFilter = undefined,
    onNew = null,
    onEdit = null,
    onSave = null,
    onDelete = null,
    onModeChange = null,
    children = null,
}) => {
    const { styles } = useStyles();
    const { newButtonLabel, inspectorTitle } = useTitles(name);
    const onPagination = useCallback((page, size) => {
        setPageable({ page, size });
    }, [setPageable]);

    const { modal } = App.useApp();
    const { message } = App.useApp();
    if (form === null) {
        [form] = Form.useForm();
    }
    const [isOpen, setOpen] = useState(false);
    const onCreateClick = useCallback(() => {
        onModeChange?.('new');
        form.resetFields();
        chain(onNew?.(), form.setFieldsValue);
        setOpen(true);
    }, [form, onModeChange, onNew, setOpen]);
    const onEditClick = useCallback((data) => {
        onModeChange?.('edit');
        form.resetFields();
        form.setFieldsValue(onEdit !== null ? onEdit?.(data) : data);
        setOpen(true);
    }, [form, onModeChange, onEdit, setOpen]);
    const onCancel = useCallback(() => {
        onModeChange?.(null);
        form.resetFields();
        setOpen(false);
    }, [onModeChange, setOpen]);
    const onSaveClick = useCallback(async () => {
        try {
            if (await form.validateFields({ recursive: true }).catch(() => false)) {
                await onSave?.(form.getFieldsValue());
                onModeChange?.(null);
                form.resetFields();
                setOpen(false);
            }
        } catch (exception) {
            message.warning(exception.message);
        }
    }, [form, message, onModeChange, onSave, setOpen]);
    const onDeleteClick = useCallback((data) => {
        modal.confirm({
            title: '确认删除？',
            cancelText: '取消',
            okType: 'danger',
            okText: '确认',
            onOk: async () => {
                try {
                    await onDelete?.(data);
                } catch (exception) {
                    message.warning(exception.message);
                }
            },
        });
    }, [onDelete, message, modal]);

    const schema = useMemo(() => [...columns, {
        key: '$actions',
        title: '操作',
        width: 160,
        render: ({ $editable = true, $deletable = false }, data) => (
            <Space>
                {$editable && <Button type="link" onClick={() => onEditClick(data)}>详情</Button>}
                {$deletable && <Button type="link" danger onClick={() => onDeleteClick(data)}>删除</Button>}
                {!$editable && !$deletable && '无'}
            </Space>
        ),
    }], [columns, onEdit, onEditClick, onDelete, onDeleteClick]);

    return (
        <div className={styles.entities}>
            <div className={styles.search}>
                <EntityFilter
                    options={filters}
                    onFilter={onFilter}
                />
            </div>
            <div className={styles.actions}>
                <Button type="primary" icon={<PlusOutlined />} onClick={onCreateClick}>{newButtonLabel}</Button>
            </div>
            <div className={styles.table}>
                <Table style={{ width: '100%' }} columns={schema} dataSource={items} pagination={false} />
            </div>
            <div className={styles.pagination}>
                <Pagination
                    showSizeChanger
                    showQuickJumper
                    current={pageable.page}
                    pageSize={pageable.size}
                    total={total}
                    onChange={onPagination}
                />
            </div>
            <Drawer title={inspectorTitle} placement="right" closable={false} open={isOpen}>
                <Rows className={styles.inspector}>
                    <Content>
                        <Form style={{ width: '100%' }} layout="vertical" form={form}>
                            {children}
                        </Form>
                    </Content>
                    <Footer bordered right>
                        <Space>
                            <Button onClick={onCancel}>取消</Button>
                            <Button type="primary" onClick={onSaveClick}>保存</Button>
                        </Space>
                    </Footer>
                </Rows>
            </Drawer>
        </div>
    );
};
