import Flex from 'views/layouts/Flex';

export default ({
    direction = "vertical", // horizontal | vertical | flex directions
    items = "start", // start | center | end | space-between | space-around
    item = "start", // start | center | end | space-between | space-around

    children,
    ...props
}) => {
    if (items === 'start') {
        items = 'flex-start';
    } else if (items === 'end') {
        items = 'flex-end';
    }
    if (item === 'start') {
        item = 'flex-start';
    } else if (item === 'end') {
        item = 'flex-end';
    }
    let justifyContent = null, alignItems = null;
    if (direction === 'vertical') {
        justifyContent = item;
        alignItems = items;
    } else {
        justifyContent = items;
        alignItems = item;
    }

    return <Flex
        direction={direction}
        justifyContent={justifyContent}
        alignItems={alignItems}
        {...props}
    >
        {children}
    </Flex>
};
