import { gql } from '@apollo/client';
import { useMutate } from 'helpers/graphql';
import { useCallback } from 'react';
import { useDialogPasswordVisible } from 'snapshots/profile';
import { QUERY_SHELL_USER } from 'snapshots/shell';

// Session

const MUTATION_SIGN_IN = gql`
    mutation SignIn($principal: String!, $credentials: String!) {
        signIn(mode: PhoneAndPassword, principal: $principal, credentials: $credentials) {
            isPasswordInitial
        }
    }
`;

const MUTATION_SIGN_OFF = gql`
    mutation SignOff {
        signOff
    }
`;

export const useSignIn = () => {
    const signIn = useMutate(MUTATION_SIGN_IN, {
        refetchQueries: [
            QUERY_SHELL_USER,
        ],
    });
    const { show } = useDialogPasswordVisible();
    return useCallback(async (variables) => {
        const { isPasswordInitial = false } = await signIn(variables) || {};
        if (isPasswordInitial) {
            show();
        }
    }, [signIn, show]);
};

export const useSignOff = () => {
    const mutate = useMutate(MUTATION_SIGN_OFF, {
        refetchQueries: [
            QUERY_SHELL_USER,
        ],
    });
    return useCallback(async () => await mutate(), [mutate]);
};
