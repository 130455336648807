import { useAuthenticationState } from 'snapshots/shell';
import Loading from 'views/pages/Loading';
import Session from 'views/pages/Session';

export default ({ children }) => {
    const { ready, authenticated } = useAuthenticationState();
    if (!ready) {
        return <Loading />;
    } else if (!authenticated) {
        return <Session />;
    } else {
        return children;
    }
};
