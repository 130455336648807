import { gql } from '@apollo/client';
import { useMutate, useQueryValue } from 'helpers/graphql';
import { useMemo } from 'react';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

const QUERY_HRM_EMPLOYEES = gql`
    query HrmEmployees($filter: FilterInput!, $pageable: PageInput!) {
        hrmEmployees(filter: $filter, pageable: $pageable) {
            total
            items {
                ... on EmployeeProjection {
                    id
                    phone
                    name
                    employeed
                    departments {
                        id
                        name
                    }
                    positions {
                        id
                        name
                    }
                    updatedAt
                }
            }
        }
    }
`;

const filter = atom({
    key: 'hrm.employees.filter',
    default: {
        words: '',
    }
});

export const useHrmEmployeesFilterState = () => useRecoilState(filter);
export const useHrmEmployeesFilterValue = () => useRecoilValue(filter);
export const useSetHrmEmployeesFilter = () => useSetRecoilState(filter);

const pageable = atom({
    key: 'hrm.employees.pageable',
    default: {
        page: 1,
        size: 50,
    }
});

export const useHrmEmployeesPageableState = () => useRecoilState(pageable);
export const useHrmEmployeesPageableValue = () => useRecoilValue(pageable);
export const useSetHrmEmployeesPageable = () => useSetRecoilState(pageable);

export const useHrmEmployeesPagingValue = () => {
    const filter = useHrmEmployeesFilterValue();
    const pageable = useHrmEmployeesPageableValue();
    const { total, items } = useQueryValue(QUERY_HRM_EMPLOYEES, {
        variables: {
            filter,
            pageable,
        },
        defaultValue: {
            total: 0,
            items: [],
        },
    });
    const employees = useMemo(() => items.map(({ departments, positions, ...item }) => {
        const { id: departmentId = null, name: department = null } = departments?.[0] || {};
        const { id: positionId = null, name: position = null } = positions?.[0] || {};
        return {
            ...item,
            key: item.id,
            departmentId,
            department,
            positionId,
            position,
            $deletable: true,
        };
    }), [items]);
    return {
        total,
        items: employees
    };
};

export const useHrmSaveEmployee = () => {
    const filter = useHrmEmployeesFilterValue();
    const pageable = useHrmEmployeesPageableValue();
    return useMutate(gql`
        mutation HrmSaveEmployee($employee: EmployeeInput!) {
            hrmSaveEmployee(employee: $employee)
        }
    `, {
        refetchQueries: [{
            query: QUERY_HRM_EMPLOYEES,
            variables: {
                filter,
                pageable,
            },
        }]
    });
};

export const useHrmDeleteEmployee = () => {
    const filter = useHrmEmployeesFilterValue();
    const pageable = useHrmEmployeesPageableValue();
    return useMutate(gql`
        mutation HrmDeleteEmployee($id: ID!) {
            hrmDeleteEmployee(id: $id)
        }
    `, {
        refetchQueries: [{
            query: QUERY_HRM_EMPLOYEES,
            variables: {
                filter,
                pageable,
            },
        }]
    });
};
