import { chain, coalesce } from 'helpers/function';

const deserialize = (value) => chain(value, JSON.parse);

const serialize = (value) => JSON.stringify(value);

/**
 * 从Local Storage读取并解析数据。
 */
export const localStorageValue = (key) => deserialize(localStorage.getItem(key));

/**
 * 从Session Storage读取并解析数据。
 */
export const sessionStorageValue = (key) => deserialize(sessionStorage.getItem(key));

/**
 * 从Session & Local Storage读取并解析数据。
 */
export const storageValue = (key) => deserialize(coalesce(sessionStorage.getItem(key), localStorage.getItem(key)));

/**
 * 将数据保存到Local Storage。
 */
export const localStorageEffect = (key) => ({setSelf, onSet}) => {
    chain(localStorageValue(key), setSelf);
    onSet((newValue, _, isReset) => {
        isReset
            ? localStorage.removeItem(key)
            : localStorage.setItem(key, serialize(newValue));
    });
};

/**
 * 将数据保存到Session Storage。
 */
export const sessionStorageEffect = (key) => ({setSelf, onSet}) => {
    chain(sessionStorageValue(key), setSelf);
    onSet((newValue, _, isReset) => {
        isReset
            ? sessionStorage.removeItem(key)
            : sessionStorage.setItem(key, serialize(newValue));
    });
};

/**
 * 将数据同时保存到Session Storage和Local Storage。
 */
export const storageEffect = (key) => ({setSelf, onSet}) => {
    chain(storageValue(key), (value) => {
        if (sessionStorage.getItem(key) === null) {
            // 确保Session Storage中有值
            sessionStorage.setItem(key, serialize(value));
        }
        return value;
    }, setSelf);
    onSet((newValue, _, isReset) => {
        if (isReset) {
            sessionStorage.removeItem(key);
        } else {
            const value = serialize(newValue);
            sessionStorage.setItem(key, value);
            localStorage.setItem(key, value);
        }
    });
};

export const broadcastEffect = (name) => ({ onSet }) => onSet((value) => {
    window.postMessage({ name, value });
});
