import { gql } from '@apollo/client';
import { QUERY_HRM_POSITIONS_OPTIONS } from 'applets/hrm/state';
import { useMutate, useQueryValue } from 'helpers/graphql';
import { useMemo } from 'react';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { usePagesLeafValue } from 'snapshots/routers';

const QUERY_HRM_POSITIONS = gql`
    query HrmPositions($filter: FilterInput!, $pageable: PageInput!) {
        hrmPositions(filter: $filter, pageable: $pageable) {
            total
            items {
                ... on PositionProjection {
                    id
                    name
                    operation
                    authorities
                    permissions
                    deletable
                    members
                    updatedAt
                }
            }
        }
    }
`;

const filter = atom({
    key: 'hrm.positions.filter',
    default: {
        words: '',
    }
});

export const useHrmPositionsFilterState = () => useRecoilState(filter);
export const useHrmPositionsFilterValue = () => useRecoilValue(filter);
export const useSetHrmPositionsFilter = () => useSetRecoilState(filter);

const pageable = atom({
    key: 'hrm.positions.pageable',
    default: {
        page: 1,
        size: 50,
    }
});

export const useHrmPositionsPageableState = () => useRecoilState(pageable);
export const useHrmPositionsPageableValue = () => useRecoilValue(pageable);
export const useSetHrmPositionsPageable = () => useSetRecoilState(pageable);

export const useHrmPositionsPagingValue = () => {
    const filter = useHrmPositionsFilterValue();
    const pageable = useHrmPositionsPageableValue();
    const { total, items } = useQueryValue(QUERY_HRM_POSITIONS, {
        variables: {
            filter,
            pageable,
        },
        defaultValue: {
            total: 0,
            items: [],
        },
    });
    const pages = usePagesLeafValue();
    const positions = useMemo(() => items.map(({ deletable, ...item }) => {
        const permissions = new Set(item.permissions);
        return {
            ...item,
            key: item.id,
            permissions: pages.filter(({ key }) => permissions.has(key)).map(({ label }) => label),
            deletable,
            $deletable: deletable,
        };
    }), [items, pages]);
    return {
        total,
        items: positions,
    };
};

export const useHrmSavePosition = () => {
    const filter = useHrmPositionsFilterValue();
    const pageable = useHrmPositionsPageableValue();
    return useMutate(gql`
        mutation HrmSavePosition($position: PositionInput!) {
            hrmSavePosition(position: $position)
        }
    `, {
        refetchQueries: [{
            query: QUERY_HRM_POSITIONS,
            variables: {
                filter,
                pageable,
            },
        }, {
            query: QUERY_HRM_POSITIONS_OPTIONS,
        }]
    });
};

export const useHrmDeletePosition = () => {
    const filter = useHrmPositionsFilterValue();
    const pageable = useHrmPositionsPageableValue();
    return useMutate(gql`
        mutation HrmDeletePosition($id: ID!) {
            hrmDeletePosition(id: $id)
        }
    `, {
        refetchQueries: [{
            query: QUERY_HRM_POSITIONS,
            variables: {
                filter,
                pageable,
            },
        }, {
            query: QUERY_HRM_POSITIONS_OPTIONS,
        }]
    });
};
