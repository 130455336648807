import {
    IconAnalyze,
    IconArmchair,
    IconBuildingBank,
    IconBuildingStore,
    IconCategory,
    IconCoin,
    IconHeartHandshake,
    IconHome,
    IconListDetails,
    IconNotes,
    IconPackages,
    IconRss,
    IconSettings,
    IconSitemap,
    IconSpider,
    IconSubtask,
    IconTie,
    IconUsers,
    IconUsersGroup,
} from '@tabler/icons-react';
import CrmCommodities from 'applets/crm/Commodities';
import CrmCustomers from 'applets/crm/Customers';
import CrmHome from 'applets/crm/Home';
import CrmOrders from 'applets/crm/Orders';
import HrmDepartments from 'applets/hrm/Departments';
import HrmEmployees from 'applets/hrm/Employees';
import HrmPositions from 'applets/hrm/Positions';
import OpsDepartments from 'applets/ops/Departments';
import OpsEmployees from 'applets/ops/Employees';
import OpsInstitutions from 'applets/ops/Institutions';
import OpsPositions from 'applets/ops/Positions';
import OpsProducts from 'applets/ops/Products';
import OpsUsers from 'applets/ops/Users';
import { forest, mapTo } from 'helpers/function';
import { useCallback, useMemo } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useShellHasPermission } from 'snapshots/shell';
import NotFound from 'views/pages/NotFound';

/**
 * 全局的页面信息集合：包含页面路径信息和层次结构信息。
 */
export const pages = [{
    key: 'crm/home',
    label: '首页',
    Icon: IconHome,
    Component: CrmHome,
}, {
    key: 'crm/sales',
    label: '销售管理',
    Icon: IconBuildingStore,
    children: [{
        key: 'crm/orders',
        label: '订单管理',
        Icon: IconListDetails,
        Component: CrmOrders,
    }, {
        key: 'crm/commodities',
        label: '商品管理',
        Icon: IconCategory,
        Component: CrmCommodities,
    }, {
        key: 'crm/customers',
        label: '客户管理',
        Icon: IconHeartHandshake,
        Component: CrmCustomers,
    }, {
        key: 'crm/settlements',
        label: '结算管理',
        Icon: IconCoin,
        Component: NotFound,
    }],
}, {
    key: 'hrm',
    label: '组织管理',
    Icon: IconUsersGroup,
    children: [{
        key: 'hrm/departments',
        label: '部门管理',
        Icon: IconSitemap,
        Component: HrmDepartments,
    }, {
        key: 'hrm/positions',
        label: '岗位管理',
        Icon: IconArmchair,
        Component: HrmPositions,
    }, {
        key: 'hrm/employees',
        label: '员工管理',
        Icon: IconTie,
        Component: HrmEmployees,
    }],
}, {
    key: 'dms',
    label: '数据管理',
    Icon: IconSpider,
    children: [{
        key: 'dms/tasks',
        label: '今日任务',
        Icon: IconSubtask,
        Component: NotFound,
    }, {
        key: 'dms/subscriptions',
        label: '订阅管理',
        Icon: IconRss,
        Component: NotFound,
    }, {
        key: 'dms/notes',
        label: '笔记管理',
        Icon: IconNotes,
        Component: NotFound,
    }, {
        key: 'dms/leads',
        label: '线索管理',
        Icon: IconAnalyze,
        Component: NotFound,
    }],
}, {
    key: 'ops',
    label: '运营管理',
    Icon: IconSettings,
    children: [{
        key: 'ops/users',
        label: '用户管理',
        Icon: IconUsers,
        Component: OpsUsers,
    }, {
        key: 'ops/institutions',
        label: '机构管理',
        Icon: IconBuildingBank,
        Component: OpsInstitutions,
    }, {
        key: 'ops/departments',
        label: '部门管理',
        Icon: IconSitemap,
        Component: OpsDepartments,
    }, {
        key: 'ops/positions',
        label: '岗位管理',
        Icon: IconArmchair,
        Component: OpsPositions,
    }, {
        key: 'ops/employees',
        label: '员工管理',
        Icon: IconTie,
        Component: OpsEmployees,
    }, {
        key: 'ops/products',
        label: '产品管理',
        Icon: IconPackages,
        Component: OpsProducts,
    }],
}];

export const usePagesLeafValue = () => useMemo(() => forest.listLeafNodes(pages).map(({ key, label, Icon }) => ({
    key,
    label,
    Icon,
})), []);

/**
 * 将页面节点信息转成Ant Design Menu Item信息。
 */
const toMenuItem = ({ key, label, Icon, children = null }) => ({
    key,
    label: children === null ? <Link to={key}>{label}</Link> : label,
    icon: <Icon size={16} />,
});

/**
 * 将页面叶子节点信息转成React Router Item信息。
 */
const toLinkItem = ({ key: path, Component }) => ({
    path,
    Component,
});

/**
 * 返回拥有权限的页面信息：保持原始的层级结构。
 */
export const usePagesValue = () => {
    const hasPermission = useShellHasPermission();
    return useMemo(() => (
        forest.filter(pages, ({ key }) => hasPermission(key))
    ), [hasPermission]);
};

/**
 * 返回目录信息。
 */
export const useCatalogValue = () => {
    const pages = usePagesValue();
    return useMemo(() => ({
        items: forest.map(pages, toMenuItem),
        openKeys: mapTo(forest.listBranchNodes(pages), 'key'),
    }), [pages]);
};

/**
 * 返回路由信息。
 */
export const useRoutersValue = () => {
    const pages = usePagesValue();
    return useMemo(() => {
        if (pages.length > 0) {
            const [index, ...routers] = forest.listLeafNodes(pages).map(toLinkItem);
            return [{
                index: true,
                Component: index.Component,
            }, index, ...routers];
        } else {
            return [];
        }
    }, [pages]);
};

/**
 * 返回当前页面信息。
 */
export const usePageValue = () => {
    const pages = usePagesValue();
    const location = useLocation();
    return useMemo(() => {
        const pathname = location.pathname;
        const nodes = forest.listLeafNodes(pages);
        const node = nodes.find(({ key }) => pathname.includes(key));
        if (node !== undefined) {
            return node;
        } else if (nodes.length > 0) {
            return nodes[0];
        } else {
            return {};
        }
    }, [pages, location]);
};

// Redirect

const redirect = (url) => () => {
    const navigate = useNavigate();
    return useCallback(() => {
        navigate(url);
    }, [navigate]);
};

export const useToHomePage = redirect('/');
