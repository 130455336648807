import { createStyles } from 'antd-style';
import Flex from 'views/layouts/Flex';

const useStyles = createStyles(({ token, css }, { bordered = false } = {}) => {
    const item = css`
      height: 100%;
      line-height: normal;
    `;
    const side = css`
      flex: 0 0 auto;
    `;
    return {
        columns: css`
          width: 100%;
          ${item};
        `,
        left: css`
          ${item};
          ${side};
          border-right: ${bordered ? 1 : 0}px solid ${token.colorBorder};
        `,
        content: css`
          ${item};
          flex: 1 1 auto;
        `,
        right: css`
          ${item};
          ${side};
          border-left: ${bordered ? 1 : 0}px solid ${token.colorBorder};
        `,
    };
});

export const Left = ({ className, bordered = false, children, ...props }) => {
    const { styles, cx } = useStyles({ bordered });
    return <Flex className={cx(styles.left, className)} direction="horizontal" {...props}>{children}</Flex>
};

export const Content = ({ className, children, ...props }) => {
    const { styles, cx } = useStyles();
    return <Flex className={cx(styles.content, className)} direction="horizontal" {...props}>{children}</Flex>
};

export const Right = ({ className, bordered = false, children, ...props }) => {
    const { styles, cx } = useStyles({ bordered });
    return <Flex className={cx(styles.right, className)} direction="horizontal" {...props}>{children}</Flex>
};

/**
 * 行布局：可以指定任意多个吸顶或吸底的行，正文部分填充满剩余高度。
 */
export default ({ className, children, ...props }) => {
    const { styles, cx } = useStyles();
    return <Flex className={cx(styles.columns, className)} direction="horizontal" {...props}>{children}</Flex>
};
