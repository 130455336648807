import { Breadcrumb } from 'antd';
import { useAppletTitleValue } from 'snapshots/applets';
import { usePageValue } from 'snapshots/routers';
import { useShellAccountValue } from 'snapshots/shell';

export default () => {
    const { institutionName = '' } = useShellAccountValue();
    const { label: pageName = '', key = '' } = usePageValue();
    const appletTitle = useAppletTitleValue(key);
    return <Breadcrumb items={[{
        title: institutionName
    }, {
        title: appletTitle
    }, {
        title: pageName
    }]} />;
};
