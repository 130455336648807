import { gql } from '@apollo/client';
import { QUERY_HRM_DEPARTMENTS_OPTIONS } from 'applets/hrm/state';
import { useMutate, useQueryValue } from 'helpers/graphql';
import { useMemo } from 'react';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

const QUERY_HRM_DEPARTMENTS = gql`
    query HrmDepartments($filter: FilterInput!, $pageable: PageInput!) {
        hrmDepartments(filter: $filter, pageable: $pageable) {
            total
            items {
                ... on DepartmentProjection {
                    id
                    name
                    parentName
                    percentage
                    members
                    updatedAt
                }
            }
        }
    }
`;

const filter = atom({
    key: 'hrm.departments.filter',
    default: {
        words: '',
    }
});

export const useHrmDepartmentsFilterState = () => useRecoilState(filter);
export const useHrmDepartmentsFilterValue = () => useRecoilValue(filter);
export const useSetHrmDepartmentsFilter = () => useSetRecoilState(filter);

const pageable = atom({
    key: 'hrm.departments.pageable',
    default: {
        page: 1,
        size: 50,
    }
});

export const useHrmDepartmentsPageableState = () => useRecoilState(pageable);
export const useHrmDepartmentsPageableValue = () => useRecoilValue(pageable);
export const useSetHrmDepartmentsPageable = () => useSetRecoilState(pageable);

export const useHrmDepartmentsPagingValue = () => {
    const filter = useHrmDepartmentsFilterValue();
    const pageable = useHrmDepartmentsPageableValue();
    const { total, items } = useQueryValue(QUERY_HRM_DEPARTMENTS, {
        variables: {
            filter,
            pageable,
        },
        defaultValue: {
            total: 0,
            items: [],
        },
    });
    const departments = useMemo(() => items.map((item) => {
        return {
            ...item,
            key: item.id,
            $deletable: true,
        };
    }), [items]);
    return {
        total,
        items: departments
    };
};

export const useHrmSaveDepartment = () => {
    const filter = useHrmDepartmentsFilterValue();
    const pageable = useHrmDepartmentsPageableValue();
    return useMutate(gql`
        mutation HrmSaveDepartment($department: DepartmentInput!) {
            hrmSaveDepartment(department: $department)
        }
    `, {
        refetchQueries: [{
            query: QUERY_HRM_DEPARTMENTS,
            variables: {
                filter,
                pageable,
            },
        }, {
            query: QUERY_HRM_DEPARTMENTS_OPTIONS,
        }]
    });
};

export const useHrmDeleteDepartment = () => {
    const filter = useHrmDepartmentsFilterValue();
    const pageable = useHrmDepartmentsPageableValue();
    return useMutate(gql`
        mutation HrmDeleteDepartment($id: ID!) {
            hrmDeleteDepartment(id: $id)
        }
    `, {
        refetchQueries: [{
            query: QUERY_HRM_DEPARTMENTS,
            variables: {
                filter,
                pageable,
            },
        }, {
            query: QUERY_HRM_DEPARTMENTS_OPTIONS,
        }]
    });
};
