import { IconLock, IconLockCheck, IconShieldLock } from '@tabler/icons-react';
import { App, Form, Input, Modal } from 'antd';
import { useCallback } from 'react';
import { useDialogPasswordVisible, useDialogPasswordVisibleValue, useUpdatePasswordAndLogout } from 'snapshots/profile';

export default () => {
    const [form] = Form.useForm();
    const visible = useDialogPasswordVisibleValue();
    const { hide } = useDialogPasswordVisible();
    const updatePasswordAndLogout = useUpdatePasswordAndLogout();
    const { message } = App.useApp();
    const onOK = useCallback(async () => {
        try {
            const { originPassword, newPassword } = form.getFieldsValue();
            await updatePasswordAndLogout({
                originPassword,
                newPassword,
            });
            hide();
        } catch (exception) {
            message.error(exception.message);
        }
    }, [form, message, hide, updatePasswordAndLogout]);
    return (
        <Modal
            centered
            maskClosable
            title="修改密码"
            open={visible}
            onOk={onOK}
            onCancel={hide}
        >
            <Form layout="vertical" form={form}>
                <Form.Item
                    name="originPassword"
                    label="当前密码"
                    required
                    rules={[{
                        required: true,
                        message: '请输入当前密码',
                    }]}
                >
                    <Input.Password prefix={<IconShieldLock size={16} />}
                                    placeholder="请输入正确的当前密码" />
                </Form.Item>
                <Form.Item
                    name="newPassword"
                    label="新的密码"
                    required
                    rules={[{
                        required: true,
                        message: '请输入新的密码',
                    }, () => ({
                        validator: async (_, value) => {
                            if (value && value.length < 6) {
                                throw Error('密码长度请勿小于6位');
                            }
                        },
                    })]}
                >
                    <Input.Password prefix={<IconLock size={16} />}
                                    placeholder="新密码需要与当前密码不同" />
                </Form.Item>
                <Form.Item
                    name="confirmPassword"
                    dependencies={['newPassword']}
                    label="确认密码"
                    required
                    rules={[{
                        required: true,
                        message: '请输入确认密码',
                    }, ({ getFieldValue }) => ({
                        validator: async (_, value) => {
                            const password = getFieldValue('newPassword');
                            if (password && value && password !== value) {
                                throw Error('两次输入密码不一致');
                            }
                        },
                    })]}
                >
                    <Input.Password prefix={<IconLockCheck size={16} />}
                                    placeholder="请重复输入新密码" />
                </Form.Item>
            </Form>
        </Modal>
    );
};
