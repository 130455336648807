import { gql } from '@apollo/client';
import { useMutate, useQueryValue } from 'helpers/graphql';
import { useMemo } from 'react';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { usePagesLeafValue } from 'snapshots/routers';

const QUERY_OPS_POSITIONS = gql`
    query OpsPositions($filter: FilterInput!, $pageable: PageInput!) {
        opsPositions(filter: $filter, pageable: $pageable) {
            total
            items {
                ... on PositionProjection {
                    id
                    institutionId
                    institutionName
                    name
                    operation
                    authorities
                    permissions
                    deletable
                    members
                    updatedAt
                }
            }
        }
    }
`;

const filter = atom({
    key: 'ops.positions.filter',
    default: {
        words: '',
    }
});

export const useOpsPositionsFilterState = () => useRecoilState(filter);
export const useOpsPositionsFilterValue = () => useRecoilValue(filter);
export const useSetOpsPositionsFilter = () => useSetRecoilState(filter);

const pageable = atom({
    key: 'ops.positions.pageable',
    default: {
        page: 1,
        size: 50,
    }
});

export const useOpsPositionsPageableState = () => useRecoilState(pageable);
export const useOpsPositionsPageableValue = () => useRecoilValue(pageable);
export const useSetOpsPositionsPageable = () => useSetRecoilState(pageable);

export const useOpsPositionsPagingValue = () => {
    const filter = useOpsPositionsFilterValue();
    const pageable = useOpsPositionsPageableValue();
    const { total, items } = useQueryValue(QUERY_OPS_POSITIONS, {
        variables: {
            filter,
            pageable,
        },
        defaultValue: {
            total: 0,
            items: [],
        },
    });
    const pages = usePagesLeafValue();
    const positions = useMemo(() => items.map((item) => {
        const permissions = new Set(item.permissions);
        return {
            ...item,
            key: item.id,
            permissions: pages.filter(({ key }) => permissions.has(key)).map(({ label }) => label),
            $deletable: true,
        };
    }), [items, pages]);
    return {
        total,
        items: positions,
    };
};

export const useOpsSavePosition = () => {
    const filter = useOpsPositionsFilterValue();
    const pageable = useOpsPositionsPageableValue();
    return useMutate(gql`
        mutation OpsSavePosition($position: PositionInput!) {
            opsSavePosition(position: $position)
        }
    `, {
        refetchQueries: [{
            query: QUERY_OPS_POSITIONS,
            variables: {
                filter,
                pageable,
            },
        }]
    });
};

export const useOpsDeletePosition = () => {
    const filter = useOpsPositionsFilterValue();
    const pageable = useOpsPositionsPageableValue();
    return useMutate(gql`
        mutation OpsDeletePosition($id: ID!) {
            opsDeletePosition(id: $id)
        }
    `, {
        refetchQueries: [{
            query: QUERY_OPS_POSITIONS,
            variables: {
                filter,
                pageable,
            },
        }]
    });
};
