/**
 * 哑函数：没有做任何事情
 */
export const dummy = () => {};

/**
 * 返回第一个非空的值。
 */
export const coalesce = (...values) => {
    for (let value of values) {
        if (value !== null) {
            return value;
        }
    }
    return null;
};

/**
 * 安全地链式调用：当前一个函数的结果非空，则作为入参调用后一个函数。
 */
export const chain = (value, ...operations) => {
    for (let operation of operations) {
        if (value !== null) {
            value = operation(value);
        } else {
            break;
        }
    }
    return value;
};

/**
 * 将对象列表转成属性值列表。
 */
export const mapTo = (list, property) => list.map(item => item[property]);

/**
 * 森林（多棵树）层级结构的通用处理函数
 */
export const forest = {

    /**
     * 将森林平铺成普通列表。
     */
    flatten: (nodes, property = 'children') => nodes.flatMap((node) => {
        if (property in node) {
            return [node, ...forest.flatten(node[property], property)];
        } else {
            return [node];
        }
    }),

    /**
     * 找到第一个符合[predicate]的节点。
     */
    find: (nodes, predicate, property = 'children') => forest.flatten(nodes, property).find(predicate),

    /**
     * 调用[transform]处理所有节点，并保持结构。
     */
    map: (nodes, transform, property = 'children') => nodes.map((node) => {
        if (property in node) {
            // branch node
            return {
                ...transform(node),
                [property]: forest.map(node[property], transform, property),
            };
        } else {
            return transform(node);
        }
    }),

    /**
     * 过滤出[predicate]结果为true的“叶子”节点，并保持结构。
     *
     * - 叶子节点需要符合[predicate]要求;
     * - 非叶子节点需要只要至少包含一个子节点。
     */
    filter: (nodes, predicate, property = 'children') => nodes.flatMap((node) => {
        if (property in node) {
            const children = forest.filter(node[property], predicate, property);
            if (children.length > 0) {
                return [{
                    ...node,
                    children,
                }];
            } else {
                return [];
            }
        } else if (predicate(node)) {
            return [node];
        } else {
            return [];
        }
    }),

    /**
     * 铺平并筛选出非叶子节点。
     */
    listBranchNodes: (nodes, property = 'children') => forest
        .flatten(nodes, property)
        .filter((node) => property in node),

    /**
     * 铺平并筛选出叶子节点。
     */
    listLeafNodes: (nodes, property = 'children') => forest
        .flatten(nodes, property)
        .filter((node) => !(property in node)),
};

/**
 * 深度合并两个Object
 */
export const merge = (left, right) => {
    const keys = [...new Set([...Object.keys(left), ...Object.keys(right)])];
    return keys.reduce((o, key) => {
        if ((key in left) && (key in right)) {
            const a = left[key];
            const b = right[key];
            if (Array.isArray(a) && Array.isArray(b)) {
                o[key] = [...a, ...b];
            } else if (typeof a === 'object' && typeof b === 'object') {
                o[key] = merge(a, b);
            } else {
                o[key] = b;
            }
        } else if (key in left) {
            o[key] = left[key];
        } else {
            o[key] = right[key];
        }
        return o;
    }, {});
};
