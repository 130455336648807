import { Input, Select } from 'antd';
import { useCallback, useState } from 'react';

const Properties = ({ options, onChange }) => (
    options.length === 1 ? options[0].label : (
        <Select defaultValue={options[0].value} options={options} onChange={onChange} />
    )
);

/**
 * 实体过滤器：支持多种类型的搜索。
 */
export default ({ options = [], onFilter = null }) => {
    const [type, setType] = useState(options?.[0]?.value);
    const [placeholder, setPlaceholder] = useState(options?.[0].placeholder || '');
    const onChange = useCallback((type) => {
        setType(type);
        setPlaceholder(options.find(({ value }) => value === type)?.placeholders || '');
    }, [setType, setPlaceholder]);
    return options.length ? (
        <Input.Search
            allowClear
            addonBefore={<Properties options={options} onChange={onChange} />}
            placeholder={placeholder}
            onSearch={(value) => onFilter?.({ [type]: value })} />
    ) : null;
};
