import { gql } from '@apollo/client';
import { useMutate, useQueryValue } from 'helpers/graphql';
import { useMemo } from 'react';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

const QUERY_OPS_DEPARTMENTS = gql`
    query OpsDepartments($filter: FilterInput!, $pageable: PageInput!) {
        opsDepartments(filter: $filter, pageable: $pageable) {
            total
            items {
                ... on DepartmentProjection {
                    id
                    institutionId
                    institutionName
                    name
                    parentName
                    percentage
                    members
                    updatedAt
                }
            }
        }
    }
`;

const filter = atom({
    key: 'ops.departments.filter',
    default: {
        words: '',
    }
});

export const useOpsDepartmentsFilterState = () => useRecoilState(filter);
export const useOpsDepartmentsFilterValue = () => useRecoilValue(filter);
export const useSetOpsDepartmentsFilter = () => useSetRecoilState(filter);

const pageable = atom({
    key: 'ops.departments.pageable',
    default: {
        page: 1,
        size: 50,
    }
});

export const useOpsDepartmentsPageableState = () => useRecoilState(pageable);
export const useOpsDepartmentsPageableValue = () => useRecoilValue(pageable);
export const useSetOpsDepartmentsPageable = () => useSetRecoilState(pageable);

export const useOpsDepartmentsPagingValue = () => {
    const filter = useOpsDepartmentsFilterValue();
    const pageable = useOpsDepartmentsPageableValue();
    const { total, items } = useQueryValue(QUERY_OPS_DEPARTMENTS, {
        variables: {
            filter,
            pageable,
        },
        defaultValue: {
            total: 0,
            items: [],
        },
    });
    const departments = useMemo(() => items.map((item) => {
        return {
            ...item,
            key: item.id,
            $deletable: true,
        };
    }), [items]);
    return {
        total,
        items: departments
    };
};

export const useOpsSaveDepartment = () => {
    const filter = useOpsDepartmentsFilterValue();
    const pageable = useOpsDepartmentsPageableValue();
    return useMutate(gql`
        mutation OpsSaveDepartment($department: DepartmentInput!) {
            opsSaveDepartment(department: $department)
        }
    `, {
        refetchQueries: [{
            query: QUERY_OPS_DEPARTMENTS,
            variables: {
                filter,
                pageable,
            },
        }]
    });
};

export const useOpsDeleteDepartment = () => {
    const filter = useOpsDepartmentsFilterValue();
    const pageable = useOpsDepartmentsPageableValue();
    return useMutate(gql`
        mutation OpsDeleteDepartment($id: ID!) {
            opsDeleteDepartment(id: $id)
        }
    `, {
        refetchQueries: [{
            query: QUERY_OPS_DEPARTMENTS,
            variables: {
                filter,
                pageable,
            },
        }]
    });
};
