import { createStyles } from 'antd-style';
import { Outlet } from 'react-router-dom';
import Addons from 'views/components/Addons';
import Brand from 'views/components/Brand';
import Catalog from 'views/components/Catalog';
import PageTitle from 'views/components/PageTtile';

const useStyles = createStyles(({ css, token }) => ({
    shell: css`
      height: 100%;
      display: grid;
      grid-template-columns: 220px auto auto;
      grid-template-rows: 64px auto;
      grid-template-areas: 'brand breadcrumb addons'
                           'catalog content content';
    `,
    brand: css`
      grid-area: brand;
      justify-self: start;
      align-self: center;
    `,
    breadcrumb: css`
      grid-area: breadcrumb;
      align-self: center;
    `,
    addons: css`
      grid-area: addons;
      justify-self: right;
      align-self: center;
    `,
    catalog: css`
      grid-area: catalog;
    `,
    content: css`
      grid-area: content;
      overflow: auto;
      border-top: ${token.colorBorder} 1px solid;
      border-left: ${token.colorBorder} 1px solid;
      border-top-left-radius: 1rem;
      padding: 1rem;
    `,
}));

export default () => {
    const { styles } = useStyles();
    return (
        <div className={styles.shell}>
            <div className={styles.brand}>
                <Brand />
            </div>
            <div className={styles.breadcrumb}>
                <PageTitle />
            </div>
            <div className={styles.addons}>
                <Addons />
            </div>
            <div className={styles.catalog}>
                <Catalog />
            </div>
            <div className={styles.content}>
                <Outlet />
            </div>
        </div>
    );
};
