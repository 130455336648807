import { Button, Card, Carousel, Col, List, Row, Space, Statistic } from 'antd';

const Picture = ({ url }) => (
    <div>
        <div style={{
            height: 220,
            lineHeight: 200,
            backgroundRepeat: 'no-repeat',
            backgroundImage: `url("${url}")`,
            backgroundPosition: 'center center',
            backgroundSize: 'cover',
        }} />
    </div>
);

const Banner = () => (
    <div style={{ position: 'relative', height: 220 }}>
        <div style={{ position: 'absolute', inset: 0 }}>
            <Carousel autoplay>
                <Picture url="/carousels/01.png" />
                <Picture url="/carousels/02.png" />
                <Picture url="/carousels/03.png" />
                <Picture url="/carousels/04.png" />
                <Picture url="/carousels/05.png" />
                <Picture url="/carousels/06.png" />
            </Carousel>
        </div>
    </div>
);

const Announcements = ({ title, news }) => (
    <Card title={title}>
        <List
            itemLayout="vertical"
            split={false}
            size="small"
            dataSource={news}
            renderItem={({ type, title, publishedAt }) => (
                <List.Item extra={publishedAt}>
                    【{type}】{title}
                </List.Item>
            )}
        />
    </Card>
);

const News = () => (
    <Card title="最新公告" extra={<Button type="link">查看更多</Button>}>
        <Row gutter={16}>
            <Col span={8}>
                <Announcements
                    title="商品上架"
                    news={[{
                        type: '新品上架',
                        title: '阳光人寿阳光橙C款定期寿险',
                        publishedAt: '09/18 11:18',
                    }, {
                        type: '独家定制',
                        title: '完美人生2024重大疾病保险',
                        publishedAt: '09/18 11:17',
                    }, {
                        type: '新品上架',
                        title: '华贵麦兜兜少儿重大疾病保险',
                        publishedAt: '09/14 15:41',
                    }]}
                />
            </Col>
            <Col span={8}>
                <Announcements
                    title="商品调整"
                    news={[{
                        type: '重要通知',
                        title: '爱心人寿客诉风险要求通知',
                        publishedAt: '09/19 18:07',
                    }, {
                        type: '重要通知',
                        title: '原恒大人寿产品调整注意事项',
                        publishedAt: '09/18 14:28',
                    }, {
                        type: '重要通知',
                        title: '未来之星学平险产品调整',
                        publishedAt: '09/13 11:50',
                    }]}
                />
            </Col>
            <Col span={8}>
                <Announcements
                    title="平台公告"
                    news={[{
                        type: '重要通知',
                        title: '中邮保险系统维护通知',
                        publishedAt: '09/21 18:14',
                    }, {
                        type: '重要通知',
                        title: '利多多2号新品发布会',
                        publishedAt: '09/20 14:00',
                    }, {
                        type: '重要通知',
                        title: '华夏人寿系统调整',
                        publishedAt: '09/14 16:21',
                    }]}
                />
            </Col>
        </Row>
    </Card>
);

const Performances = () => (
    <Card tabList={[{
        key: 'yesterday',
        label: '昨日业绩',
    }, {
        key: 'weekly',
        label: '本周业绩',
    }, {
        key: 'monthly',
        label: '本月业绩',
    }, {
        key: 'totally',
        label: '累积业绩',
    }]}>
        <Row gutter={16} justify="center" align="middle">
            <Col span={6}>
                <Statistic title="新增保单（个）" value={1} />
            </Col>
            <Col span={6}>
                <Statistic title="新增客户（个）" value={2} />
            </Col>
            <Col span={6}>
                <Statistic title="累计保费（元）" value={439.00} />
            </Col>
            <Col span={6}>
                <Statistic title="预计收入（元）" value={215.11} />
            </Col>
        </Row>
    </Card>
);

export default () => {
    return (
        <Space direction="vertical" style={{ width: '100%' }} size="large">
            <Banner />
            <News />
            <Performances />
        </Space>
    );
};