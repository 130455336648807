import { forest } from 'helpers/function';
import { useMemo } from 'react';
import { pages } from 'snapshots/routers';

/**
 * 小应用清单
 */
const applets = [{
    value: 'ops',
    title: '系统运营管理',
}, {
    value: 'dms',
    title: '数据管理系统',
}, {
    value: 'crm',
    title: '客户关系管理',
}, {
    value: 'hrm',
    title: '人力资源管理',
}];

/**
 * 获取包含应用功能列表的小应用清单。
 * @returns [{ title: string, value: string, children: [{ title: string, value: string }] }]
 */
export const useAppletsValue = () => useMemo(() => {
    const mappings = Object.groupBy(forest.listLeafNodes(pages).map(({ key, label }) => ({ value: key, title: label })), ({ value }) => {
        const index = value.indexOf('/');
        return value.substring(0, index);
    });
    return applets.map(({ value, ...applet }) => ({
        value,
        ...applet,
        children: mappings[value],
    }));
}, []);

/**
 * 查找当前页面的
 */
export const useAppletTitleValue = (code) => useMemo(() => {
    const applet = applets.find(({ value }) => code.startsWith(value));
    return applet !== undefined ? applet.title : null;
}, [code]);
