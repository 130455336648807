import { createStyles } from 'antd-style';
import React from 'react';

const useStyles = createStyles(({ css }, {
    direction,
    alignItems,
    alignContent,
    justifyContent,
}) => ({
    box: css`
      display: flex;
      flex-wrap: nowrap;
      flex-direction: ${direction};

      align-items: ${alignItems};
      align-content: ${alignContent};
      justify-content: ${justifyContent};
    `
}));

export default ({
    component = "div",
    direction = "horizontal", // horizontal | vertical | flex directions

    alignItems = null,
    alignContent = null,
    top = false,
    middle = false,
    bottom = false,

    justifyContent = null,
    left = false,
    center = false,
    right = false,

    children,
    className,
    ...props
}) => {
    if (direction === 'horizontal') {
        direction = 'row';
    } else if (direction === 'vertical') {
        direction = 'column';
    }

    if (alignItems === null) {
        if (middle) {
            alignItems = 'center';
        } else if (bottom) {
            alignItems = 'flex-end';
        } else {
            alignItems = 'flex-start';
        }
    }
    if (alignContent === null) {
        alignContent = alignItems;
    }

    if (justifyContent === null) {
        if (center) {
            justifyContent = 'center';
        } else if (right) {
            justifyContent = 'flex-end';
        } else {
            justifyContent = 'flex-start';
        }
    }

    if (direction === 'column' || direction === 'column-reverse') {
        [alignItems, justifyContent] = [justifyContent, alignItems];
    }

    const { styles, cx } = useStyles({
        direction,
        alignItems,
        alignContent,
        justifyContent,
    });
    return React.createElement(component, {
        className: cx(styles.box, className),
        ...props,
    }, children);
};
