import { Form, Input, Switch, Tag } from 'antd';
import { useCallback, useMemo, useState } from 'react';
import {
    useOpsSaveUser,
    useOpsUsersPageableState,
    useOpsUsersPagingValue,
    useSetOpsUsersFilter,
} from 'applets/ops/Users/state';
import EntityTable from 'views/components/EntityTable';

const schema = [{
    key: 'id',
    dataIndex: 'id',
    title: '#',
    width: 60,
}, {
    key: 'phone',
    dataIndex: 'phone',
    title: '手机号码',
}, {
    key: 'passwordStatus',
    dataIndex: 'passwordStatus',
    title: '密码状态',
    width: 120,
    render: (value) => {
        switch (value) {
            case 'NO_PASSWORD':
                return <Tag color="error">无登入密码</Tag>;
            case 'INITIAL':
                return <Tag color="warning">初始密码</Tag>;
            case 'CHANGED':
                return <Tag color="success">用户已修改</Tag>;
            default:
                return <Tag color="error">未知状态</Tag>;
        }
    },
}, {
    key: 'enabled',
    dataIndex: 'enabled',
    title: '用户状态',
    width: 100,
    render: (value) => {
        if (value) {
            return <Tag color="success">启用</Tag>;
        } else {
            return <Tag color="error">禁用</Tag>;
        }
    },
}, {
    key: 'updatedAt',
    dataIndex: 'updatedAt',
    title: '最后更新时间',
    width: 200,
}];

export default () => {
    const { total, items: users } = useOpsUsersPagingValue();
    const setFilter = useSetOpsUsersFilter();
    const [pageable, setPageable] = useOpsUsersPageableState();

    const [mode, setMode] = useState(null);
    const isNewMode = useMemo(() => mode === 'new', [mode]);
    const isEditMode = useMemo(() => mode === 'edit', [mode]);
    const onNew = useCallback(() => ({ enabled: true }), []);
    const saveUser = useOpsSaveUser();
    const onSave = useCallback(async ({ id = null, phone, password, enabled, remark }) => {
        await saveUser({
            user: {
                id,
                phone,
                password,
                enabled,
                remark,
            },
        });
    }, [saveUser]);

    return (
        <EntityTable
            name="用户"
            columns={schema}
            total={total}
            pageable={pageable}
            setPageable={setPageable}
            items={users}
            filters={[{ value: 'phone', label: '搜索', placeholder: '手机号码' }]}
            onFilter={setFilter}
            onNew={onNew}
            onSave={onSave}
            onModeChange={setMode}
        >
            {isEditMode ? (
                <Form.Item name="id" label="用户编号">
                    <Input disabled />
                </Form.Item>
            ) : null}
            <Form.Item name="phone" label="手机号码" rules={[{ required: isNewMode }]}>
                <Input disabled={isEditMode} />
            </Form.Item>
            <Form.Item name="password" label="初始密码" rules={[{ required: isNewMode }]}>
                <Input.Password />
            </Form.Item>
            <Form.Item name="enabled" label="用户状态" valuePropName="checked">
                <Switch checkedChildren="启用" unCheckedChildren="禁用" />
            </Form.Item>
            <Form.Item name="remark" label="备注">
                <Input.TextArea allowClear showCount />
            </Form.Item>
        </EntityTable>
    );
};
