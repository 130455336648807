import { ApolloClient, ApolloLink, ApolloProvider, concat, HttpLink, InMemoryCache } from '@apollo/client';
import { storageValue } from 'helpers/effects';
import { ACCOUNT_ID_KEY } from 'snapshots/constants';

export default ({ children }) => {
    const middleware = new ApolloLink((operation, forward) => {
        operation.setContext(({ headers = {} }) => {
            const accountId = storageValue(ACCOUNT_ID_KEY);
            if (accountId !== null) {
                return {
                    headers: {
                        ...headers,
                        'Account-ID': accountId,
                    }
                }
            } else {
                return { headers };
            }
        });
        return forward(operation);
    });
    const link = new HttpLink({ uri: '/graphql' });

    const client = new ApolloClient({
        link: concat(middleware, link),
        cache: new InMemoryCache(),
    });
    return (
        <ApolloProvider client={client}>
            {children}
        </ApolloProvider>
    )
};
