import { createGlobalStyle, createStyles, css } from 'antd-style';
import SignIn from 'views/components/SignIn';
import Columns, { Content, Left } from 'views/layouts/Columns';
import Stack from 'views/layouts/Stack';
import Viewport from 'views/layouts/Viewport';

const Global = createGlobalStyle`
  body {
    background-repeat: no-repeat;
    background-image: url("/images/background.png");
    background-position: center center;
    background-size: cover;

    div#app {
      position: relative;
      margin: 0;
      padding: 0;
      height: 100vh;
    }
  }
`;

const useStyles = createStyles({
    brand: css`
      width: 12rem;
      margin-bottom: 2rem;
    `
});

export default () => {
    const { styles } = useStyles();
    return (
        <Viewport>
            <Global />
            <Columns>
                <Left style={{ width: '60%' }} middle right>
                    <img src="/images/products.png" width="80%" alt="百香云服" />
                </Left>
                <Content middle center>
                    <Stack item="center">
                        <img src="/images/brand.png" className={styles.brand} alt="百香云服" />
                        <SignIn />
                    </Stack>
                </Content>
            </Columns>
        </Viewport>
    );
}
