import { Menu } from 'antd';
import { createStyles, css } from 'antd-style';
import { useCatalogValue, usePageValue } from 'snapshots/routers';

const useStyles = createStyles({
    menu: css`
      font-size: 1rem;

      .ant-menu-sub {
        background-color: transparent !important;
      }
    `,
});

export default ({ ...props }) => {
    const { styles } = useStyles();
    const { items, openKeys } = useCatalogValue();
    const { key: selectedKey = '' } = usePageValue();
    return (
        <Menu
            className={styles.menu}
            style={{ border: 0 }}
            mode="inline"
            selectedKeys={[selectedKey]}
            openKeys={openKeys}
            items={items}
            {...props}
        />
    );
};
