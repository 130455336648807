import { Form, Input, Radio, Select, Tag, Tooltip, TreeSelect } from 'antd';
import {
    useOpsDeletePosition,
    useOpsPositionsPageableState,
    useOpsPositionsPagingValue,
    useOpsSavePosition,
    useSetOpsPositionsFilter,
} from 'applets/ops/Positions/state';
import {
    QUERY_OPS_POSITIONS_OPTIONS,
    useOpsInstitutionAppletsValue,
    useOpsInstitutionsOptionsValue,
} from 'applets/ops/state';
import { useCallback, useEffect, useMemo, useState } from 'react';
import EntityTable from 'views/components/EntityTable';

const schema = [{
    key: 'id',
    dataIndex: 'id',
    title: '#',
    width: 60,
}, {
    key: 'institutionName',
    dataIndex: 'institutionName',
    title: '机构全称',
    width: 200,
}, {
    key: 'name',
    dataIndex: 'name',
    title: '岗位名称',
    width: 200,
}, {
    key: 'permissions',
    dataIndex: 'permissions',
    title: '授权',
    render: (permissions) => (
        <>{permissions.map((permission) => <Tag key={permission}>{permission}</Tag>)}</>
    ),
}, {
    key: 'members',
    dataIndex: 'members',
    title: '成员数量',
    width: 100,
}, {
    key: 'deletable',
    dataIndex: 'deletable',
    title: '类型',
    render: (deletable) => (deletable ? <Tag color="green">用户</Tag> : <Tag color="warning">系统</Tag>),
    width: 100,
}, {
    key: 'updatedAt',
    dataIndex: 'updatedAt',
    title: '最后更新时间',
    width: 180,
}];

export default () => {
    const { total = 0, items: positions = [] } = useOpsPositionsPagingValue();
    const setFilter = useSetOpsPositionsFilter();
    const [pageable = 0, setPageable = null] = useOpsPositionsPageableState();

    const [form] = Form.useForm();
    const institutionId = Form.useWatch('institutionId', form);
    useEffect(() => {
        form.setFieldValue('permissions', []);
    }, [institutionId]);

    const optionsInstitutions = useOpsInstitutionsOptionsValue();
    const applets = useOpsInstitutionAppletsValue(institutionId);
    const appletsExpandKeys = useMemo(() => applets.map(({ value }) => value), [applets]);

    const [mode, setMode] = useState(null);
    const isNewMode = useMemo(() => mode === 'new', [mode]);
    const isEditMode = useMemo(() => mode === 'edit', [mode]);
    const onNew = useCallback(() => {
        return { operation: 'INTERSECTION', deletable: false };
    }, []);
    const savePosition = useOpsSavePosition();
    const onSave = useCallback(async ({ id = null, institutionId = null, name, operation, authorities, deletable }) => {
        await savePosition({
            position: {
                id,
                institutionId,
                name,
                operation,
                authorities,
                deletable,
            },
        }, {
            refetchQueries: [{
                query: QUERY_OPS_POSITIONS_OPTIONS,
                variables: {
                    institutionId
                }
            }]
        });
    }, [savePosition]);
    const deletePosition = useOpsDeletePosition();
    const onDelete = useCallback(async ({ id, institutionId = null }) => {
        await deletePosition({ id }, {
            refetchQueries: [{
                query: QUERY_OPS_POSITIONS_OPTIONS,
                variables: {
                    institutionId
                }
            }]
        });
    }, [deletePosition]);

    return (
        <EntityTable
            form={form}
            name="岗位"
            columns={schema}
            total={total}
            pageable={pageable}
            setPageable={setPageable}
            items={positions}
            filters={[
                { value: 'words', label: '搜索', placeholder: '机构全名/机构简称/岗位名称' },
            ]}
            onFilter={setFilter}
            onNew={onNew}
            onSave={onSave}
            onDelete={onDelete}
            onModeChange={setMode}
        >
            {isEditMode ? (
                <Form.Item name="id" label="岗位编号">
                    <Input disabled />
                </Form.Item>
            ) : null}
            <Form.Item hidden={isEditMode} name="institutionId" label="所属机构" rules={[{ required: true }]}>
                <Select options={optionsInstitutions} placeholder="请选择所属机构" />
            </Form.Item>
            {isEditMode ? (
                <Form.Item name="institutionName" label="所属机构">
                    <Input disabled={isEditMode} />
                </Form.Item>
            ) : null}
            <Form.Item name="name" label="岗位名称" rules={[{ required: isNewMode }]}>
                <Input placeholder="请输入岗位名称" />
            </Form.Item>
            <Form.Item name="operation" label="授权方式" rules={[{ required: isNewMode }]}>
                <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    options={[
                        { value: 'INTERSECTION', label: '仅包含清单中的权限' },
                        { value: 'DIFFERENCE', label: '排除清单中的权限' },
                    ]}
                />
            </Form.Item>
            <Form.Item name="authorities" label="权限清单">
                {applets.length ? (
                    <TreeSelect
                        multiple
                        treeCheckable
                        treeDefaultExpandAll
                        showCheckedStrategy={TreeSelect.SHOW_CHILD}
                        placeholder="赋予或排除的权限列表"
                        treeExpandedKeys={appletsExpandKeys}
                        treeData={applets}
                    />
                ) : (
                    <Input disabled placeholder="请先选择所属机构" />
                )}
            </Form.Item>
            <Form.Item name="deletable" label="用户是否可删除" rules={[{ required: isNewMode }]}>
                <Radio.Group
                    optionType="button"
                    buttonStyle="solid"
                    options={[
                        { value: false, label: <Tooltip placement="top" title="用户不可删除">系统岗位</Tooltip> },
                        { value: true, label: <Tooltip placement="top" title="用户可删除">用户岗位</Tooltip> },
                    ]}
                />
            </Form.Item>
        </EntityTable>
    );
};
