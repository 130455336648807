import { useCallback, useMemo } from 'react';
import { useSetRecoilState } from 'recoil';

export const useRecoilBoolean = (state) => {
    const setVisible = useSetRecoilState(state);
    const toggle = useCallback(() => {
        setVisible((visible) => !visible);
    }, [setVisible]);
    const show = useCallback(() => {
        setVisible(true);
    }, [setVisible]);
    const hide = useCallback(() => {
        setVisible(false);
    }, [setVisible]);
    return useMemo(() => ({
        toggle,
        show,
        hide,
    }), [toggle, show, hide])
};
