import { gql } from '@apollo/client';
import { forest } from 'helpers/function';
import { useQueryValue } from 'helpers/graphql';
import { useMemo } from 'react';
import { useAppletsValue } from 'snapshots/applets';

export const QUERY_HRM_DEPARTMENTS_OPTIONS = gql`
    query HrmDepartmentsOptions {
        hrmDepartmentsOptions {
            value
            label
            parent
        }
    }
`;

export const QUERY_HRM_POSITIONS_OPTIONS = gql`
    query HrmPositionsOptions {
        hrmPositionsOptions {
            value
            label
        }
    }
`;

export const useHrmInstitutionAppletsValue = () => {
    const applets = useAppletsValue();
    /** @type string[] */
    const permissions = useQueryValue(gql`
        query HrmInstitutionPermissions {
            hrmInstitutionPermissions
        }
    `, {
        defaultValue: [],
    });
    return useMemo(() => {
        const set = new Set(permissions);
        const predicate = ({ value }) => set.has(value);
        return forest.filter(applets, predicate);
    }, [applets, permissions]);
};

const buildOptionTree = (mappings, parent = null) => {
    if (mappings.has(parent)) {
        return mappings.get(parent).map((option) => {
            if (mappings.has(option.value)) {
                return {
                    ...option,
                    children: buildOptionTree(mappings, option.value),
                };
            } else {
                return option;
            }
        });
    } else {
        return [];
    }
};

export const useHrmDepartmentsOptionsValue = () => {
    const options = useQueryValue(QUERY_HRM_DEPARTMENTS_OPTIONS, {
        defaultValue: [],
    });
    return useMemo(() => {
        const mappings = options.reduce((mappings, { parent, ...option }) => {
            if (!mappings.has(parent)) {
                mappings.set(parent, []);
            }
            mappings.get(parent).push(option);
            return mappings;
        }, new Map());
        return buildOptionTree(mappings);
    }, [options]);
};

export const useHrmPositionsOptionsValue = () => useQueryValue(QUERY_HRM_POSITIONS_OPTIONS, {
    defaultValue: [],
});
