import { createGlobalStyle } from 'antd-style';

const Global = createGlobalStyle`
  html, body, #app, #app > div.ant-app {
    height: 100vh;
  }
`;

/**
 * Viewport Layout：铺满全屏。
 *
 * 主要是样式，控件部分没有内容。
 */
export default ({ children }) => (
    <>
        <Global />
        {children}
    </>
);
