import { Cascader, Form, Input, InputNumber, Space, Switch, Tag, TreeSelect } from 'antd';
import * as districts from 'helpers/districts';
import { useCallback, useMemo, useState } from 'react';
import {
    useOpsDeleteInstitution,
    useOpsInstitutionsPageableState,
    useOpsInstitutionsPagingValue,
    useOpsSaveInstitution,
    useSetOpsInstitutionsFilter,
} from 'applets/ops/Institutions/state';
import { useAppletsValue } from 'snapshots/applets';
import EntityTable from 'views/components/EntityTable';

const schema = [{
    key: 'id',
    dataIndex: 'id',
    title: '#',
    width: 60,
}, {
    key: 'name',
    dataIndex: 'name',
    title: '全称',
    width: 200,
}, {
    key: 'alias',
    dataIndex: 'alias',
    title: '简称',
    width: 120,
}, {
    key: 'pages',
    dataIndex: 'pages',
    title: '许可',
    render: (pages) => (
        <>{pages.map((page) => <Tag key={page}>{page}</Tag>)}</>
    ),
}, {
    key: 'percentage',
    dataIndex: 'percentage',
    title: '佣金比率',
    render: (value) => `${value}%`,
    width: 100,
}, {
    key: 'enabled',
    dataIndex: 'enabled',
    title: '状态',
    width: 60,
    render: (value) => {
        if (value) {
            return <Tag color="success">启用</Tag>;
        } else {
            return <Tag color="error">禁用</Tag>;
        }
    },
}, {
    key: 'updatedAt',
    dataIndex: 'updatedAt',
    title: '最后更新时间',
    width: 180,
}];

export default () => {
    const { total, items: institutions } = useOpsInstitutionsPagingValue();
    const setFilter = useSetOpsInstitutionsFilter();
    const [pageable, setPageable] = useOpsInstitutionsPageableState();
    const applets = useAppletsValue();

    const [mode, setMode] = useState(null);
    const isNewMode = useMemo(() => mode === 'new', [mode]);
    const isEditMode = useMemo(() => mode === 'edit', [mode]);
    const onNew = useCallback(() => ({ percentage: '100.00', enabled: true }), []);
    const saveInstitution = useOpsSaveInstitution();
    const onSave = useCallback(async ({ id = null, name, alias, bio, address: { code = [], street = '' }, authorities, percentage, enabled, remark }) => {
        const address = code?.length && street ? `${code[code.length - 1]}/${street}` : null;
        await saveInstitution({
            institution: {
                id,
                name,
                alias,
                address,
                bio,
                authorities,
                percentage,
                enabled,
                remark,
            },
        });
    }, [saveInstitution]);
    const deleteInstitution = useOpsDeleteInstitution();
    const onDelete = useCallback(async ({ id }) => {
        await deleteInstitution({ id });
    }, [deleteInstitution]);

    return (
        <EntityTable
            name="机构"
            columns={schema}
            total={total}
            pageable={pageable}
            setPageable={setPageable}
            items={institutions}
            filters={[
                { value: 'words', label: '搜索', placeholder: '机构全名/机构简称/机构简介' },
            ]}
            onFilter={setFilter}
            onNew={onNew}
            onSave={onSave}
            onDelete={onDelete}
            onModeChange={setMode}
        >
            {isEditMode ? (
                <Form.Item name="id" label="机构编号">
                    <Input disabled />
                </Form.Item>
            ) : null}
            <Form.Item name="name" label="机构全称" rules={[{ required: true }]}>
                <Input placeholder="通常是公司的完整名称" />
            </Form.Item>
            <Form.Item name="alias" label="机构简称" rules={[{ required: true }]}>
                <Input placeholder="用于页面展示" />
            </Form.Item>
            <Form.Item label="机构地址">
                <Space direction="vertical" style={{ width: '100%' }}>
                    <Form.Item noStyle name={['address', 'code']}>
                        <Cascader options={districts.options} placeholder="省/市/区" />
                    </Form.Item>
                    <Form.Item noStyle name={['address', 'street']}>
                        <Input placeholder="街道与详细地址" />
                    </Form.Item>
                </Space>
            </Form.Item>
            <Form.Item name="bio" label="机构简介">
                <Input.TextArea allowClear showCount placeholder="机构简短的介绍" />
            </Form.Item>
            <Form.Item name="authorities" label="许可">
                <TreeSelect
                    multiple
                    treeCheckable
                    treeDefaultExpandAll
                    showCheckedStrategy={TreeSelect.SHOW_CHILD}
                    placeholder="授予机构系统使用许可"
                    treeData={applets}
                />
            </Form.Item>
            <Form.Item name="percentage" label="佣金比例" rules={[{ required: isNewMode }]}>
                <InputNumber
                    style={{ width: '100%' }}
                    stringMode
                    min="0.00"
                    max="100.00"
                    step="0.01"
                    placeholder="0.00%-100.00%"
                    formatter={(value) => `${value}%`}
                />
            </Form.Item>
            <Form.Item name="enabled" label="机构状态" valuePropName="checked">
                <Switch checkedChildren="启用" unCheckedChildren="禁用" />
            </Form.Item>
            <Form.Item name="remark" label="备注">
                <Input.TextArea allowClear showCount />
            </Form.Item>
        </EntityTable>
    );
};
