import { gql } from '@apollo/client';
import { useMutate, useQueryValue } from 'helpers/graphql';
import { useMemo } from 'react';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

const QUERY_OPS_EMPLOYEES = gql`
    query OpsEmployees($filter: FilterInput!, $pageable: PageInput!) {
        opsEmployees(filter: $filter, pageable: $pageable) {
            total
            items {
                ... on EmployeeProjection {
                    id
                    institutionId
                    institutionName
                    phone
                    name
                    employeed
                    departments {
                        id
                        name
                    }
                    positions {
                        id
                        name
                    }
                    updatedAt
                }
            }
        }
    }
`;

const filter = atom({
    key: 'ops.employees.filter',
    default: {
        words: '',
    }
});

export const useOpsEmployeesFilterState = () => useRecoilState(filter);
export const useOpsEmployeesFilterValue = () => useRecoilValue(filter);
export const useSetOpsEmployeesFilter = () => useSetRecoilState(filter);

const pageable = atom({
    key: 'ops.employees.pageable',
    default: {
        page: 1,
        size: 50,
    }
});

export const useOpsEmployeesPageableState = () => useRecoilState(pageable);
export const useOpsEmployeesPageableValue = () => useRecoilValue(pageable);
export const useSetOpsEmployeesPageable = () => useSetRecoilState(pageable);

export const useOpsEmployeesPagingValue = () => {
    const filter = useOpsEmployeesFilterValue();
    const pageable = useOpsEmployeesPageableValue();
    const { total, items } = useQueryValue(QUERY_OPS_EMPLOYEES, {
        variables: {
            filter,
            pageable,
        },
        defaultValue: {
            total: 0,
            items: [],
        },
    });
    const employees = useMemo(() => items.map(({ departments, positions, ...item }) => {
        const { id: departmentId = null, name: department = null } = departments?.[0] || {};
        const { id: positionId = null, name: position = null } = positions?.[0] || {};
        return {
            ...item,
            key: item.id,
            departmentId,
            department,
            positionId,
            position,
            $deletable: true,
        };
    }), [items]);
    return {
        total,
        items: employees
    };
};

export const useOpsSaveEmployee = () => {
    const filter = useOpsEmployeesFilterValue();
    const pageable = useOpsEmployeesPageableValue();
    return useMutate(gql`
        mutation OpsSaveEmployee($employee: EmployeeInput!) {
            opsSaveEmployee(employee: $employee)
        }
    `, {
        refetchQueries: [{
            query: QUERY_OPS_EMPLOYEES,
            variables: {
                filter,
                pageable,
            },
        }]
    });
};

export const useOpsDeleteEmployee = () => {
    const filter = useOpsEmployeesFilterValue();
    const pageable = useOpsEmployeesPageableValue();
    return useMutate(gql`
        mutation OpsDeleteEmployee($id: ID!) {
            opsDeleteEmployee(id: $id)
        }
    `, {
        refetchQueries: [{
            query: QUERY_OPS_EMPLOYEES,
            variables: {
                filter,
                pageable,
            },
        }]
    });
};
