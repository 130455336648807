import { Form, Input, InputNumber, Select, TreeSelect } from 'antd';
import {
    useOpsDeleteDepartment,
    useOpsDepartmentsPageableState,
    useOpsDepartmentsPagingValue,
    useOpsSaveDepartment,
    useSetOpsDepartmentsFilter,
} from 'applets/ops/Departments/state';
import {
    QUERY_OPS_DEPARTMENTS_OPTIONS,
    useOpsDepartmentsOptionsValue,
    useOpsInstitutionsOptionsValue,
} from 'applets/ops/state';
import { useCallback, useEffect, useMemo, useState } from 'react';
import EntityTable from 'views/components/EntityTable';

const schema = [{
    key: 'id',
    dataIndex: 'id',
    title: '#',
    width: 60,
}, {
    key: 'institutionName',
    dataIndex: 'institutionName',
    title: '机构全称',
}, {
    key: 'name',
    dataIndex: 'name',
    title: '部门名称',
    width: 300,
}, {
    key: 'parentName',
    dataIndex: 'parentName',
    title: '上级部门',
    width: 300,
}, {
    key: 'percentage',
    dataIndex: 'percentage',
    title: '佣金比例',
    width: 100,
    render: (value) => `${value}%`,
}, {
    key: 'members',
    dataIndex: 'members',
    title: '成员数量',
    width: 100,
}, {
    key: 'updatedAt',
    dataIndex: 'updatedAt',
    title: '最后更新时间',
    width: 180,
}];

export default () => {
    const { total, items: positions } = useOpsDepartmentsPagingValue();
    const setFilter = useSetOpsDepartmentsFilter();
    const [pageable = 0, setPageable = null] = useOpsDepartmentsPageableState();

    const [form] = Form.useForm();
    const institutionId = Form.useWatch('institutionId', form);
    useEffect(() => {
        form.setFieldValue('parentId', null);
    }, [institutionId]);

    const optionsInstitutions = useOpsInstitutionsOptionsValue();
    const optionsDepartments = useOpsDepartmentsOptionsValue(institutionId);

    const [mode, setMode] = useState(null);
    const isNewMode = useMemo(() => mode === 'new', [mode]);
    const isEditMode = useMemo(() => mode === 'edit', [mode]);
    const onNew = useCallback(() => ({ percentage: '100.00' }), []);
    const saveDepartment = useOpsSaveDepartment();
    const onSave = useCallback(async ({ id = null, institutionId = null, parentId = null, name, percentage }) => {
        await saveDepartment({
            department: {
                id,
                institutionId,
                parentId,
                name,
                percentage,
            },
        }, {
            refetchQueries: [{
                query: QUERY_OPS_DEPARTMENTS_OPTIONS,
                variables: {
                    institutionId
                }
            }]
        });
    }, [saveDepartment]);
    const deleteDepartment = useOpsDeleteDepartment();
    const onDelete = useCallback(async ({ id, institutionId = null }) => {
        await deleteDepartment({ id }, {
            refetchQueries: [{
                query: QUERY_OPS_DEPARTMENTS_OPTIONS,
                variables: {
                    institutionId
                }
            }]
        });
    }, [deleteDepartment]);

    return (
        <EntityTable
            form={form}
            name="部门"
            columns={schema}
            total={total}
            pageable={pageable}
            setPageable={setPageable}
            items={positions}
            filters={[
                { value: 'words', label: '搜索', placeholder: '机构全名/机构简称/部门名称' },
            ]}
            onFilter={setFilter}
            onNew={onNew}
            onSave={onSave}
            onDelete={onDelete}
            onModeChange={setMode}
        >
            {isEditMode ? (
                <Form.Item name="id" label="部门编号">
                    <Input disabled />
                </Form.Item>
            ) : null}
            <Form.Item hidden={isEditMode} name="institutionId" label="所属机构" rules={[{ required: isNewMode }]}>
                <Select disabled={isEditMode} options={optionsInstitutions} placeholder="请选择所属机构" />
            </Form.Item>
            {isEditMode ? (
                <Form.Item name="institutionName" label="所属机构">
                    <Input disabled={isEditMode} />
                </Form.Item>
            ) : null}
            {isNewMode ? (
                <Form.Item name="parentId" label="上级部门">
                    <TreeSelect
                        allowClear
                        treeDefaultExpandAll
                        placeholder="请选择上级部门"
                        treeData={optionsDepartments}
                    />
                </Form.Item>
            ) : null}
            {isEditMode ? (
                <Form.Item name="parent" label="上级部门">
                    <Input disabled={isEditMode} />
                </Form.Item>
            ) : null}
            <Form.Item name="name" label="部门名称" rules={[{ required: isNewMode }]}>
                <Input placeholder="请输入部门名称" />
            </Form.Item>
            <Form.Item name="percentage" label="佣金比例" rules={[{ required: isNewMode }]}>
                <InputNumber
                    style={{ width: '100%' }}
                    stringMode
                    min="0.00"
                    max="100.00"
                    step="0.01"
                    placeholder="0.00%-100.00%"
                    formatter={(value) => `${value}%`}
                />
            </Form.Item>
        </EntityTable>
    );
};
