import { DownOutlined, LogoutOutlined, SecurityScanOutlined, SwapOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Dropdown, Tag } from 'antd';
import { createStyles } from 'antd-style';
import { useMemo } from 'react';
import { useDialogPasswordVisible, useDialogProfileVisible } from 'snapshots/profile';
import { useSignOff } from 'snapshots/session';
import {
    useShellAccountIdValue,
    useShellAccountsValue,
    useShellAccountValue,
    useSwitchShellAccount,
} from 'snapshots/shell';
import ProfileDialog from 'views/components/ProfileDialog';
import SecurityDialog from 'views/components/SecurityDialog';

const useStyles = createStyles(({ css, token }) => ({
    user: css`
      display: grid;
      grid-template-columns: 40px auto 20px;
      grid-template-rows: 50% 50%;
      grid-template-areas: 'avatar name arrow'
                           'avatar groups arrow';
      grid-column-gap: 0.5rem;
      height: 100%;
      padding-right: 1rem;
      user-select: none;
      cursor: pointer;
    `,
    avatar: css`
      grid-area: avatar;
      justify-self: center;
      align-self: center;
      background-color: ${token.colorSuccess};
    `,
    name: css`
      grid-area: name;
      align-self: end;
      color: ${token.colorText};
    `,
    groups: css`
      grid-area: groups;
      align-self: start;
      color: ${token.colorTextLabel};
    `,
    arrow: css`
      grid-area: arrow;
      justify-self: end;
      align-self: center;
    `,
}));

const Account = ({ onMouseEnter, onMouseLeave, onFocus, onClick }) => {
    const { name = '', departments = [], positions = [] } = useShellAccountValue();
    const { styles } = useStyles();
    const avatar = useMemo(() => (name ? name[0] : ''), [name]);
    return (
        <div className={styles.user}
             onMouseEnter={onMouseEnter}
             onMouseLeave={onMouseLeave}
             onFocus={onFocus}
             onClick={onClick}
        >
            <Avatar className={styles.avatar} size="large">{avatar}</Avatar>
            <div className={styles.name}>{name}</div>
            <div className={styles.groups}>
                {departments.map(({ name }) => <Tag key={name}>{name}</Tag>)}
                {positions.map(({ name }) => <Tag key={name}>{name}</Tag>)}
            </div>
            <div className={styles.arrow}>
                <DownOutlined style={{ fontSize: '10px' }} />
            </div>
        </div>
    );
};

export default () => {
    const accounts = useShellAccountsValue();
    const selectedKeys = useShellAccountIdValue();
    const { show: onProfileClick } = useDialogProfileVisible();
    const { show: onPasswordClick } = useDialogPasswordVisible();
    const onSwitchAccountClick = useSwitchShellAccount();
    const onSignOffClick = useSignOff();

    return (
        <>
            <Dropdown placement="bottomRight" menu={{
                selectedKeys: [selectedKeys],
                items: [{
                    key: 'user-profile',
                    label: '个人信息',
                    icon: <UserOutlined />,
                    onClick: onProfileClick,
                }, {
                    key: 'password-reset',
                    label: '修改密码',
                    icon: <SecurityScanOutlined />,
                    onClick: onPasswordClick,
                }, accounts.length > 1 ? {
                    key: 'switch-account',
                    label: '切换账号',
                    icon: <SwapOutlined />,
                    children: accounts.map(({ id, institutionName, name }) => ({
                        key: id,
                        label: `${institutionName} - ${name}`,
                        onClick: () => onSwitchAccountClick(id),
                    })),
                } : null, {
                    key: 'sign-off',
                    label: '安全退出',
                    icon: <LogoutOutlined />,
                    onClick: onSignOffClick,
                }],
            }}>
                <Account />
            </Dropdown>
            <ProfileDialog />
            <SecurityDialog />
        </>
    );
};
