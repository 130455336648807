import { Button, Table } from 'antd';

const columns = [{
    key: 'name', dataIndex: 'name', title: '姓名',
}, {
    key: 'phone', dataIndex: 'phone', title: '手机号',
}, {
    key: 'count', dataIndex: 'count', title: '保单数',
}, {
    key: 'amount', dataIndex: 'amount', title: '总保费',
}, {
    key: 'action', dataIndex: 'action', title: '操作', render: () => <Button type="link">查看保险明细</Button>,
}];

const data = [{
    'phone': '155*****392', 'name': '王鹤', 'amount': '415.89', 'count': '3',
}, {
    'phone': '150*****966', 'name': '丛原', 'amount': '439.0', 'count': '1',
}, {
    'phone': '137*****101', 'name': '肖雁', 'amount': '20000.0', 'count': '1',
}, {
    'phone': '', 'name': '谢静威', 'amount': '100.0', 'count': '1',
}, {
    'phone': '131*****636', 'name': '李影影', 'amount': '30000.0', 'count': '1',
}, {
    'phone': '156*****558', 'name': '黄淑瑶', 'amount': '80000.0', 'count': '2',
}, {
    'phone': '186*****677', 'name': '许晶', 'amount': '50000.0', 'count': '1',
}, {
    'phone': '177*****089', 'name': '范锐琪', 'amount': '4025.0', 'count': '1',
}, {
    'phone': '188****3923', 'name': '张佳佳', 'amount': '50100.0', 'count': '2',
}, {
    'phone': '136****5353', 'name': '谢静威', 'amount': '50000.0', 'count': '1',
}];

export default () => {
    return (
        <Table style={{ width: '100%' }} columns={columns} dataSource={data} />
    );
};
