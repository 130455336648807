import { useMemo } from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import { useRoutersValue } from 'snapshots/routers';
import ShellContainer from 'views/containers/ShellContainer';
import Viewport from 'views/layouts/Viewport';
import NotFound from 'views/pages/NotFound';

export default () => {
    const children = useRoutersValue();
    const router = useMemo(() => createBrowserRouter([{
        path: '/',
        Component: ShellContainer,
        children,
    }, {
        path: '*',
        Component: NotFound,
    }]), [children]);
    return (
        <Viewport>
            <RouterProvider router={router} />
        </Viewport>
    );
};
