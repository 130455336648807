import { gql } from '@apollo/client';
import { forest } from 'helpers/function';
import { useQueryValue } from 'helpers/graphql';
import { useMemo } from 'react';
import { useAppletsValue } from 'snapshots/applets';

export const QUERY_OPS_INSTITUTIONS_OPTIONS = gql`
    query OpsInstitutionsOptions {
        opsInstitutionsOptions {
            value
            label
        }
    }
`;

export const QUERY_OPS_DEPARTMENTS_OPTIONS = gql`
    query OpsDepartmentsOptions($institutionId: ID) {
        opsDepartmentsOptions(institutionId: $institutionId) {
            value
            label
            parent
        }
    }
`;

export const QUERY_OPS_POSITIONS_OPTIONS = gql`
    query OpsPositionsOptions($institutionId: ID) {
        opsPositionsOptions(institutionId: $institutionId) {
            value
            label
        }
    }
`;

const buildOptionTree = (mappings, parent = null) => {
    if (mappings.has(parent)) {
        return mappings.get(parent).map((option) => {
            if (mappings.has(option.value)) {
                return {
                    ...option,
                    children: buildOptionTree(mappings, option.value),
                };
            } else {
                return option;
            }
        });
    } else {
        return [];
    }
};

export const useOpsInstitutionAppletsValue = (id) => {
    const applets = useAppletsValue();
    /** @type string[] */
    const permissions = useQueryValue(gql`
        query OpsInstitutionPermissions($id: ID) {
            opsInstitutionPermissions(id: $id)
        }
    `, {
        variables: { id },
        defaultValue: [],
    });
    return useMemo(() => {
        const set = new Set(permissions);
        const predicate = ({ value }) => set.has(value);
        return forest.filter(applets, predicate);
    }, [applets, permissions]);
};

export const useOpsInstitutionsOptionsValue = () => useQueryValue(QUERY_OPS_INSTITUTIONS_OPTIONS, {
    defaultValue: [],
});

export const useOpsDepartmentsOptionsValue = (institutionId) => {
    const options = useQueryValue(QUERY_OPS_DEPARTMENTS_OPTIONS, {
        variables: {
            institutionId,
        },
        defaultValue: [],
    });
    return useMemo(() => {
        const mappings = options.reduce((mappings, { parent, ...option }) => {
            if (!mappings.has(parent)) {
                mappings.set(parent, []);
            }
            mappings.get(parent).push(option);
            return mappings;
        }, new Map());
        return buildOptionTree(mappings);
    }, [options]);
};

export const useOpsPositionsOptionsValue = (institutionId) => useQueryValue(QUERY_OPS_POSITIONS_OPTIONS, {
    variables: {
        institutionId,
    },
    defaultValue: [],
});
