import { createRoot } from 'react-dom/client';
import { RecoilRoot } from 'recoil';
import Application from 'views/pages/Application';
import AntDesignProvider from 'views/providers/AntDesignProvider';
import GraphQLProvider from 'views/providers/GraphQLProvider';
import SessionProvider from 'views/providers/SessionProvider';

const container = document.getElementById('app');
const root = createRoot(container);
root.render(
    <RecoilRoot>
        <AntDesignProvider>
            <GraphQLProvider>
                <SessionProvider>
                    <Application />
                </SessionProvider>
            </GraphQLProvider>
        </AntDesignProvider>
    </RecoilRoot>,
);
