import {
    ClockCircleOutlined,
    DollarOutlined,
    DownOutlined,
    FolderOutlined,
    LinkOutlined,
    MoneyCollectOutlined,
    StarOutlined,
    UpOutlined,
} from '@ant-design/icons';
import { Avatar, Button, Card, Checkbox, Image, List, Space, Tabs, Tag, Typography } from 'antd';
import { createStyles } from 'antd-style';
import React, { useState } from 'react';

const categories = [{
    key: '全部',
    label: '全部',
}, {
    key: '意外险',
    label: '意外险',
}, {
    key: '定期寿险',
    label: '定期寿险',
}, {
    key: '增额终身寿险',
    label: '增额终身寿险',
}, {
    key: '年金险',
    label: '年金险',
}, {
    key: '百万医疗险',
    label: '百万医疗险',
}, {
    key: '重大疾病险',
    label: '重大疾病险',
}, {
    key: '家财险',
    label: '家财险',
}, {
    key: '医疗险',
    label: '医疗险',
}, {
    key: '防癌险',
    label: '防癌险',
}, {
    key: '理财产品两全险',
    label: '理财产品两全险',
}, {
    key: '责任险',
    label: '责任险',
}, {
    key: '旅游意外险',
    label: '旅游意外险',
}, {
    key: '护理险',
    label: '护理险',
}];

const data = [{
    'activityEndTime': null,
    'activityStartTime': null,
    'apiUrl': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1704318662308700160',
    'bannerImg': '',
    'commissionImg': '',
    'commissionNum': '71',
    'companyLogo': '',
    'companyName': '弘康人寿',
    'dockingChannel': '1',
    'guarateeProfile': '',
    'id': '1706617786353754300',
    'insureType': '',
    'isAppointInsure': '0',
    'isFollow': '0',
    'isGift': '0',
    'isHot': '1',
    'isPresell': '0',
    'isShow': '0',
    'label': '',
    'lowPrice': '1000.00',
    'maxCommission': '74.5',
    'mobileBannerImg': '',
    'planToOffTime': null,
    'platProductId': '1701063852789714945',
    'product': {
        'acceptInsuranceAge': '出生满30天至75周岁',
        'bannerImg': '',
        'commission': '74.5%',
        'companyCode': '',
        'companyFullName': '',
        'companyLogo': 'https://www.fxyf99.com/upload/FILE/20181119/20181119101552588.jpg',
        'companyName': '',
        'estimatedOnboardTime': '',
        'firstSettlementRule': '',
        'goodnessSign': '',
        'guarateeLimit': '终身',
        'guarateeProfile': '年度有效保额3.0%逐年增长；\n支持资金周转可应急；\n满足多种场景资金需求',
        'insureType': '["1"]',
        'isGift': '0',
        'isInsureCustom': false,
        'isNewFlow': '1',
        'isPresell': '0',
        'isSupportWebapp': false,
        'keyword': '',
        'label': '',
        'mobileBannerImg': '',
        'name': '',
        'presellImg': '',
        'presellUrl': '',
        'productCategory': '3',
        'productNo': '04081',
        'productVideoThumbnail': '',
        'productVideoUrl': '',
        'profile': '年度有效保额3.0%逐年增长；年末现金价值写进合同；支持资金周转可应急',
        'remark': '',
        'renewalRecoverRule': '',
        'sellType': '["3"]',
        'selloutNodes': '',
        'shareImg': '',
        'shortImg': 'https://file.fxyf99.com/product_material/20230922sFSjNp.jpg',
        'shortMobImg': 'https://file.fxyf99.com/product_material/20230922klBbvh.jpg',
        'showCommission': '2.5%-74.5%',
        'subsidyCommission': '',
        'updateStatus': '0',
        'url': '',
    },
    'productCategory': '3',
    'productId': '1704318662308700160',
    'productLabel': '线下经代$1250560069068468224,成人$1250560149498441728',
    'productLabelList': [
        {
            'isFirst': 1,
            'label': '线下经代',
        },
        {
            'isFirst': 0,
            'label': '成人',
        },
    ],
    'productName': '弘康人寿利多多2号终身寿险',
    'productNo': '',
    'productType': '1',
    'recommendRate': '5',
    'recordStatus': '2',
    'sellType': '',
    'selloutNodes': '[]',
    'shortImg': '',
    'showProductInfomation': false,
    'status': '2',
    'subsidyCommissionNum': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1704318662308700160',
}, {
    'activityEndTime': null,
    'activityStartTime': null,
    'apiUrl': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1701840789649690624',
    'bannerImg': '',
    'commissionImg': '',
    'commissionNum': '30',
    'companyLogo': '',
    'companyName': '信泰人寿',
    'dockingChannel': '1',
    'guarateeProfile': '',
    'id': '1702651289291423837',
    'insureType': '',
    'isAppointInsure': '0',
    'isFollow': '0',
    'isGift': '0',
    'isHot': '1',
    'isPresell': '0',
    'isShow': '0',
    'label': '人工核保',
    'lowPrice': '47.40',
    'maxCommission': '85',
    'mobileBannerImg': '',
    'planToOffTime': null,
    'platProductId': '1686629087796707331',
    'product': {
        'acceptInsuranceAge': '出生满28日至60周岁',
        'bannerImg': '',
        'commission': '85%',
        'companyCode': '',
        'companyFullName': '',
        'companyLogo': 'https://www.fxyf99.com/upload/FILE/20230808/412e67b2eb87482cb8decd18063a767f.png',
        'companyName': '',
        'estimatedOnboardTime': '',
        'firstSettlementRule': '',
        'goodnessSign': '',
        'guarateeLimit': '终身',
        'guarateeProfile': '可选80周岁前首次重疾双倍赔\n可选重度恶性肿瘤、特定心脑疾病二次赔\n少儿特定/罕见重疾额外加倍赔',
        'insureType': '["1"]',
        'isGift': '0',
        'isInsureCustom': false,
        'isNewFlow': '1',
        'isPresell': '0',
        'isSupportWebapp': false,
        'keyword': '',
        'label': '',
        'mobileBannerImg': '',
        'name': '',
        'presellImg': '',
        'presellUrl': '',
        'productCategory': '3',
        'productNo': '01114',
        'productVideoThumbnail': '',
        'productVideoUrl': '',
        'profile': '覆盖195种疾病保障广；首次重疾赔付后，轻中症责任不终止，等待期内发生中轻症，仅该种中轻症责任终止，其他中轻症责任继续有效；20种少儿特定/罕见重疾加倍赔，多种可选责任按需强化保障。',
        'remark': '',
        'renewalRecoverRule': '',
        'sellType': '["3"]',
        'selloutNodes': '',
        'shareImg': '',
        'shortImg': 'https://file.fxyf99.com/product_material/20230913BPSuuS.jpg',
        'shortMobImg': 'https://file.fxyf99.com/product_material/20230913SsE193.jpg',
        'showCommission': '4%-85%',
        'subsidyCommission': '',
        'updateStatus': '0',
        'url': '',
    },
    'productCategory': '3',
    'productId': '1701840789649690624',
    'productLabel': '线下经代$1250560069068468224,成人$1250560149498441728',
    'productLabelList': [
        {
            'isFirst': 1,
            'label': '线下经代',
        },
        {
            'isFirst': 0,
            'label': '成人',
        },
    ],
    'productName': '信泰完美人生2024重大疾病保险【60岁以下-湖北北京广东（含深圳）】',
    'productNo': '',
    'productType': '1',
    'recommendRate': '5',
    'recordStatus': '2',
    'sellType': '',
    'selloutNodes': '[]',
    'shortImg': '',
    'showProductInfomation': false,
    'status': '2',
    'subsidyCommissionNum': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1701840789649690624',
}, {
    'activityEndTime': null,
    'activityStartTime': null,
    'apiUrl': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1707278418162225152',
    'bannerImg': '',
    'commissionImg': '',
    'commissionNum': '30',
    'companyLogo': '',
    'companyName': '信泰人寿',
    'dockingChannel': '1',
    'guarateeProfile': '',
    'id': '1707288184699752534',
    'insureType': '',
    'isAppointInsure': '0',
    'isFollow': '0',
    'isGift': '0',
    'isHot': '1',
    'isPresell': '0',
    'isShow': '0',
    'label': '人工核保',
    'lowPrice': '47.40',
    'maxCommission': '85',
    'mobileBannerImg': '',
    'planToOffTime': null,
    'platProductId': '1686629087796701115',
    'product': {
        'acceptInsuranceAge': '出生满28日至60周岁',
        'bannerImg': '',
        'commission': '85%',
        'companyCode': '',
        'companyFullName': '',
        'companyLogo': 'https://www.fxyf99.com/upload/FILE/20230808/412e67b2eb87482cb8decd18063a767f.png',
        'companyName': '',
        'estimatedOnboardTime': '',
        'firstSettlementRule': '',
        'goodnessSign': '',
        'guarateeLimit': '终身',
        'guarateeProfile': '可选80周岁前首次重疾双倍赔\n可选重度恶性肿瘤、特定心脑疾病二次赔\n少儿特定/罕见重疾额外加倍赔',
        'insureType': '["4"]',
        'isGift': '0',
        'isInsureCustom': false,
        'isNewFlow': '1',
        'isPresell': '0',
        'isSupportWebapp': false,
        'keyword': '',
        'label': '',
        'mobileBannerImg': '',
        'name': '',
        'presellImg': '',
        'presellUrl': '',
        'productCategory': '3',
        'productNo': '01115',
        'productVideoThumbnail': '',
        'productVideoUrl': '',
        'profile': '覆盖195种疾病保障广；首次重疾赔付后，轻中症责任不终止，等待期内发生中轻症，仅该种中轻症责任终止，其他中轻症责任继续有效；15种少儿特定/罕见重疾加倍赔，多种可选责任按需强化保障。',
        'remark': '',
        'renewalRecoverRule': '',
        'sellType': '["3"]',
        'selloutNodes': '',
        'shareImg': '',
        'shortImg': 'https://file.fxyf99.com/product_material/20230928jbq3aY.jpg',
        'shortMobImg': 'https://file.fxyf99.com/product_material/20230928Efonbi.jpg',
        'showCommission': '4%-85%',
        'subsidyCommission': '',
        'updateStatus': '0',
        'url': '',
    },
    'productCategory': '3',
    'productId': '1707278418162225152',
    'productLabel': '线下经代$1250560069068468224,成人$1250560149498441728',
    'productLabelList': [
        {
            'isFirst': 1,
            'label': '线下经代',
        },
        {
            'isFirst': 0,
            'label': '成人',
        },
    ],
    'productName': '信泰完美人生2024重大疾病保险【支持双录-青岛河北湖北北京广东（含深圳）】',
    'productNo': '',
    'productType': '1',
    'recommendRate': '5',
    'recordStatus': '2',
    'sellType': '',
    'selloutNodes': '[]',
    'shortImg': '',
    'showProductInfomation': false,
    'status': '2',
    'subsidyCommissionNum': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1707278418162225152',
}, {
    'activityEndTime': null,
    'activityStartTime': null,
    'apiUrl': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1684750039028137984',
    'bannerImg': '',
    'commissionImg': '',
    'commissionNum': '13',
    'companyLogo': '',
    'companyName': '中意人寿',
    'dockingChannel': '1',
    'guarateeProfile': '',
    'id': '1685282725930910194',
    'insureType': '',
    'isAppointInsure': '0',
    'isFollow': '0',
    'isGift': '0',
    'isHot': '1',
    'isPresell': '0',
    'isShow': '0',
    'label': '核保宽松',
    'lowPrice': '10000.00',
    'maxCommission': '74.5',
    'mobileBannerImg': '',
    'planToOffTime': null,
    'platProductId': '1681583706998652930',
    'product': {
        'acceptInsuranceAge': '7天-70周岁',
        'bannerImg': '',
        'commission': '74%',
        'companyCode': '',
        'companyFullName': '',
        'companyLogo': 'https://www.fxyf99.com/upload/FILE/20181119/20181119104029446.jpg',
        'companyName': '',
        'estimatedOnboardTime': '',
        'firstSettlementRule': '',
        'goodnessSign': '',
        'guarateeLimit': '终身',
        'guarateeProfile': '有效保额逐年递增\n累积红利持续增加\n身故全场保障守护一生',
        'insureType': '["5"]',
        'isGift': '0',
        'isInsureCustom': false,
        'isNewFlow': '1',
        'isPresell': '0',
        'isSupportWebapp': false,
        'keyword': '',
        'label': '',
        'mobileBannerImg': '',
        'name': '',
        'presellImg': '',
        'presellUrl': '',
        'productCategory': '3',
        'productNo': '09014',
        'productVideoThumbnail': '',
        'productVideoUrl': '',
        'profile': '有效保额3.5%逐年递增，累计红利保险金额持续增加，相伴终身，保险利益写入合同，多重保障构筑风险屏障。',
        'remark': '',
        'renewalRecoverRule': '',
        'sellType': '["3"]',
        'selloutNodes': '',
        'shareImg': '',
        'shortImg': 'https://file.fxyf99.com/product_material/20230728wtxgrI.png',
        'shortMobImg': 'https://file.fxyf99.com/product_material/202307287kULbN.png',
        'showCommission': '4.5%-74%',
        'subsidyCommission': '',
        'updateStatus': '0',
        'url': '',
    },
    'productCategory': '3',
    'productId': '1684750039028137984',
    'productLabel': '线下经代$1250560069068468224,成人$1250560149498441728',
    'productLabelList': [
        {
            'isFirst': 1,
            'label': '线下经代',
        },
        {
            'isFirst': 0,
            'label': '成人',
        },
    ],
    'productName': '中意人寿一生中意终身寿险（分红型）',
    'productNo': '',
    'productType': '1',
    'recommendRate': '5',
    'recordStatus': '2',
    'sellType': '',
    'selloutNodes': '[]',
    'shortImg': '',
    'showProductInfomation': false,
    'sortNo': '3',
    'status': '2',
    'subsidyCommissionNum': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1684750039028137984',
}, {
    'activityEndTime': null,
    'activityStartTime': null,
    'apiUrl': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1699953230444138496',
    'bannerImg': '',
    'commissionImg': '',
    'commissionNum': '56',
    'companyLogo': '',
    'companyName': '弘康人寿',
    'dockingChannel': '1',
    'guarateeProfile': '',
    'id': '1701407563388359226',
    'insureType': '',
    'isAppointInsure': '0',
    'isFollow': '0',
    'isGift': '0',
    'isHot': '1',
    'isPresell': '0',
    'isShow': '0',
    'label': '智能核保',
    'lowPrice': '1000.00',
    'maxCommission': '71',
    'mobileBannerImg': '',
    'planToOffTime': null,
    'platProductId': '1689180430510204078',
    'product': {
        'acceptInsuranceAge': '30天-60周岁',
        'bannerImg': '',
        'commission': '71%',
        'companyCode': '',
        'companyFullName': '',
        'companyLogo': 'https://www.fxyf99.com/upload/FILE/20181119/20181119101552588.jpg',
        'companyName': '',
        'estimatedOnboardTime': '',
        'firstSettlementRule': '',
        'goodnessSign': '',
        'guarateeLimit': '终身',
        'guarateeProfile': '年度有效保额3.0%逐年增长；\n支持资金周转可应急；\n满足多种场景资金需求',
        'insureType': '["1"]',
        'isGift': '0',
        'isInsureCustom': false,
        'isNewFlow': '1',
        'isPresell': '0',
        'isSupportWebapp': false,
        'keyword': '',
        'label': '',
        'mobileBannerImg': '',
        'name': '',
        'presellImg': '',
        'presellUrl': '',
        'productCategory': '3',
        'productNo': '04078',
        'productVideoThumbnail': '',
        'productVideoUrl': '',
        'profile': '年度有效保额3.0%逐年增长；年末现金价值写进合同；支持资金周转可应急',
        'remark': '',
        'renewalRecoverRule': '',
        'sellType': '["3"]',
        'selloutNodes': '',
        'shareImg': '',
        'shortImg': 'https://file.fxyf99.com/product_material/20230908ona3WT.png',
        'shortMobImg': 'https://file.fxyf99.com/product_material/20230908OvOR18.png',
        'showCommission': '3%-71%',
        'subsidyCommission': '',
        'updateStatus': '0',
        'url': '',
    },
    'productCategory': '3',
    'productId': '1699953230444138496',
    'productLabel': '线下经代$1250560069068468224,成人$1250560149498441728',
    'productLabelList': [
        {
            'isFirst': 1,
            'label': '线下经代',
        },
        {
            'isFirst': 0,
            'label': '成人',
        },
    ],
    'productName': '弘康人寿金玉满堂3.0终身寿险',
    'productNo': '',
    'productType': '1',
    'recommendRate': '5',
    'recordStatus': '2',
    'sellType': '',
    'selloutNodes': '[]',
    'shortImg': '',
    'showProductInfomation': false,
    'status': '2',
    'subsidyCommissionNum': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1699953230444138496',
}, {
    'activityEndTime': null,
    'activityStartTime': null,
    'apiUrl': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1691391301118648320',
    'bannerImg': '',
    'commissionImg': '',
    'commissionNum': '56',
    'companyLogo': '',
    'companyName': '弘康人寿',
    'dockingChannel': '1',
    'guarateeProfile': '',
    'id': '1691987994645340616',
    'insureType': '',
    'isAppointInsure': '0',
    'isFollow': '0',
    'isGift': '0',
    'isHot': '1',
    'isPresell': '0',
    'isShow': '0',
    'label': '人工核保',
    'lowPrice': '1000.00',
    'maxCommission': '71',
    'mobileBannerImg': '',
    'planToOffTime': null,
    'platProductId': '1689180430510256129',
    'product': {
        'acceptInsuranceAge': '30天-60周岁',
        'bannerImg': '',
        'commission': '71%',
        'companyCode': '',
        'companyFullName': '',
        'companyLogo': 'https://www.fxyf99.com/upload/FILE/20181119/20181119101552588.jpg',
        'companyName': '',
        'estimatedOnboardTime': '',
        'firstSettlementRule': '',
        'goodnessSign': '',
        'guarateeLimit': '终身',
        'guarateeProfile': '年度有效保额3.0%逐年增长；\n支持资金周转可应急；\n满足多种场景资金需求',
        'insureType': '["1"]',
        'isGift': '0',
        'isInsureCustom': false,
        'isNewFlow': '1',
        'isPresell': '0',
        'isSupportWebapp': false,
        'keyword': '',
        'label': '',
        'mobileBannerImg': '',
        'name': '',
        'presellImg': '',
        'presellUrl': '',
        'productCategory': '3',
        'productNo': '04076',
        'productVideoThumbnail': '',
        'productVideoUrl': '',
        'profile': '年度有效保额3.0%逐年增长；年末现金价值写进合同；支持资金周转可应急。',
        'remark': '',
        'renewalRecoverRule': '',
        'sellType': '["3"]',
        'selloutNodes': '',
        'shareImg': '',
        'shortImg': 'https://file.fxyf99.com/product_material/20230815neImdx.png',
        'shortMobImg': 'https://file.fxyf99.com/product_material/2023081596qnLq.png',
        'showCommission': '3%-71%',
        'subsidyCommission': '',
        'updateStatus': '0',
        'url': '',
    },
    'productCategory': '3',
    'productId': '1691391301118648320',
    'productLabel': '线下经代$1250560069068468224,成人$1250560149498441728',
    'productLabelList': [
        {
            'isFirst': 1,
            'label': '线下经代',
        },
        {
            'isFirst': 0,
            'label': '成人',
        },
    ],
    'productName': '弘康金玉满堂3.0终身寿险【可对接人核】',
    'productNo': '',
    'productType': '1',
    'recommendRate': '5',
    'recordStatus': '2',
    'sellType': '',
    'selloutNodes': '[]',
    'shortImg': '',
    'showProductInfomation': false,
    'status': '2',
    'subsidyCommissionNum': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1691391301118648320',
}, {
    'activityEndTime': null,
    'activityStartTime': null,
    'apiUrl': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1686569768981094400',
    'bannerImg': '',
    'commissionImg': '',
    'commissionNum': '9',
    'companyLogo': '',
    'companyName': '大家养老',
    'dockingChannel': '1',
    'guarateeProfile': '',
    'id': '1706112424958738502',
    'insureType': '',
    'isAppointInsure': '0',
    'isFollow': '0',
    'isGift': '0',
    'isHot': '1',
    'isPresell': '0',
    'isShow': '0',
    'label': '智能核保,人工核保',
    'lowPrice': '5000.00',
    'maxCommission': '60',
    'mobileBannerImg': '',
    'planToOffTime': null,
    'platProductId': '1683384595497435138',
    'product': {
        'acceptInsuranceAge': '出生满7天至57周岁',
        'bannerImg': '',
        'commission': '60%',
        'companyCode': '',
        'companyFullName': '',
        'companyLogo': 'https://www.fxyf99.com/upload/FILE/20220510/da299fec411c47fea233d03d038e7769.png',
        'companyName': '',
        'estimatedOnboardTime': '',
        'firstSettlementRule': '',
        'goodnessSign': '',
        'guarateeLimit': '测试',
        'guarateeProfile': '养老年金终身领\n身价保障享终身',
        'insureType': '["1"]',
        'isGift': '0',
        'isInsureCustom': false,
        'isNewFlow': '1',
        'isPresell': '0',
        'isSupportWebapp': false,
        'keyword': '',
        'label': '',
        'mobileBannerImg': '',
        'name': '',
        'presellImg': '',
        'presellUrl': '',
        'productCategory': '3',
        'productNo': '94016',
        'productVideoThumbnail': '',
        'productVideoUrl': '',
        'profile': '养老年金领取额度确定，领取方式灵活，终身保障身故责任',
        'remark': '',
        'renewalRecoverRule': '',
        'sellType': '["3"]',
        'selloutNodes': '',
        'shareImg': '',
        'shortImg': 'https://file.fxyf99.com/product_material/20230802k6OhjZ.jpg',
        'shortMobImg': 'https://file.fxyf99.com/product_material/202308026h15Dy.jpg',
        'showCommission': '2%-60%',
        'subsidyCommission': '',
        'updateStatus': '0',
        'url': '',
    },
    'productCategory': '3',
    'productId': '1686569768981094400',
    'productLabel': '线下经代$1250560069068468224,成人$1250560149498441728',
    'productLabelList': [
        {
            'isFirst': 1,
            'label': '线下经代',
        },
        {
            'isFirst': 0,
            'label': '成人',
        },
    ],
    'productName': '大家养老大盈之家2.0养老年金保险',
    'productNo': '',
    'productType': '1',
    'recommendRate': '5',
    'recordStatus': '2',
    'sellType': '',
    'selloutNodes': '[]',
    'shortImg': '',
    'showProductInfomation': false,
    'status': '2',
    'subsidyCommissionNum': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1686569768981094400',
}, {
    'activityEndTime': null,
    'activityStartTime': null,
    'apiUrl': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1689884767533723648',
    'bannerImg': '',
    'commissionImg': '',
    'commissionNum': '8',
    'companyLogo': '',
    'companyName': '复星联合',
    'dockingChannel': '1',
    'guarateeProfile': '',
    'id': '1690919842955522228',
    'insureType': '',
    'isAppointInsure': '0',
    'isFollow': '0',
    'isGift': '0',
    'isHot': '1',
    'isPresell': '0',
    'isShow': '0',
    'label': '人工核保',
    'lowPrice': '10000.00',
    'maxCommission': '66',
    'mobileBannerImg': '',
    'planToOffTime': null,
    'platProductId': '1685818858543427585',
    'product': {
        'acceptInsuranceAge': '出生满28天至70周岁',
        'bannerImg': '',
        'commission': '66%',
        'companyCode': '',
        'companyFullName': '',
        'companyLogo': 'https://www.fxyf99.com/upload/FILE/20181119/20181119100956603.jpg',
        'companyName': '',
        'estimatedOnboardTime': '',
        'firstSettlementRule': '',
        'goodnessSign': '',
        'guarateeLimit': '终身',
        'guarateeProfile': '计划选择多样，灵活搭配\n保障额度递增，现价明确\n现价部分领取，合理规划',
        'insureType': '["1"]',
        'isGift': '0',
        'isInsureCustom': false,
        'isNewFlow': '1',
        'isPresell': '0',
        'isSupportWebapp': false,
        'keyword': '',
        'label': '',
        'mobileBannerImg': '',
        'name': '',
        'presellImg': '',
        'presellUrl': '',
        'productCategory': '3',
        'productNo': '02074',
        'productVideoThumbnail': '',
        'productVideoUrl': '',
        'profile': '有效保险金额3%逐年递增，保单利益清晰明确，多种缴费年期，满足多样化需求。',
        'remark': '',
        'renewalRecoverRule': '',
        'sellType': '["3"]',
        'selloutNodes': '',
        'shareImg': '',
        'shortImg': 'https://file.fxyf99.com/product_material/20230811TLF4le.jpg',
        'shortMobImg': 'https://file.fxyf99.com/product_material/20230811Jz7T01.jpg',
        'showCommission': '28%-66%',
        'subsidyCommission': '',
        'updateStatus': '0',
        'url': '',
    },
    'productCategory': '3',
    'productId': '1689884767533723648',
    'productLabel': '线下经代$1250560069068468224,成人$1250560149498441728',
    'productLabelList': [
        {
            'isFirst': 1,
            'label': '线下经代',
        },
        {
            'isFirst': 0,
            'label': '成人',
        },
    ],
    'productName': '复星联合康爱一生护理保险（荣耀版）',
    'productNo': '',
    'productType': '1',
    'recommendRate': '5',
    'recordStatus': '2',
    'sellType': '',
    'selloutNodes': '[]',
    'shortImg': '',
    'showProductInfomation': false,
    'status': '2',
    'subsidyCommissionNum': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1689884767533723648',
}, {
    'activityEndTime': null,
    'activityStartTime': null,
    'apiUrl': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1685463129088782336',
    'bannerImg': '',
    'commissionImg': '',
    'commissionNum': '8',
    'companyLogo': '',
    'companyName': '信美相互',
    'dockingChannel': '1',
    'guarateeProfile': '',
    'id': '1686564752601030737',
    'insureType': '',
    'isAppointInsure': '0',
    'isFollow': '0',
    'isGift': '0',
    'isHot': '1',
    'isPresell': '0',
    'isShow': '0',
    'label': '人工核保',
    'lowPrice': '10000.00',
    'maxCommission': '68',
    'mobileBannerImg': '',
    'planToOffTime': null,
    'platProductId': '1366218602617768008',
    'product': {
        'acceptInsuranceAge': '出生满7天且健康出院至75周岁',
        'bannerImg': '',
        'commission': '68%',
        'companyCode': '',
        'companyFullName': '',
        'companyLogo': 'https://www.fxyf99.com/upload/FILE/20210121/e620b761ca164d3e97d7a9af618e34ed.png',
        'companyName': '',
        'estimatedOnboardTime': '',
        'firstSettlementRule': '',
        'goodnessSign': '',
        'guarateeLimit': '终身',
        'guarateeProfile': '保额每年3%递增\n保障守护一生\n定向传承方案',
        'insureType': '["6"]',
        'isGift': '0',
        'isInsureCustom': false,
        'isNewFlow': '1',
        'isPresell': '0',
        'isSupportWebapp': false,
        'keyword': '',
        'label': '',
        'mobileBannerImg': '',
        'name': '',
        'presellImg': '',
        'presellUrl': '',
        'productCategory': '3',
        'productNo': '68008',
        'productVideoThumbnail': '',
        'productVideoUrl': '',
        'profile': '终身身故全残保障，保额每年按3.0%递增，可选航空动车意外责任，信美会员权益专享。',
        'remark': '',
        'renewalRecoverRule': '',
        'sellType': '["3"]',
        'selloutNodes': '',
        'shareImg': '',
        'shortImg': 'https://file.fxyf99.com/product_material/20230730CuwsKo.png',
        'shortMobImg': 'https://file.fxyf99.com/product_material/20230730xRamo9.png',
        'showCommission': '4%-68%',
        'subsidyCommission': '',
        'updateStatus': '0',
        'url': '',
    },
    'productCategory': '3',
    'productId': '1685463129088782336',
    'productLabel': '线下经代$1250560069068468224,成人$1250560149498441728',
    'productLabelList': [
        {
            'isFirst': 1,
            'label': '线下经代',
        },
        {
            'isFirst': 0,
            'label': '成人',
        },
    ],
    'productName': '信美相互传家有道尊享版2.0终身寿险',
    'productNo': '',
    'productType': '1',
    'recommendRate': '5',
    'recordStatus': '2',
    'sellType': '',
    'selloutNodes': '[]',
    'shortImg': '',
    'showProductInfomation': false,
    'status': '2',
    'subsidyCommissionNum': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1685463129088782336',
}, {
    'activityEndTime': null,
    'activityStartTime': null,
    'apiUrl': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1685954987228381184',
    'bannerImg': '',
    'commissionImg': '',
    'commissionNum': '15',
    'companyLogo': '',
    'companyName': '人保寿险',
    'dockingChannel': '1',
    'guarateeProfile': '',
    'id': '1686285524623888576',
    'insureType': '',
    'isAppointInsure': '0',
    'isFollow': '0',
    'isGift': '0',
    'isHot': '1',
    'isPresell': '0',
    'isShow': '0',
    'label': '',
    'lowPrice': '235.00',
    'maxCommission': '49',
    'mobileBannerImg': '',
    'planToOffTime': null,
    'platProductId': '1681920319733710849',
    'product': {
        'acceptInsuranceAge': '出生满28天-55周岁',
        'bannerImg': '',
        'commission': '49%',
        'companyCode': '',
        'companyFullName': '',
        'companyLogo': 'https://www.fxyf99.com/upload/FILE/20220116/6f9f85daaa234530af2108a321ebc06b.jpg',
        'companyName': '',
        'estimatedOnboardTime': '',
        'firstSettlementRule': '',
        'goodnessSign': '',
        'guarateeLimit': '保至70周岁、终身',
        'guarateeProfile': '覆盖180种疾病，保障广泛\n多种可选责任按需自由搭配\n身故责任灵活可选',
        'insureType': '["1"]',
        'isGift': '0',
        'isInsureCustom': false,
        'isNewFlow': '1',
        'isPresell': '0',
        'isSupportWebapp': false,
        'keyword': '',
        'label': '',
        'mobileBannerImg': '',
        'name': '',
        'presellImg': '',
        'presellUrl': '',
        'productCategory': '3',
        'productNo': '95006',
        'productVideoThumbnail': '',
        'productVideoUrl': '',
        'profile': '覆盖180种疾病，保障广泛，多种责任自由选，产品形态灵活，按需选择，身故责任灵活可选。',
        'remark': '',
        'renewalRecoverRule': '',
        'sellType': '["1"]',
        'selloutNodes': '',
        'shareImg': '',
        'shortImg': 'https://file.fxyf99.com/product_material/20230731k3j13i.png',
        'shortMobImg': 'https://file.fxyf99.com/product_material/20230731JNvd5o.png',
        'showCommission': '23.5%-49%',
        'subsidyCommission': '',
        'updateStatus': '0',
        'url': '',
    },
    'productCategory': '3',
    'productId': '1685954987228381184',
    'productLabel': '网销产品$1250560069068468224,成人$1250560149498441728',
    'productLabelList': [
        {
            'isFirst': 1,
            'label': '网销产品',
        },
        {
            'isFirst': 0,
            'label': '成人',
        },
    ],
    'productName': '人保寿险i无忧2.0重大疾病保险（互联网专属）',
    'productNo': '',
    'productType': '1',
    'recommendRate': '5',
    'recordStatus': '2',
    'sellType': '',
    'selloutNodes': '[]',
    'shortImg': '',
    'showProductInfomation': false,
    'status': '2',
    'subsidyCommissionNum': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1685954987228381184',
}];

const useStyles = createStyles(({ token, css }) => {
    return {
        filters: css`
          background-color: ${token.colorBgContainerDisabled};
          padding: 1rem 2rem;
          margin-bottom: 1rem;
        `,
        options: css`
          display: grid;
          grid-template-columns: 5rem auto;
          grid-row-gap: 1rem;
        `,
        option: css`
          width: 10rem;
        `,
        tools: css`
          text-align: center;
        `,
    };
});

const Action = ({ Icon, text }) => (
    <Space>
        <Icon />
        {text}
    </Space>
);

const Filters = () => {
    const [expanded, setExpanded] = useState(false);
    const options = [{
        name: '分类',
        values: ['网销产品', '线下经代', '电销产品'],
    }, {
        name: '人群',
        values: ['成人', '儿童', '老年', '非标'],
    }, {
        name: '核保方式',
        values: ['智能核保', '邮件核保', '人工核保'],
    }, {
        name: '保险公司',
        values: ['君龙人寿', '招商仁和人寿', '人保寿险', '恒大人寿', '华夏人寿', '小康人寿', '中华保险', '信美相互', '国富人寿', '光大永明', '瑞华保险', '中荷人寿', '海保人寿', '昆仑健康', '复星保德信', '信泰人寿', '同方全球人寿', '华贵保险', '和谐健康', '富德生命', '复星联合', '德华安顾', '百年人寿', '横琴人寿', '阳光人寿', '长生人寿', '泰康在线', '长城人寿'],
    }];
    const { styles } = useStyles();
    return (
        <section className={styles.filters}>
            <dl className={styles.options}>
                {options.filter((_, index) => expanded || index < 2).map(({ name, values }) => (
                    <React.Fragment key={name}>
                        <dt>{name}：</dt>
                        <dd>{values.map(value => (
                            <Checkbox key={value} className={styles.option}>{value}</Checkbox>
                        ))}</dd>
                    </React.Fragment>
                ))}
            </dl>
            <div className={styles.tools}>
                {expanded ? (
                    <Button type="link" icon={<UpOutlined />} onClick={() => setExpanded(false)}>收起</Button>
                ) : (
                    <Button type="link" icon={<DownOutlined />} onClick={() => setExpanded(true)}>展开</Button>
                )}
            </div>
        </section>
    );
};

const Commodity = ({ commodity }) => {
    return (
        <List.Item
            actions={[
                <Action Icon={StarOutlined} text="收藏" />,
                <Action Icon={MoneyCollectOutlined} text={`${commodity.lowPrice}元起`} />,
                <Action Icon={DollarOutlined} text={`服务费${commodity.product.showCommission}`} />,
                <Action Icon={ClockCircleOutlined} text={`${commodity.product.acceptInsuranceAge}，${commodity.product.guarateeLimit}`} />,
            ]}
            extra={
                <Space style={{ height: '100%' }} direction="horizontal">
                    <Image width={300} src={commodity.product.shortImg} />
                    <Space direction="vertical">
                        <Button icon={<LinkOutlined />} type="primary">
                            <a href={commodity.url} target="_blank">投保链接</a>
                        </Button>
                        <Button icon={<FolderOutlined />} type="default">推广资料</Button>,
                    </Space>
                </Space>
            }
        >
            <List.Item.Meta
                avatar={<Avatar src={commodity.product.companyLogo} />}
                title={commodity.productName}
                description={
                    <Space>
                        {commodity.label !== '' ? <Tag color="warning">{commodity.label}</Tag> : null}
                        {commodity.productLabelList.map(({ label, isFirst }) => (
                            <Tag>{label}</Tag>
                        ))}
                    </Space>
                }
            />
            <Typography.Paragraph>{commodity.product.profile}</Typography.Paragraph>
            <Typography.Paragraph>
                <ul>
                    {commodity.product.guarateeProfile.split('\n').map(it => <li>{it}</li>)}
                </ul>
            </Typography.Paragraph>
        </List.Item>
    );
};

const Commodities = () => {
    return (
        <>
            <Filters />
            <Card tabList={categories}>
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={data}
                    renderItem={(commodity) => <Commodity commodity={commodity} />}
                />
            </Card>
        </>
    );
};

export default () => {
    return (
        <Tabs defaultActiveKey="on-sale" items={[{
            key: 'on-sale',
            label: '在售商品',
            children: <Commodities />,
        }, {
            key: 'removed',
            label: '已下架商品',
            children: <Commodities />,
        }]} />
    );
};