/**
 * Shell Container需要的数据
 */
import { gql } from '@apollo/client';
import { storageEffect } from 'helpers/effects';
import { useQueryState } from 'helpers/graphql';
import { useCallback, useMemo } from 'react';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { ACCOUNT_ID_KEY } from 'snapshots/constants';
import { useToHomePage } from 'snapshots/routers';

export const QUERY_SHELL_USER = gql`
    query ShellUserInformation {
        phone
        accounts {
            id
            name
            avatar
            institutionName
            permissions
            departments {
                name
            }
            positions {
                name
            }
        }
    }
`;

export const useShellUserState = () => useQueryState(QUERY_SHELL_USER, {
    defaultValue: {
        phone: '',
        accounts: [],
    },
});

// Authentication

export const useAuthenticationState = () => {
    const { ready, success, error } = useShellUserState();
    return useMemo(() => ({
        ready,
        authenticated: ready && success && error?.extensions?.classification !== 'UNAUTHORIZED',
    }), [ready, success, error]);
};

// User Information

export const useShellPhoneValue = () => {
    const { value: { phone } } = useShellUserState();
    return phone;
};

export const useShellAccountsValue = () => {
    const { value: { accounts } } = useShellUserState();
    return accounts;
};

// Current Account

const accountId = atom({
    key: 'shell.account.id',
    default: '',
    effects: [storageEffect(ACCOUNT_ID_KEY)],
});

export const useShellAccountId = () => useRecoilState(accountId);

export const useShellAccountIdValue = () => useRecoilValue(accountId);

export const useSetShellAccountId = () => useSetRecoilState(accountId);

export const useShellAccountValue = () => {
    const accounts = useShellAccountsValue();
    const [accountId, setAccountId] = useShellAccountId();
    return useMemo(() => {
        if (accounts.length > 0) {
            const account = accountId !== '' ? accounts.find(({ id }) => id === accountId) : undefined;
            if (account !== undefined) {
                return account;
            } else {
                setAccountId(accounts[0].id);
                return accounts[0];
            }
        } else {
            return {}; // default value
        }
    }, [accounts, accountId, setAccountId]);
};

export const useSwitchShellAccount = () => {
    const switchAccountTo = useSetShellAccountId();
    const toHomePage = useToHomePage();
    return useCallback((id) => {
        switchAccountTo(id);
        toHomePage();
    }, [switchAccountTo, toHomePage]);
};

export const useShellHasPermission = () => {
    const { permissions = [] } = useShellAccountValue();
    const base = useMemo(() => new Set(permissions), [permissions]);
    return useCallback((permission) => base.has(permission), [base]);
};
