import { createStyles } from 'antd-style';
import Flex from 'views/layouts/Flex';

const useStyles = createStyles(({ token, css }, { bordered = false } = {}) => {
    const item = css`
      width: 100%;
      height: auto;
      line-height: normal;
    `;
    const side = css`
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: auto;
    `;
    return {
        rows: css`
          width: 100%;
          height: 100%;
          min-height: 0;
        `,
        header: css`
          ${item};
          ${side};
          border-bottom: ${bordered ? 1 : 0}px solid ${token.colorBorder};
        `,
        content: css`
          ${item};
          flex-grow: 1;
          flex-shrink: 1;
          overflow-y: auto;

          &:hover {
            overflow-y: auto;
          }
        ;
        `,
        footer: css`
          ${item};
          ${side};
          border-top: ${bordered ? 1 : 0}px solid ${token.colorBorder};
        `,
    };
});

export const Header = ({ className, bordered = false, children, ...props }) => {
    const { styles, cx } = useStyles({ bordered });
    return <Flex className={cx(styles.header, className)} direction="horizontal" {...props}>{children}</Flex>;
};

export const Content = ({ className, children, ...props }) => {
    const { styles, cx } = useStyles();
    return <Flex className={cx(styles.content, className)} direction="horizontal" {...props}>{children}</Flex>;
};

export const Footer = ({ className, bordered = false, children, ...props }) => {
    const { styles, cx } = useStyles({ bordered });
    return <Flex className={cx(styles.footer, className)} direction="horizontal" {...props}>{children}</Flex>;
};

/**
 * 行布局：可以指定任意多个吸顶或吸底的行，正文部分填充满剩余高度。
 */
export default ({ className, children, ...props }) => {
    const { styles, cx } = useStyles();
    return <Flex className={cx(styles.rows, className)} direction="vertical" {...props}>{children}</Flex>
};
