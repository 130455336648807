import { useMemo } from 'react';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

const products = [{
    'channel': 'FengQi',
    'id': '1723962201201778688',
    'name': '穗岁康广州市民专享百万医疗保险',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20231113i5vSFF.png',
    'features': ['政府做指导，广州市定制', '一张医保卡，全家可交费', '待遇增值多，保障更实在'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1723962201201778688',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '4.5' }] }] }],
    'companyName': '太保健康',
}, {
    'channel': 'FengQi',
    'id': '1724674416692887552',
    'name': '复星联合妈咪保贝（星礼版）-母婴计划',
    'scene': '经代',
    'categories': ['重疾'],
    'banner': 'https://file.fxyf99.com/product_material/20231115lIAHbX.jpg',
    'features': ['保障更全面', '确诊定额赔'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1724674416692887552',
    'limit': '30年、70年、至终身',
    'commissions': [{
        'grade': '保终身（广东、四川）',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '95' }, { 'term': '2', 'rate': '5' }],
        }, { 'name': '20年', 'rates': [{ 'term': '1', 'rate': '95' }, { 'term': '2', 'rate': '5' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '56.5' }, { 'term': '2', 'rate': '6' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '45' }, { 'term': '2', 'rate': '5' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '25.5' }, { 'term': '2', 'rate': '5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '5' }] }],
    }, {
        'grade': '保终身（北京）',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '80' }, { 'term': '2', 'rate': '19' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '80' }, { 'term': '2', 'rate': '19' }],
        }, { 'name': '15年', 'rates': [{ 'term': '1', 'rate': '58' }, { 'term': '2', 'rate': '6' }] }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '45' }, { 'term': '2', 'rate': '5' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '25.5' }, { 'term': '2', 'rate': '5' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '5' }],
        }],
    }, {
        'grade': '保70年（广东、四川）',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '82' }, { 'term': '2', 'rate': '5' }],
        }, { 'name': '20年', 'rates': [{ 'term': '1', 'rate': '82' }, { 'term': '2', 'rate': '5' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '55' }, { 'term': '2', 'rate': '6' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '43' }, { 'term': '2', 'rate': '5' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '24.5' }, { 'term': '2', 'rate': '5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '4.5' }] }],
    }, {
        'grade': '保70年（北京）',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '80' }, { 'term': '2', 'rate': '6.5' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '80' }, { 'term': '2', 'rate': '6.5' }],
        }, { 'name': '15年', 'rates': [{ 'term': '1', 'rate': '55' }, { 'term': '2', 'rate': '6' }] }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '44' }, { 'term': '2', 'rate': '5' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '24.5' }, { 'term': '2', 'rate': '5' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '4.5' }],
        }],
    }, {
        'grade': '保30年（广东、四川）',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '51' }, { 'term': '2', 'rate': '2.5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '48' }, { 'term': '2', 'rate': '2.5' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '45' }, { 'term': '2', 'rate': '2.5' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '25.5' }, { 'term': '2', 'rate': '2.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '3.5' }] }],
    }, {
        'grade': '保30年（北京）',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '51' }, { 'term': '2', 'rate': '2.5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '48' }, { 'term': '2', 'rate': '2.5' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '45' }, { 'term': '2', 'rate': '2.5' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '25.5' }, { 'term': '2', 'rate': '2.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '3.5' }] }],
    }],
    'companyName': '复星联合',
}, {
    'channel': 'FengQi',
    'id': '1719591545690304512',
    'name': '昆仑健康岁享金生终身护理保险',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/202311156RRZxP.jpg',
    'features': ['有效保额 复利递增', '利益明确 延续无忧', '保单贷款 服务便捷'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1719591545690304512',
    'limit': '终身',
    'commissions': [{
        'grade': '保终身',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '63.5' }, { 'term': '2', 'rate': '4' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '31' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '22' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '4' }] }],
    }],
    'companyName': '昆仑健康',
}, {
    'channel': 'FengQi',
    'id': '1722811121433616384',
    'name': '弘康金禧一生年金保险（2023版）',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20231110SdONLH.jpg',
    'features': ['年金终身领取', '领取金额写进合同', '领取后仍有现价'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1722811121433616384',
    'limit': '终身',
    'commissions': [{
        'grade': '被保人：56-60岁',
        'terms': [{ 'name': '5年', 'rates': [{ 'term': '1', 'rate': '21' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '4' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '1' }] }],
    }, {
        'grade': '被保人：51-55岁',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '46' }, { 'term': '2', 'rate': '10' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '29' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '6' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '3' }] }],
    }, {
        'grade': '被保人：46-50岁',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '53' }, { 'term': '2', 'rate': '10' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '37' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '9' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '5' }] }],
    }, {
        'grade': '被保人：41-45岁',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '62' }, { 'term': '2', 'rate': '10' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '43' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '12' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '7' }] }],
    }, {
        'grade': '被保人：36-40岁',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '63' }, { 'term': '2', 'rate': '10' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '45' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '14' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '7' }] }],
    }, {
        'grade': '被保人：31-35岁',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '64' }, { 'term': '2', 'rate': '10' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '50' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '16' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '8' }] }],
    }, {
        'grade': '被保人：26-30岁',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '65' }, { 'term': '2', 'rate': '10' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '52' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '18' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '9' }] }],
    }, {
        'grade': '被保人：19-25岁',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '65' }, { 'term': '2', 'rate': '10' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '53' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '19' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '9' }] }],
    }, {
        'grade': '被保人：0-18岁',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '66' }, { 'term': '2', 'rate': '10' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '55' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '20' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '9' }] }],
    }],
    'companyName': '弘康人寿',
}, {
    'channel': 'FengQi',
    'id': '1714465579774574592',
    'name': '昆仑健康乐享年年终身护理保险',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20231018IBVJ42.jpg',
    'features': ['有效保额 复利递增', '利益明确 延续无忧', '保单贷款 服务便捷'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1714465579774574592',
    'limit': '终身',
    'commissions': [{
        'grade': '【北京】',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '72' }, { 'term': '2', 'rate': '8' }],
        }, { 'name': '15年', 'rates': [{ 'term': '1', 'rate': '68' }, { 'term': '2', 'rate': '6' }] }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '68' }, { 'term': '2', 'rate': '6' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '44' }, { 'term': '2', 'rate': '3' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '26' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '4' }] }],
    }],
    'companyName': '昆仑健康',
}, {
    'channel': 'FengQi',
    'id': '1684132435195830272',
    'name': '君龙人寿小青龙2号少儿重大疾病保险计划（互联网）',
    'scene': '网销',
    'categories': ['重疾'],
    'banner': 'https://file.fxyf99.com/product_material/20230728qbH4xf.png',
    'features': ['涵盖209种疾病基础保障广泛 ', '少儿特定/罕见疾病额外赔', '更有多项健康服务双倍呵护'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1684132435195830272',
    'limit': '保终身/保至70周岁/保30年',
    'commissions': [{
        'grade': '保终身',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '54' }, { 'term': '2', 'rate': '30' }, { 'term': '3', 'rate': '12' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '54' }, { 'term': '2', 'rate': '27' }, { 'term': '3', 'rate': '12' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '49' }, { 'term': '2', 'rate': '17' }, { 'term': '3', 'rate': '7' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '49' }, { 'term': '2', 'rate': '17' }, { 'term': '3', 'rate': '7' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '29' }, { 'term': '2', 'rate': '3.5' }, { 'term': '3', 'rate': '2.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '14' }] }],
    }, {
        'grade': '保至70岁',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '54' }, { 'term': '2', 'rate': '19' }, { 'term': '3', 'rate': '2' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '54' }, { 'term': '2', 'rate': '19' }, { 'term': '3', 'rate': '2' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '49' }, { 'term': '2', 'rate': '17' }, { 'term': '3', 'rate': '2' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '39' }, { 'term': '2', 'rate': '7' }, { 'term': '3', 'rate': '2' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '24' }, { 'term': '2', 'rate': '2' }, { 'term': '3', 'rate': '2' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '14' }] }],
    }, {
        'grade': '保30年',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '52' }, { 'term': '2', 'rate': '19' }, { 'term': '3', 'rate': '2' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '44' }, { 'term': '2', 'rate': '12' }, { 'term': '3', 'rate': '2' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '39' }, { 'term': '2', 'rate': '7' }, { 'term': '3', 'rate': '2' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '24' }, { 'term': '2', 'rate': '2' }, { 'term': '3', 'rate': '2' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '10' }] }],
    }],
    'companyName': '君龙人寿',
}, {
    'channel': 'FengQi',
    'id': '1722793521777516544',
    'name': '德华安顾孝亲宝中老年防癌疾病保险（2023 版）（互联网专属）',
    'scene': '网销',
    'categories': ['重疾'],
    'banner': 'https://file.fxyf99.com/product_material/20231110k9A1iM.png',
    'features': ['三高、糖尿病人群均可投保', '最高保额提升至30万', '特定重度恶性肿瘤额外赔'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1722793521777516544',
    'limit': '10年/15年/20年',
    'commissions': [{
        'grade': '',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '51' }, { 'term': '2', 'rate': '25' }, { 'term': '3', 'rate': '22.5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '51' }, { 'term': '2', 'rate': '25' }, { 'term': '3', 'rate': '12.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '51' }, { 'term': '2', 'rate': '25' }] }],
    }],
    'companyName': '德华安顾',
}, {
    'channel': 'FengQi',
    'id': '1722185730861694976',
    'name': '招商仁和仁孝保老年综合医疗保险（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20231108nedrP5.jpg',
    'features': ['200万恶性肿瘤医疗保障', '80岁都能投，保证续保6年', '体检通过可升级一般医疗责任'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1722185730861694976',
    'limit': '1年，6年保证续保',
    'commissions': [{
        'grade': '',
        'terms': [{
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '26' }, { 'term': '2', 'rate': '3' }, { 'term': '3', 'rate': '' }],
        }],
    }],
    'companyName': '招商仁和人寿',
}, {
    'channel': 'FengQi',
    'id': '1717754248884584448',
    'name': '京东安联成长优享儿童高端医疗保险（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/202311074G07DK.png',
    'features': ['多品牌医疗机构覆盖；可选特定疾病海外就医'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1717754248884584448',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '13' }] }] }],
    'companyName': '京东安联',
}, {
    'channel': 'FengQi',
    'id': '1722181927915618304',
    'name': '弘康利多多2号终身寿险产品计划【可外籍投保】',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20231108Y54kPR.jpg',
    'features': ['年度有效保额3.0%逐年增长', '支持保单贷款', '满足多种场景资金需求'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1722181927915618304',
    'limit': '终身',
    'commissions': [{
        'grade': '被保人：61-65岁',
        'terms': [{ 'name': '10年', 'rates': [{ 'term': '1', 'rate': '40' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '30' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '6' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '5' }],
        }],
    }, {
        'grade': '被保人：56-60岁',
        'terms': [{ 'name': '15年', 'rates': [{ 'term': '1', 'rate': '49' }] }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '49' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '36' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '21' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '6' }] }],
    }, {
        'grade': '被保人：51-55岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '64' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '64' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '64' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '39' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '22' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '7' }],
        }],
    }, {
        'grade': '被保人：46-50岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '69' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '69' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '69' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '42' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '24' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '8' }],
        }],
    }, {
        'grade': '被保人：41-45岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '79' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '79' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '79' }, { 'term': '2', 'rate': '5.5' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '44' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '26' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '9' }] }],
    }, {
        'grade': '被保人：36-40岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '87' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '87' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '84' }, { 'term': '2', 'rate': '10' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '47' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '26' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '10' }],
        }],
    }, {
        'grade': '被保人：31-35岁',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '88' }, { 'term': '2', 'rate': '3.5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '85' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '84' }, { 'term': '2', 'rate': '10' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '49' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '27' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '11' }],
        }],
    }, {
        'grade': '被保人：26-30岁',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '88' }, { 'term': '2', 'rate': '3.5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '85' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '84' }, { 'term': '2', 'rate': '10' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '52' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '28' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '11' }],
        }],
    }, {
        'grade': '被保人：19-25岁',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '88' }, { 'term': '2', 'rate': '3.5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '85' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '84' }, { 'term': '2', 'rate': '10' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '54' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '29' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '11' }],
        }],
    }, {
        'grade': '被保人：0-18岁',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '88' }, { 'term': '2', 'rate': '3.5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '85' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '84' }, { 'term': '2', 'rate': '10' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '57' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '32' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '12' }],
        }],
    }],
    'companyName': '弘康人寿',
}, {
    'channel': 'FengQi',
    'id': '1722064510770085888',
    'name': '复星保德信星海赢家（龙腾版）养老年金保险',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20231108Gfsuxh.png',
    'features': ['产品无需健告告知', '有保证领取期间', '可对接信托和养老社区'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1722064510770085888',
    'limit': '终身',
    'commissions': [{
        'grade': '【计划二】被保人：31-50岁',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '63' }, { 'term': '2', 'rate': '29.5' }, { 'term': '3', 'rate': '6' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '60' }, { 'term': '2', 'rate': '20.5' }, { 'term': '3', 'rate': '2.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '60' }, { 'term': '2', 'rate': '18.5' }] }],
    }, {
        'grade': '【计划一】被保人：31-50岁',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '63' }, { 'term': '2', 'rate': '23.5' }, { 'term': '3', 'rate': '6' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '60' }, { 'term': '2', 'rate': '24' }, { 'term': '3', 'rate': '2.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '60' }, { 'term': '2', 'rate': '18.5' }] }],
    }, {
        'grade': '【计划二】被保人：0-30岁',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '63' }, { 'term': '2', 'rate': '22.5' }, { 'term': '3', 'rate': '6' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '60' }, { 'term': '2', 'rate': '23.5' }, { 'term': '3', 'rate': '2.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '60' }, { 'term': '2', 'rate': '21.5' }] }],
    }, {
        'grade': '【计划一】被保人：21-30岁',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '63' }, { 'term': '2', 'rate': '23.5' }, { 'term': '3', 'rate': '6' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '60' }, { 'term': '2', 'rate': '23.5' }, { 'term': '3', 'rate': '2.5' }],
        }],
    }, {
        'grade': '【计划一】被保人：0-30岁',
        'terms': [{ 'name': '10年', 'rates': [{ 'term': '1', 'rate': '60' }, { 'term': '2', 'rate': '21.5' }] }],
    }, {
        'grade': '【计划一】被保人：0-20岁',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '63' }, { 'term': '2', 'rate': '27.5' }, { 'term': '3', 'rate': '6' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '60' }, { 'term': '2', 'rate': '28.5' }, { 'term': '3', 'rate': '2.5' }],
        }],
    }, {
        'grade': '【计划二】',
        'terms': [{ 'name': '5年', 'rates': [{ 'term': '1', 'rate': '41.5' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '19.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '3' }] }],
    }, {
        'grade': '【计划一】',
        'terms': [{ 'name': '5年', 'rates': [{ 'term': '1', 'rate': '44.5' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '23.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '5' }] }],
    }],
    'companyName': '复星保德信',
}, {
    'channel': 'FengQi',
    'id': '1706201336725094400',
    'name': '太平洋·交通意外保险（互联网）',
    'scene': '网销',
    'categories': ['意外'],
    'banner': 'https://file.fxyf99.com/product_material/202309259ak5YB.jpg',
    'features': ['保障多种交通工具出行', '价格实惠，保障丰富', '按天起保，灵活规划'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1706201336725094400',
    'limit': '出生满30天（含）-70周岁（含）',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '太平洋财产保险',
}, {
    'channel': 'FengQi',
    'id': '1720248854603444224',
    'name': '弘康弘运连连终身寿险（2023）',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20231103u2bLFN.png',
    'features': ['年度有效保额逐年递增', '锁定现价，安全确定', '航空意外，安心守护'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1720248854603444224',
    'limit': '终身',
    'commissions': [{
        'grade': '被保人：61-65岁',
        'terms': [{ 'name': '10年', 'rates': [{ 'term': '1', 'rate': '27' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '21' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '4' }] }],
    }, {
        'grade': '被保人：56-60岁',
        'terms': [{ 'name': '15年', 'rates': [{ 'term': '1', 'rate': '31' }] }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '31' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '25.5' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '5.5' }],
        }],
    }, {
        'grade': '被保人：51-55岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '35.5' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '35.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '35.5' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '28.5' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '7' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '2' }],
        }],
    }, {
        'grade': '被保人：46-50岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '41.5' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '41.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '41.5' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '33' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '9.5' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '3.5' }],
        }],
    }, {
        'grade': '被保人：41-45岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '47.5' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '47.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '61' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '37' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '12' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '3.5' }],
        }],
    }, {
        'grade': '被保人：36-40岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '52.5' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '52.5' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '61' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '40.5' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '13.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '3.5' }] }],
    }, {
        'grade': '被保人：31-35岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '56.5' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '56.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '61' }, { 'term': '2', 'rate': '6' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '43' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '15' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '3.5' }],
        }],
    }, {
        'grade': '被保人：26-30岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '60' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '60' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '61' }, { 'term': '2', 'rate': '6' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '45.5' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '16.5' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '4' }],
        }],
    }, {
        'grade': '被保人：19-25岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '62.5' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '62.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '61' }, { 'term': '2', 'rate': '6' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '46.5' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '17.5' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '4' }],
        }],
    }, {
        'grade': '被保人：0-18岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '64.5' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '64.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '61' }, { 'term': '2', 'rate': '6' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '48' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '18' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '6' }],
        }],
    }],
    'companyName': '弘康人寿',
}, {
    'channel': 'FengQi',
    'id': '1719996677795053568',
    'name': '北京普惠健康保（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20231102fncb8A.png',
    'features': ['医保个账可缴费 带病可保可赔付'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1719996677795053568',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '4' }] }] }],
    'companyName': '北京人寿',
}, {
    'channel': 'FengQi',
    'id': '1719159796854337536',
    'name': '同方全球新传世荣耀2023终身寿险',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/2023103162xN3Q.jpg',
    'features': ['投保年龄高达70岁', '意外身故额外赔付', '可选责任按需选择'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1719159796854337536',
    'limit': '终身',
    'commissions': [{
        'grade': '保终身【湖北】',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '74.5' }, { 'term': '2', 'rate': '19.5' }, { 'term': '3', 'rate': '3' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '74.5' }, { 'term': '2', 'rate': '19.5' }, { 'term': '3', 'rate': '3' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '74.5' }, { 'term': '2', 'rate': '14' }, { 'term': '3', 'rate': '3' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '74.5' }, { 'term': '2', 'rate': '6' }, { 'term': '3', 'rate': '3' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '44.5' }, { 'term': '2', 'rate': '3' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '27.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '5' }] }],
    }],
    'companyName': '同方全球人寿',
}, {
    'channel': 'FengQi',
    'id': '1719907322875314176',
    'name': '信泰如意尊（创赢版）终身寿险【支持双录-青岛河北江西江苏湖北北京广东（含深圳）】',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20231102KzUWaq.jpg',
    'features': ['投保年龄最高80周岁', '有效保额以3.0%逐年递增', '航空/重大自然灾害意外额外赔'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1719907322875314176',
    'limit': '终身',
    'commissions': [{
        'grade': '保终身',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '90' }, { 'term': '2', 'rate': '5.5' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '52' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '30' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '7' }] }],
    }],
    'companyName': '信泰人寿',
}, {
    'channel': 'FengQi',
    'id': '1719521919710973952',
    'name': '同方全球新康健（多倍版）保险产品计划',
    'scene': '经代',
    'categories': ['重疾'],
    'banner': 'https://file.fxyf99.com/product_material/202311019WpDGX.png',
    'features': ['多种责任保障广 健康守护保终身', '轻中重疾不分组 多次赔付保障高', '良性肿瘤也能赔 高发疾病加倍保', '保费豁免人性化 贴心关怀解您忧'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1719521919710973952',
    'limit': '终身',
    'commissions': [{
        'grade': '保终身【湖北】',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '75' }, { 'term': '2', 'rate': '19.5' }, { 'term': '3', 'rate': '3' }],
        }, {
            'name': '25年',
            'rates': [{ 'term': '1', 'rate': '75' }, { 'term': '2', 'rate': '19.5' }, { 'term': '3', 'rate': '3' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '75' }, { 'term': '2', 'rate': '19.5' }, { 'term': '3', 'rate': '3' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '75' }, { 'term': '2', 'rate': '14' }, { 'term': '3', 'rate': '3' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '75' }, { 'term': '2', 'rate': '6' }, { 'term': '3', 'rate': '3' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '44.5' }, { 'term': '2', 'rate': '3' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '5' }],
        }],
    }],
    'companyName': '同方全球人寿',
}, {
    'channel': 'FengQi',
    'id': '1719165883716382720',
    'name': '同方全球新康健（易心安）重大疾病保险',
    'scene': '经代',
    'categories': ['重疾'],
    'banner': 'https://file.fxyf99.com/product_material/20231031jZ1grA.png',
    'features': ['健康告知仅3条', '保障身故全残', '最高65岁可投保'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1719165883716382720',
    'limit': '保至70周岁/终身',
    'commissions': [{
        'grade': '湖北',
        'terms': [{
            'name': '19年',
            'rates': [{ 'term': '1', 'rate': '74.5' }, { 'term': '2', 'rate': '11' }, { 'term': '3', 'rate': '3' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '67' }, { 'term': '2', 'rate': '3' }] }],
    }],
    'companyName': '同方全球人寿',
}, {
    'channel': 'FengQi',
    'id': '1716998720750149632',
    'name': '珠江人寿永鑫年年年金保险',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/202310254od4qO.png',
    'features': ['5年后领取保险金', '资金周转灵活', '可指定受益人'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1716998720750149632',
    'limit': '10年',
    'commissions': [{
        'grade': '保10年【北京、广东（含深圳）】',
        'terms': [{
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '38' }, { 'term': '2', 'rate': '4.5' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '31' }, { 'term': '2', 'rate': '3' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '4.5' }],
        }],
    }],
    'companyName': '珠江人寿',
}, {
    'channel': 'FengQi',
    'id': '1716998973519880192',
    'name': '珠江人寿增寿年年终身寿险',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20231026GHIjGC.jpg',
    'features': ['投保年龄最高70周岁', '有效保额以3.0%逐年递增', '支持保单贷款和减保'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1716998973519880192',
    'limit': '终身',
    'commissions': [{
        'grade': '保终身【北京、广东（含深圳）】',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '68' }, { 'term': '2', 'rate': '15' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '50' }, { 'term': '2', 'rate': '4.5' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '31' }, { 'term': '2', 'rate': '3' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '4.5' }] }],
    }],
    'companyName': '珠江人寿',
}, {
    'channel': 'FengQi',
    'id': '1717358142826844160',
    'name': '复星联合妈咪保贝（星礼版）-少儿计划',
    'scene': '经代',
    'categories': ['重疾'],
    'banner': 'https://file.fxyf99.com/product_material/20231026yUbioL.jpg',
    'features': ['保障更全面', '确诊定额赔'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1717358142826844160',
    'limit': '30年、至70岁、至终身',
    'commissions': [{
        'grade': '保终身（广东、四川）',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '95' }, { 'term': '2', 'rate': '5' }],
        }, { 'name': '20年', 'rates': [{ 'term': '1', 'rate': '95' }, { 'term': '2', 'rate': '5' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '54' }, { 'term': '2', 'rate': '6' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '42.5' }, { 'term': '2', 'rate': '5' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '24.5' }, { 'term': '2', 'rate': '5' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '4.5' }],
        }],
    }, {
        'grade': '保终身（北京）',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '73' }, { 'term': '2', 'rate': '19' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '73' }, { 'term': '2', 'rate': '19' }],
        }, { 'name': '15年', 'rates': [{ 'term': '1', 'rate': '54' }, { 'term': '2', 'rate': '6' }] }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '42.5' }, { 'term': '2', 'rate': '5' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '24.5' }, { 'term': '2', 'rate': '5' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '4.5' }],
        }],
    }, {
        'grade': '保至70周岁（广东、四川）',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '72' }, { 'term': '2', 'rate': '5' }],
        }, { 'name': '20年', 'rates': [{ 'term': '1', 'rate': '72' }, { 'term': '2', 'rate': '5' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '51' }, { 'term': '2', 'rate': '6' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '38' }, { 'term': '2', 'rate': '5' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '22' }, { 'term': '2', 'rate': '5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '3.5' }] }],
    }, {
        'grade': '保至70周岁（北京）',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '72' }, { 'term': '2', 'rate': '5' }],
        }, { 'name': '20年', 'rates': [{ 'term': '1', 'rate': '72' }, { 'term': '2', 'rate': '5' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '51' }, { 'term': '2', 'rate': '6' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '38' }, { 'term': '2', 'rate': '5' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '22' }, { 'term': '2', 'rate': '5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '3.5' }] }],
    }, {
        'grade': '保30年（广东、四川）',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '46.5' }, { 'term': '2', 'rate': '2.5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '45.5' }, { 'term': '2', 'rate': '2.5' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '42.5' }, { 'term': '2', 'rate': '2.5' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '24.5' }, { 'term': '2', 'rate': '2.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '3' }] }],
    }, {
        'grade': '保30年（北京）',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '46.5' }, { 'term': '2', 'rate': '2.5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '45.5' }, { 'term': '2', 'rate': '2.5' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '42.5' }, { 'term': '2', 'rate': '2.5' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '24.5' }, { 'term': '2', 'rate': '2.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '3' }] }],
    }],
    'companyName': '复星联合',
}, {
    'channel': 'FengQi',
    'id': '1716328293904084992',
    'name': '美亚“宝贝无忧”儿童个人意外伤害保险（2023版）（互联网专属）',
    'scene': '网销',
    'categories': ['意外'],
    'banner': 'https://file.fxyf99.com/product_material/20231023ngmVD0.jpg',
    'features': ['保障涵盖甲、乙类及进口药', '意外医疗0免赔最高100%比例赔付', '涵盖特需部、私立医院和诊所'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1716328293904084992',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '17.5' }] }] }],
    'companyName': '美亚财险',
}, {
    'channel': 'FengQi',
    'id': '1714613984836517888',
    'name': '瑞华乐享一生终身护理保险（2.0版）',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20231019Q0cO1h.jpg',
    'features': ['复利增额，终身相伴', '护理保障，持续增长', '攻守兼备，灵活迅速'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1714613984836517888',
    'limit': '终身',
    'commissions': [{
        'grade': '保终身',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '61' }, { 'term': '2', 'rate': '11' }, { 'term': '3', 'rate': '2.5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '58.5' }, { 'term': '2', 'rate': '9.5' }, { 'term': '3', 'rate': '2' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '58.5' }, { 'term': '2', 'rate': '8' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '40.5' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '24' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '2.5' }] }],
    }],
    'companyName': '瑞华保险',
}, {
    'channel': 'FengQi',
    'id': '1714613883921563648',
    'name': '瑞华康瑞保重大疾病保险（3.0版）',
    'scene': '经代',
    'categories': ['重疾'],
    'banner': 'https://file.fxyf99.com/product_material/20231019LLPx6j.png',
    'features': ['重疾不分组', '保额还递增', '保障组合多'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1714613883921563648',
    'limit': '70岁/终身',
    'commissions': [{
        'grade': '保终身',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '83' }, { 'term': '2', 'rate': '22' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '83' }, { 'term': '2', 'rate': '19.5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '76.5' }, { 'term': '2', 'rate': '5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '45' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '18' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '3.5' }] }],
    }, {
        'grade': '保至70岁',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '57.5' }, { 'term': '2', 'rate': '4.5' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '53.5' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '15年', 'rates': [{ 'term': '1', 'rate': '45' }] }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '40.5' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '14' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '2' }],
        }],
    }],
    'companyName': '瑞华保险',
}, {
    'channel': 'FengQi',
    'id': '1716359265420767232',
    'name': '信泰如意尊（创赢版）终身寿险【60岁以下-湖北北京广东（含深圳）】',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20231024iVFMoW.jpg',
    'features': ['投保年龄最高80周岁', '有效保额以3.0%逐年递增', '航空/重大自然灾害意外额外赔'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1716359265420767232',
    'limit': '终身',
    'commissions': [{
        'grade': '保终身',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '90' }, { 'term': '2', 'rate': '5.5' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '52' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '30' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '7' }] }],
    }],
    'companyName': '信泰人寿',
}, {
    'channel': 'FengQi',
    'id': '1716734542691561472',
    'name': '长城金麒麟2023版终身寿险（万能型）',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20231024WyOT5u.jpg',
    'features': ['100元起投，灵活安全', '最低保证结算利率2.0%', '保单借款灵活方便'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1716734542691561472',
    'limit': '终身',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '' }] }] }],
    'companyName': '长城人寿',
}, {
    'channel': 'FengQi',
    'id': '1716622812117405696',
    'name': '阳光人寿阳光升C款增额寿险',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20231024Kk0dsq.jpg',
    'features': ['终身保障一生呵护', '现金价值逐年递增', '支持保单贷款灵活周转'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1716622812117405696',
    'limit': '终身',
    'commissions': [{
        'grade': '保终身',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '54' }, { 'term': '2', 'rate': '7.5' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '45.5' }, { 'term': '2', 'rate': '5' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '25.5' }, { 'term': '2', 'rate': '2.5' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '20' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '2' }],
        }],
    }],
    'companyName': '阳光人寿',
}, {
    'channel': 'FengQi',
    'id': '1715244224115691520',
    'name': '海港恒家保臻享版终身重大疾病保险',
    'scene': '经代',
    'categories': ['重疾'],
    'banner': 'https://file.fxyf99.com/product_material/20231020EGHtcf.jpg',
    'features': ['全面保障范围广，高发重疾多次给付', '特别关爱温馨倍至，暖心保障重点守护', '青少年加倍关爱，身价豁免贴心关怀'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1715244224115691520',
    'limit': '终身',
    'commissions': [{
        'grade': '保终身【湖北】',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '97' }, { 'term': '2', 'rate': '12' }, { 'term': '3', 'rate': '5.5' }],
        }, {
            'name': '29年',
            'rates': [{ 'term': '1', 'rate': '97' }, { 'term': '2', 'rate': '12' }, { 'term': '3', 'rate': '5.5' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '97' }, { 'term': '2', 'rate': '12' }, { 'term': '3', 'rate': '5.5' }],
        }, {
            'name': '19年',
            'rates': [{ 'term': '1', 'rate': '97' }, { 'term': '2', 'rate': '12' }, { 'term': '3', 'rate': '5.5' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '55' }, { 'term': '2', 'rate': '7' }, { 'term': '3', 'rate': '3.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '3' }] }],
    }],
    'companyName': '海港人寿',
}, {
    'channel': 'FengQi',
    'id': '1714183607642075136',
    'name': '海港启明星臻享版终身寿险',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20231017xQW6Ne.jpg',
    'features': ['星光闪耀 投保宽泛', '星火相传 价值燎原', '星随您变 灵活方便'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1714183607642075136',
    'limit': '终身',
    'commissions': [{
        'grade': '保终身【湖北】',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '72' }, { 'term': '2', 'rate': '2' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '59.5' }, { 'term': '2', 'rate': '2' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '46.5' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '25.5' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '15' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '3' }],
        }],
    }],
    'companyName': '海港人寿',
}, {
    'channel': 'FengQi',
    'id': '1713835262776516608',
    'name': '弘康金禧一生年金保险（2023版）【可外籍投保】',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20231016pb6fyo.jpg',
    'features': ['年金终身领取', '领取金额写进合同', '领取后仍有现价'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1713835262776516608',
    'limit': '终身',
    'commissions': [{
        'grade': '被保人：56-60岁',
        'terms': [{ 'name': '5年', 'rates': [{ 'term': '1', 'rate': '21' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '4' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '1' }] }],
    }, {
        'grade': '被保人：51-55岁',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '46' }, { 'term': '2', 'rate': '10' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '29' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '6' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '3' }] }],
    }, {
        'grade': '被保人：46-50岁',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '53' }, { 'term': '2', 'rate': '10' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '37' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '9' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '5' }] }],
    }, {
        'grade': '被保人：41-45岁',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '62' }, { 'term': '2', 'rate': '10' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '43' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '12' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '7' }] }],
    }, {
        'grade': '被保人：36-40岁',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '63' }, { 'term': '2', 'rate': '10' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '45' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '14' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '7' }] }],
    }, {
        'grade': '被保人：31-35岁',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '64' }, { 'term': '2', 'rate': '10' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '50' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '16' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '8' }] }],
    }, {
        'grade': '被保人：26-30岁',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '65' }, { 'term': '2', 'rate': '10' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '52' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '18' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '9' }] }],
    }, {
        'grade': '被保人：19-25岁',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '65' }, { 'term': '2', 'rate': '10' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '53' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '19' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '9' }] }],
    }, {
        'grade': '被保人：0-18岁',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '66' }, { 'term': '2', 'rate': '10' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '55' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '20' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '9' }] }],
    }],
    'companyName': '弘康人寿',
}, {
    'channel': 'FengQi',
    'id': '1705052667057774592',
    'name': '众安尊享e生·中高端医疗保险2023版（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20230922Mrwvi0.png',
    'features': ['保障覆盖人群更多，住院看病有保障', '门诊住院都覆盖，异地转诊少花钱', '就诊医院层层扩展，个性化增值服务'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1705052667057774592',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '众安保险',
}, {
    'channel': 'FengQi',
    'id': '1712309412727173120',
    'name': '长城山海关永乐版终身寿险（2023）',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20231012beSWQ9.jpg',
    'features': ['保额年年有惊喜 保障有爱节节高', '交费期间更灵活 八种方式任您挑', '减保功能更友好 年金转换有依靠'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1712309412727173120',
    'limit': '终身',
    'commissions': [{
        'grade': '（湖北）',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '85' }, { 'term': '2', 'rate': '8' }, { 'term': '3', 'rate': '3.5' }],
        }, {
            'name': '7年',
            'rates': [{ 'term': '1', 'rate': '70' }, { 'term': '2', 'rate': '6.5' }, { 'term': '3', 'rate': '3' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '52' }, { 'term': '2', 'rate': '4.5' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '30' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '8' }] }],
    }, {
        'grade': '（北京）',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '82' }, { 'term': '2', 'rate': '8' }, { 'term': '3', 'rate': '3.5' }],
        }, {
            'name': '7年',
            'rates': [{ 'term': '1', 'rate': '60' }, { 'term': '2', 'rate': '6.5' }, { 'term': '3', 'rate': '3' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '45' }, { 'term': '2', 'rate': '4.5' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '25' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '8' }] }],
    }],
    'companyName': '长城人寿',
}, {
    'channel': 'FengQi',
    'id': '1476483721721221120',
    'name': '德华安顾孝亲宝中老年防癌疾病保险（互联网专属）',
    'scene': '网销',
    'categories': ['重疾'],
    'banner': 'https://file.fxyf99.com/product_material/202112309UpLJf.jpg',
    'features': ['超高性价比', '最长20年保障', '三高人群可投保'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1476483721721221120',
    'limit': '10年、20年',
    'commissions': [{
        'grade': '',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '51' }, { 'term': '2', 'rate': '25' }, { 'term': '3', 'rate': '20' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '51' }, { 'term': '2', 'rate': '22.5' }, { 'term': '3', 'rate': '2.5' }],
        }],
    }],
    'companyName': '德华安顾',
}, {
    'channel': 'FengQi',
    'id': '1711585331478257664',
    'name': '太保健康智相守终身护理保险',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20231010ZMUB2X.jpg',
    'features': ['增值确定，守护一生', '护理保障，关怀一生', '投保轻松，覆盖广泛'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1711585331478257664',
    'limit': '终身',
    'commissions': [{
        'grade': '保终身【北京、广东（含深圳）、四川】',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '50' }] }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '48' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '46' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '29' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '4' }] }],
    }],
    'companyName': '太保健康',
}, {
    'channel': 'FengQi',
    'id': '1706910229394604032',
    'name': '长城八达岭典藏版养老年金保险',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/202309274MOoeS.png',
    'features': ['起投门槛宽松灵活', '三种投保方案随心选', '加减保权益写进合同'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1706910229394604032',
    'limit': '一次交清/3年/5年/10年/15年/20年/30年',
    'commissions': [{
        'grade': '（湖北）',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '68' }, { 'term': '2', 'rate': '27' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '64' }, { 'term': '2', 'rate': '26.5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '57' }, { 'term': '2', 'rate': '25.5' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '56' }, { 'term': '2', 'rate': '19.5' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '46.5' }, { 'term': '2', 'rate': '2.5' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '24' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '4.5' }],
        }],
    }, {
        'grade': '（北京）',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '68' }, { 'term': '2', 'rate': '23.5' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '64' }, { 'term': '2', 'rate': '25' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '57' }, { 'term': '2', 'rate': '25.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '56' }, { 'term': '2', 'rate': '18' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '40.5' }, { 'term': '2', 'rate': '2.5' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '18' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '4.5' }],
        }],
    }],
    'companyName': '长城人寿',
}, {
    'channel': 'FengQi',
    'id': '1710610709277491200',
    'name': '幸福人寿幸福乐享终身寿险',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20231008hq2Dcu.jpg',
    'features': ['创新多种交费期', '长期收益稳定', '灵活规划养老生活'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1710610709277491200',
    'limit': '终身',
    'commissions': [{
        'grade': '保终身【湖北】',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '70' }, { 'term': '2', 'rate': '8.5' }],
        }, { 'name': '15年', 'rates': [{ 'term': '1', 'rate': '70' }, { 'term': '2', 'rate': '7' }] }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '57' }, { 'term': '2', 'rate': '5.5' }],
        }, { 'name': '8年', 'rates': [{ 'term': '1', 'rate': '54' }] }, {
            'name': '7年',
            'rates': [{ 'term': '1', 'rate': '47' }],
        }, { 'name': '6年', 'rates': [{ 'term': '1', 'rate': '43' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '40' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '26' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '9' }],
        }],
    }],
    'companyName': '幸福人寿',
}, {
    'channel': 'FengQi',
    'id': '1707278418162225152',
    'name': '信泰完美人生2024重大疾病保险【支持双录-青岛河北江西江苏湖北北京广东（含深圳）】',
    'scene': '经代',
    'categories': ['重疾'],
    'banner': 'https://file.fxyf99.com/product_material/20230928jbq3aY.jpg',
    'features': ['可选80周岁前首次重疾双倍赔', '可选重度恶性肿瘤、特定心脑疾病二次赔', '少儿特定/罕见重疾额外加倍赔'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1707278418162225152',
    'limit': '终身',
    'commissions': [{
        'grade': '（湖北、深圳、广东、青岛、河北、江西、江苏）',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '99' }, { 'term': '2', 'rate': '25' }, { 'term': '3', 'rate': '7' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '99' }, { 'term': '2', 'rate': '25' }, { 'term': '3', 'rate': '7' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '88' }, { 'term': '2', 'rate': '16.5' }, { 'term': '3', 'rate': '5.5' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '69.5' }, { 'term': '2', 'rate': '11.5' }, { 'term': '3', 'rate': '4.5' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '44' }, { 'term': '2', 'rate': '5.5' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '4.5' }],
        }],
    }, {
        'grade': '（北京）',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '100' }, { 'term': '2', 'rate': '33.5' }, { 'term': '3', 'rate': '7' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '100' }, { 'term': '2', 'rate': '33.5' }, { 'term': '3', 'rate': '7' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '88' }, { 'term': '2', 'rate': '16.5' }, { 'term': '3', 'rate': '5.5' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '69.5' }, { 'term': '2', 'rate': '11.5' }, { 'term': '3', 'rate': '4.5' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '44' }, { 'term': '2', 'rate': '5.5' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '4.5' }],
        }],
    }],
    'companyName': '信泰人寿',
}, {
    'channel': 'FengQi',
    'id': '1704318662308700160',
    'name': '弘康人寿利多多2号终身寿险产品计划',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20230922sFSjNp.jpg',
    'features': ['年度有效保额3.0%逐年增长；', '支持保单贷款', '满足多种场景资金需求'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1704318662308700160',
    'limit': '终身',
    'commissions': [{
        'grade': '被保人：61-65岁',
        'terms': [{ 'name': '10年', 'rates': [{ 'term': '1', 'rate': '40' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '30' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '6' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '5' }],
        }],
    }, {
        'grade': '被保人：56-60岁',
        'terms': [{ 'name': '15年', 'rates': [{ 'term': '1', 'rate': '49' }] }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '49' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '36' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '21' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '6' }] }],
    }, {
        'grade': '被保人：51-55岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '64' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '64' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '64' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '39' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '22' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '7' }],
        }],
    }, {
        'grade': '被保人：46-50岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '69' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '69' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '69' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '42' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '24' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '8' }],
        }],
    }, {
        'grade': '被保人：41-45岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '79' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '79' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '79' }, { 'term': '2', 'rate': '5.5' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '44' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '26' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '9' }] }],
    }, {
        'grade': '被保人：36-40岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '87' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '87' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '84' }, { 'term': '2', 'rate': '10' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '47' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '26' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '10' }],
        }],
    }, {
        'grade': '被保人：31-35岁',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '88' }, { 'term': '2', 'rate': '3.5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '85' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '84' }, { 'term': '2', 'rate': '10' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '49' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '27' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '11' }],
        }],
    }, {
        'grade': '被保人：26-30岁',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '88' }, { 'term': '2', 'rate': '3.5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '85' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '84' }, { 'term': '2', 'rate': '10' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '52' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '28' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '11' }],
        }],
    }, {
        'grade': '被保人：19-25岁',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '88' }, { 'term': '2', 'rate': '3.5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '85' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '84' }, { 'term': '2', 'rate': '10' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '54' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '29' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '11' }],
        }],
    }, {
        'grade': '被保人：0-18岁',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '88' }, { 'term': '2', 'rate': '3.5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '85' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '84' }, { 'term': '2', 'rate': '10' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '57' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '32' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '12' }],
        }],
    }],
    'companyName': '弘康人寿',
}, {
    'channel': 'FengQi',
    'id': '1686569768981094400',
    'name': '大家养老大盈之家2.0养老年金保险',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20230802k6OhjZ.jpg',
    'features': ['养老年金终身领', '身价保障享终身'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1686569768981094400',
    'limit': '测试',
    'commissions': [{
        'grade': '保终身',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '69' }, { 'term': '2', 'rate': '9' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '64' }, { 'term': '2', 'rate': '7.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '56' }, { 'term': '2', 'rate': '5' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '37' }, { 'term': '2', 'rate': '0.5' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '22' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '2.5' }],
        }],
    }],
    'companyName': '大家养老',
}, {
    'channel': 'FengQi',
    'id': '1698516818106384384',
    'name': '阳光人寿阳光橙C款定期寿险',
    'scene': '经代',
    'categories': ['定寿'],
    'banner': 'https://file.fxyf99.com/product_material/20230904YMAOs0.jpg',
    'features': ['优质保障，最高350万基本保额', '猝死累计赔付130%基本保额', '核保宽松，亚健康、慢病符合条件可投'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1698516818106384384',
    'limit': '10年/20年/30年/至60周岁/至65周岁/至70周岁/至80周岁',
    'commissions': [{
        'grade': '【北京、陕西、广东（含深圳）、青岛、湖北、四川、河北】',
        'terms': [{
            'name': '10年-62年',
            'rates': [{ 'term': '1', 'rate': '72' }, { 'term': '2', 'rate': '2.5' }],
        }, { 'name': '5年-9年', 'rates': [{ 'term': '1', 'rate': '34' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '4.5' }],
        }],
    }],
    'companyName': '阳光人寿',
}, {
    'channel': 'FengQi',
    'id': '1701840789649690624',
    'name': '信泰完美人生2024重大疾病保险【60岁以下-湖北北京广东（含深圳）】',
    'scene': '经代',
    'categories': ['重疾'],
    'banner': 'https://file.fxyf99.com/product_material/20230913BPSuuS.jpg',
    'features': ['可选80周岁前首次重疾双倍赔', '可选重度恶性肿瘤、特定心脑疾病二次赔', '少儿特定/罕见重疾额外加倍赔'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1701840789649690624',
    'limit': '终身',
    'commissions': [{
        'grade': '（北京）',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '99' }, { 'term': '2', 'rate': '33.5' }, { 'term': '3', 'rate': '7' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '99' }, { 'term': '2', 'rate': '33.5' }, { 'term': '3', 'rate': '7' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '88' }, { 'term': '2', 'rate': '16.5' }, { 'term': '3', 'rate': '5.5' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '69.5' }, { 'term': '2', 'rate': '11.5' }, { 'term': '3', 'rate': '4.5' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '44' }, { 'term': '2', 'rate': '5.5' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '4.5' }],
        }],
    }, {
        'grade': '（湖北、深圳、广东）',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '100' }, { 'term': '2', 'rate': '25' }, { 'term': '3', 'rate': '7' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '100' }, { 'term': '2', 'rate': '25' }, { 'term': '3', 'rate': '7' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '88' }, { 'term': '2', 'rate': '16.5' }, { 'term': '3', 'rate': '5.5' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '69.5' }, { 'term': '2', 'rate': '11.5' }, { 'term': '3', 'rate': '4.5' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '44' }, { 'term': '2', 'rate': '5.5' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '4.5' }],
        }],
    }],
    'companyName': '信泰人寿',
}, {
    'channel': 'FengQi',
    'id': '1696095038186913792',
    'name': '中邮悦享鑫生（慧选版）终身寿险',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20230828LYTPGf.png',
    'features': ['保单现金价值合同载明', '高额身故保障', '财富定向传承'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1696095038186913792',
    'limit': '终身',
    'commissions': [{
        'grade': '【北京、广东（除深圳）、河北、陕西】',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '66.5' }, { 'term': '2', 'rate': '20' }, { 'term': '3', 'rate': '5.5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '63' }, { 'term': '2', 'rate': '15' }, { 'term': '3', 'rate': '5' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '59.5' }, { 'term': '2', 'rate': '10' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '39' }] }],
    }],
    'companyName': '中邮人寿',
}, {
    'channel': 'FengQi',
    'id': '1701767909924839424',
    'name': '华贵麦兜兜少儿重大疾病保险（互联网专属）',
    'scene': '网销',
    'categories': ['重疾'],
    'banner': 'https://file.fxyf99.com/product_material/20230913Hvdshd.jpg',
    'features': ['一百万保额保障额度拉满', '128种重疾保障广泛', '赠送健康增值服务'],
    'coolingOff': '20',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1701767909924839424',
    'limit': '30年',
    'commissions': [{
        'grade': '',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '52' }, { 'term': '2', 'rate': '32' }, { 'term': '3', 'rate': '16' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '52' }, { 'term': '2', 'rate': '32' }, { 'term': '3', 'rate': '16' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '43' }, { 'term': '2', 'rate': '9.5' }, { 'term': '3', 'rate': '5.5' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '29.5' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '5.5' }] }],
    }],
    'companyName': '华贵保险',
}, {
    'channel': 'FengQi',
    'id': '1699953230444138496',
    'name': '弘康人寿金玉满堂3.0终身寿险',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20230908ona3WT.png',
    'features': ['年度有效保额3.0%逐年增长；', '支持资金周转可应急；', '满足多种场景资金需求'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1699953230444138496',
    'limit': '终身',
    'commissions': [{
        'grade': '被保人：56-60岁',
        'terms': [{ 'name': '15年', 'rates': [{ 'term': '1', 'rate': '44' }] }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '44' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '34' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '20' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '4' }] }],
    }, {
        'grade': '被保人：51-55岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '57' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '57' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '57' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '38' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '21' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '5' }],
        }],
    }, {
        'grade': '被保人：46-50岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '66' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '66' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '66' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '39' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '23' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '6' }],
        }],
    }, {
        'grade': '被保人：41-45岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '76' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '76' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '79' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '41' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '25' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '7' }],
        }],
    }, {
        'grade': '被保人：36-40岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '82' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '82' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '79' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '44' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '25' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '8' }],
        }],
    }, {
        'grade': '被保人：31-35岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '84' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '84' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '84' }, { 'term': '2', 'rate': '10' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '48' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '26' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '9' }],
        }],
    }, {
        'grade': '被保人：26-30岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '84' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '84' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '84' }, { 'term': '2', 'rate': '10' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '50' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '26' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '9' }],
        }],
    }, {
        'grade': '被保人：19-25岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '84' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '84' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '84' }, { 'term': '2', 'rate': '10' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '52' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '27' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '9' }],
        }],
    }, {
        'grade': '被保人：0-18岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '84' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '84' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '84' }, { 'term': '2', 'rate': '10' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '54' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '29' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '10' }],
        }],
    }],
    'companyName': '弘康人寿',
}, {
    'channel': 'FengQi',
    'id': '1694964276329893888',
    'name': '永诚诉讼财产保全责任保险',
    'scene': '经代',
    'categories': ['家财'],
    'banner': 'https://file.fxyf99.com/product_material/20230825pXVgIx.png',
    'features': ['费率低性价比高', '投保流程操作便捷', '手续方便'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1694964276329893888',
    'limit': '1',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '48' }] }] }],
    'companyName': '永诚保险',
}, {
    'channel': 'FengQi',
    'id': '1694879784878796800',
    'name': '中国人保乐学保·北京学生专属保障计划',
    'scene': '网销',
    'categories': ['意外'],
    'banner': 'https://file.fxyf99.com/product_material/20230825oWxQrO.png',
    'features': ['百万住院0免赔额', '百万意外保障全', '百种重疾护周全'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1694879784878796800',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '15.5' }] }] }],
    'companyName': '中国人保',
}, {
    'channel': 'FengQi',
    'id': '1689892495735975936',
    'name': '泰康颐养有约年金保险（分红型）产品计划',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20230811HkcYe1.jpg',
    'features': ['高龄专属，养老无忧', '颐养年年，身故传承', '保单分红，累计生息'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1689892495735975936',
    'limit': '至105周岁',
    'commissions': [{
        'grade': '【北京、河北、湖北、广东（含深圳）、四川、陕西、青岛】',
        'terms': [{ 'name': '10年', 'rates': [{ 'term': '1', 'rate': '18' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '8.5' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '5' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '1.5' }],
        }],
    }],
    'companyName': '泰康人寿',
}, {
    'channel': 'FengQi',
    'id': '1694884714784026624',
    'name': '信泰如意人生无忧（2024）重大疾病保险【60岁以下-北京广东（含深圳）】',
    'scene': '经代',
    'categories': ['重疾'],
    'banner': 'https://file.fxyf99.com/product_material/20230825rCtYoA.png',
    'features': ['重疾最高2次赔付，疾病不分组', '可选60周岁前首次重/中/轻症额外赔', '可选重度恶性肿瘤、特定心脑疾病多次赔'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1694884714784026624',
    'limit': '终身',
    'commissions': [{
        'grade': '【北京】',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '85' }, { 'term': '2', 'rate': '30.5' }, { 'term': '3', 'rate': '5' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '85' }, { 'term': '2', 'rate': '30.5' }, { 'term': '3', 'rate': '5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '81.5' }, { 'term': '2', 'rate': '11.5' }, { 'term': '3', 'rate': '4' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '73' }, { 'term': '2', 'rate': '8' }, { 'term': '3', 'rate': '3' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '43.5' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '4.5' }] }],
    }, {
        'grade': '【广东（含深圳）】',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '97' }, { 'term': '2', 'rate': '15.5' }, { 'term': '3', 'rate': '5' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '97' }, { 'term': '2', 'rate': '15.5' }, { 'term': '3', 'rate': '5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '81.5' }, { 'term': '2', 'rate': '11.5' }, { 'term': '3', 'rate': '4' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '73' }, { 'term': '2', 'rate': '8' }, { 'term': '3', 'rate': '3' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '43.5' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '4.5' }] }],
    }],
    'companyName': '信泰人寿',
}, {
    'channel': 'FengQi',
    'id': '1694648038267199488',
    'name': '信泰如意久久守护（2024）重大疾病保险【60岁以下-北京湖北广东（含深圳）】',
    'scene': '经代',
    'categories': ['重疾'],
    'banner': 'https://file.fxyf99.com/product_material/20230825CphSYh.png',
    'features': ['重疾赔付后，轻中症责任不终止', '可选70周岁前首次重/中/轻症双倍赔', '可选重度恶性肿瘤、特定心脑疾病多次赔'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1694648038267199488',
    'limit': '终身',
    'commissions': [{
        'grade': '【北京】',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '85' }, { 'term': '2', 'rate': '30.5' }, { 'term': '3', 'rate': '5' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '85' }, { 'term': '2', 'rate': '30.5' }, { 'term': '3', 'rate': '5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '81.5' }, { 'term': '2', 'rate': '11.5' }, { 'term': '3', 'rate': '4' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '73' }, { 'term': '2', 'rate': '8' }, { 'term': '3', 'rate': '3' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '43.5' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '4.5' }] }],
    }, {
        'grade': '【湖北、广东（含深圳）】',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '97' }, { 'term': '2', 'rate': '15.5' }, { 'term': '3', 'rate': '5' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '97' }, { 'term': '2', 'rate': '15.5' }, { 'term': '3', 'rate': '5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '81.5' }, { 'term': '2', 'rate': '11.5' }, { 'term': '3', 'rate': '4' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '73' }, { 'term': '2', 'rate': '8' }, { 'term': '3', 'rate': '3' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '43.5' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '4.5' }] }],
    }],
    'companyName': '信泰人寿',
}, {
    'channel': 'FengQi',
    'id': '1689892251451322368',
    'name': '泰康岁月有约养老年金保险产品计划',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20230811MkOutC.jpg',
    'features': ['领取金额确定，最低保证领取25年', '分红收益进万能，复利累积更增值', '保单贷款额度高，资金周转更灵活'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1689892251451322368',
    'limit': '保至106周岁',
    'commissions': [{
        'grade': '【北京、河北、湖北、广东（含深圳）、四川、陕西、青岛】',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '65' }, { 'term': '2', 'rate': '6' }],
        }, { 'name': '15年', 'rates': [{ 'term': '1', 'rate': '56' }, { 'term': '2', 'rate': '4' }] }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '59' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '31' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '19' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '6' }] }],
    }],
    'companyName': '泰康人寿',
}, {
    'channel': 'FengQi',
    'id': '1692064776358699008',
    'name': '弘康弘运连连终身寿险（2023）【可对接人核】',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20230817Ped59E.png',
    'features': ['年度有效保额逐年递增', '锁定现价，安全确定', '航空意外，安心守护'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1692064776358699008',
    'limit': '终身',
    'commissions': [{
        'grade': '被保人：61-65岁',
        'terms': [{ 'name': '10年', 'rates': [{ 'term': '1', 'rate': '27' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '21' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '4' }] }],
    }, {
        'grade': '被保人：56-60岁',
        'terms': [{ 'name': '15年', 'rates': [{ 'term': '1', 'rate': '31' }] }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '31' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '25.5' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '5.5' }],
        }],
    }, {
        'grade': '被保人：51-55岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '35.5' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '35.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '35.5' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '28.5' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '7' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '2' }],
        }],
    }, {
        'grade': '被保人：46-50岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '41.5' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '41.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '41.5' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '33' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '9.5' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '3.5' }],
        }],
    }, {
        'grade': '被保人：41-45岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '47.5' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '47.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '61' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '37' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '12' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '3.5' }],
        }],
    }, {
        'grade': '被保人：36-40岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '52.5' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '52.5' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '61' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '40.5' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '13.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '3.5' }] }],
    }, {
        'grade': '被保人：31-35岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '56.5' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '56.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '61' }, { 'term': '2', 'rate': '6' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '43' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '15' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '3.5' }],
        }],
    }, {
        'grade': '被保人：19-25岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '62.5' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '62.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '61' }, { 'term': '2', 'rate': '6' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '46.5' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '17.5' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '4' }],
        }],
    }, {
        'grade': '被保人：26-30岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '60' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '60' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '61' }, { 'term': '2', 'rate': '6' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '45.5' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '16.5' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '4' }],
        }],
    }, {
        'grade': '被保人：0-18岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '64.5' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '64.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '61' }, { 'term': '2', 'rate': '6' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '48' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '18' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '6' }],
        }],
    }],
    'companyName': '弘康人寿',
}, {
    'channel': 'FengQi',
    'id': '1692343026238341120',
    'name': '华泰少儿意外险（互联网专属）',
    'scene': '网销',
    'categories': ['意外'],
    'banner': 'https://file.fxyf99.com/product_material/20230818ELhS7E.jpg',
    'features': ['保食物中毒急性肠胃炎，含第三者责任', '可选拓展高端医疗，意外住院有津贴'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1692343026238341120',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '30.5' }] }] }],
    'companyName': '华泰保险',
}, {
    'channel': 'FengQi',
    'id': '1691645306867531776',
    'name': '湖北普惠门诊保',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20230816FZfGWD.jpg',
    'features': ['全年不限次问诊', '报销0门槛比例70%', '药品种类全'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1691645306867531776',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '13' }] }] }],
    'companyName': '太平洋财产保险',
}, {
    'channel': 'FengQi',
    'id': '1688853464542429184',
    'name': '泰康嘉悦人生年金保险（分红型）',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/202308081rx6nA.png',
    'features': ['保证给付25年', '生存金和养老金给付105周岁', '投保年领拓宽70周岁'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1688853464542429184',
    'limit': '至105周岁',
    'commissions': [{
        'grade': '【北京、河北、湖北、广东（含深圳）、四川、陕西、青岛】',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '51' }, { 'term': '2', 'rate': '2.5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '51' }, { 'term': '2', 'rate': '2.5' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '42.5' }, { 'term': '1', 'rate': '42.5' }, { 'term': '2', 'rate': '2.5' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '25.5' }, { 'term': '1', 'rate': '25.5' }, { 'term': '2', 'rate': '2.5' }],
        }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '13.5' }, { 'term': '1', 'rate': '13.5' }, {
                'term': '2',
                'rate': '2.5',
            }, { 'term': '2', 'rate': '2.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '3.5' }] }],
    }],
    'companyName': '泰康人寿',
}, {
    'channel': 'FengQi',
    'id': '1688853198816493568',
    'name': '泰康泰悦人生年金保险',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20230808vJtf5b.jpg',
    'features': ['三大类生存保险金', '复利增值灵活取', '身价保障有担当'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1688853198816493568',
    'limit': '保至105周岁首个年生效对应日',
    'commissions': [{
        'grade': '【北京、河北、湖北、广东（含深圳）、四川、陕西、青岛】',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '61.5' }, { 'term': '2', 'rate': '15' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '55' }, { 'term': '2', 'rate': '10' }],
        }, {
            'name': '12年',
            'rates': [{ 'term': '1', 'rate': '55.5' }, { 'term': '2', 'rate': '5' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '55.5' }, { 'term': '2', 'rate': '5' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '42.5' }, { 'term': '2', 'rate': '2.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '6' }] }],
    }],
    'companyName': '泰康人寿',
}, {
    'channel': 'FengQi',
    'id': '1691391301118648320',
    'name': '弘康金玉满堂3.0终身寿险【可对接人核】',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20230815neImdx.png',
    'features': ['年度有效保额3.0%逐年增长；', '支持资金周转可应急；', '满足多种场景资金需求'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1691391301118648320',
    'limit': '终身',
    'commissions': [{
        'grade': '被保人：56-60岁',
        'terms': [{ 'name': '15年', 'rates': [{ 'term': '1', 'rate': '44' }] }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '44' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '34' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '20' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '4' }] }],
    }, {
        'grade': '被保人：51-55岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '57' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '57' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '57' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '38' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '21' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '5' }],
        }],
    }, {
        'grade': '被保人：46-50岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '66' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '66' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '66' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '39' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '23' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '6' }],
        }],
    }, {
        'grade': '被保人：41-45岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '76' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '76' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '79' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '41' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '25' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '7' }],
        }],
    }, {
        'grade': '被保人：36-40岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '82' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '82' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '79' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '44' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '25' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '8' }],
        }],
    }, {
        'grade': '被保人：31-35岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '84' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '84' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '84' }, { 'term': '2', 'rate': '10' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '48' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '26' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '9' }],
        }],
    }, {
        'grade': '被保人：26-30岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '84' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '84' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '84' }, { 'term': '2', 'rate': '10' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '50' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '26' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '9' }],
        }],
    }, {
        'grade': '被保人：19-25岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '84' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '84' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '84' }, { 'term': '2', 'rate': '10' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '52' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '27' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '9' }],
        }],
    }, {
        'grade': '被保人：0-18岁',
        'terms': [{ 'name': '20年', 'rates': [{ 'term': '1', 'rate': '84' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '84' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '84' }, { 'term': '2', 'rate': '10' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '54' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '29' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '10' }],
        }],
    }],
    'companyName': '弘康人寿',
}, {
    'channel': 'FengQi',
    'id': '1689884767533723648',
    'name': '复星联合康爱一生护理保险（荣耀版）',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20230811TLF4le.jpg',
    'features': ['计划选择多样，灵活搭配', '保障额度递增，现价明确', '现价部分领取，合理规划'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1689884767533723648',
    'limit': '终身',
    'commissions': [{
        'grade': '保终身【北京、广东（含深圳）、四川】',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '77.5' }, { 'term': '2', 'rate': '10.5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '74.5' }, { 'term': '2', 'rate': '8' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '78' }, { 'term': '2', 'rate': '7' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '52' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '35' }] }],
    }],
    'companyName': '复星联合',
}, {
    'channel': 'FengQi',
    'id': '1689612160337149952',
    'name': '爱心人寿守护神终身寿险（臻享版）',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20230811AITaPw.jpg',
    'features': ['有效保额每年递增', '免责条款少', '终身保障身故或全残'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1689612160337149952',
    'limit': '终身',
    'commissions': [{
        'grade': '保终身',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '55' }, { 'term': '2', 'rate': '7' }, { 'term': '3', 'rate': '3.5' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '38' }, { 'term': '2', 'rate': '3' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '25.5' }, { 'term': '2', 'rate': '2.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '4' }] }],
    }],
    'companyName': '爱心人寿',
}, {
    'channel': 'FengQi',
    'id': '1689164292036804608',
    'name': '信泰如意致享养老年金保险【60岁以下-湖北北京广东（含深圳）】',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20230809YNom4r.png',
    'features': ['养老年金终身领取，金额确定', '多种领取方式/领取年龄可选', '开始领取后合同仍有现金价值'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1689164292036804608',
    'limit': '终身',
    'commissions': [{
        'grade': '保终身【北京、广东（含深圳）、湖北】45-69岁',
        'terms': [{
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '48.5' }, { 'term': '2', 'rate': '17' }, { 'term': '3', 'rate': '2.5' }],
        }],
    }, {
        'grade': '保终身【北京、广东（含深圳）、湖北】0-44岁',
        'terms': [{
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '60' }, { 'term': '2', 'rate': '5.5' }, { 'term': '3', 'rate': '2.5' }],
        }],
    }, {
        'grade': '保终身【北京、广东（含深圳）、湖北】',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '59.5' }, { 'term': '2', 'rate': '10.5' }, { 'term': '3', 'rate': '4.5' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '30' }, { 'term': '2', 'rate': '1' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '8' }],
        }],
    }],
    'companyName': '信泰人寿',
}, {
    'channel': 'FengQi',
    'id': '1685463763309490176',
    'name': '华贵大麦旗舰版A款定期寿险（互联网专属）',
    'scene': '网销',
    'categories': ['定寿'],
    'banner': 'https://file.fxyf99.com/product_material/20230730iP0Y8O.png',
    'features': ['产品性价比高，低费率高保障', '失能责任可附加，收入损伤有补偿', '可选责任自主选，涵盖水陆空公共交通意外'],
    'coolingOff': '20',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1685463763309490176',
    'limit': '10年，20年，30年，至60周岁，至65周岁，至70周岁',
    'commissions': [{
        'grade': '',
        'terms': [{
            'name': '30年-52年',
            'rates': [{ 'term': '1', 'rate': '52' }, { 'term': '2', 'rate': '28.5' }, { 'term': '3', 'rate': '2' }],
        }, {
            'name': '20年-29年',
            'rates': [{ 'term': '1', 'rate': '52' }, { 'term': '2', 'rate': '19.5' }, { 'term': '3', 'rate': '2' }],
        }, {
            'name': '10年-19年',
            'rates': [{ 'term': '1', 'rate': '43' }, { 'term': '2', 'rate': '3.5' }, { 'term': '3', 'rate': '2' }],
        }, { 'name': '5年-9年', 'rates': [{ 'term': '1', 'rate': '22.5' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '5.5' }],
        }],
    }],
    'companyName': '华贵保险',
}, {
    'channel': 'FengQi',
    'id': '1686354789686685696',
    'name': '复星联合乐健中端医疗保险（2023版）',
    'scene': '经代',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20230808IRc09V.png',
    'features': ['个人/家庭的专属医疗保障', '含住院、门诊责任', '重大疾病医疗最高200万保障'],
    'coolingOff': '5',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1686354789686685696',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '14' }] }] }],
    'companyName': '复星联合',
}, {
    'channel': 'FengQi',
    'id': '1685974676000194560',
    'name': '信泰如意人生无忧（2024）重大疾病保险【支持双录-青岛河北江西江苏北京广东（含深圳）】',
    'scene': '经代',
    'categories': ['重疾'],
    'banner': 'https://file.fxyf99.com/product_material/20230731isxLCz.png',
    'features': ['重疾最高2次赔付，疾病不分组', '可选60周岁前首次重/中/轻症额外赔', '可选重度恶性肿瘤、特定心脑疾病多次赔'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1685974676000194560',
    'limit': '终身',
    'commissions': [{
        'grade': '【北京】',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '85' }, { 'term': '2', 'rate': '30.5' }, { 'term': '3', 'rate': '5' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '85' }, { 'term': '2', 'rate': '30.5' }, { 'term': '3', 'rate': '5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '81.5' }, { 'term': '2', 'rate': '11.5' }, { 'term': '3', 'rate': '4' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '73' }, { 'term': '2', 'rate': '8' }, { 'term': '3', 'rate': '3' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '43.5' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '4.5' }] }],
    }, {
        'grade': '【青岛、广东（含深圳）、河北、江西、江苏】',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '97' }, { 'term': '2', 'rate': '15.5' }, { 'term': '3', 'rate': '5' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '97' }, { 'term': '2', 'rate': '15.5' }, { 'term': '3', 'rate': '5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '81.5' }, { 'term': '2', 'rate': '11.5' }, { 'term': '3', 'rate': '4' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '73' }, { 'term': '2', 'rate': '8' }, { 'term': '3', 'rate': '3' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '43.5' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '4.5' }] }],
    }],
    'companyName': '信泰人寿',
}, {
    'channel': 'FengQi',
    'id': '1686189353939177472',
    'name': '信泰如意久久守护（2024）重大疾病保险【支持双录-青岛河北江西江苏北京湖北广东（含深圳）】',
    'scene': '经代',
    'categories': ['重疾'],
    'banner': 'https://file.fxyf99.com/product_material/20230801ljBnqt.png',
    'features': ['重疾赔付后，轻中症责任不终止', '可选70周岁前首次重/中/轻症双倍赔', '可选重度恶性肿瘤、特定心脑疾病多次赔'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1686189353939177472',
    'limit': '终身',
    'commissions': [{
        'grade': '【北京】',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '85' }, { 'term': '2', 'rate': '30.5' }, { 'term': '3', 'rate': '5' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '85' }, { 'term': '2', 'rate': '30.5' }, { 'term': '3', 'rate': '5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '81.5' }, { 'term': '2', 'rate': '11.5' }, { 'term': '3', 'rate': '4' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '73' }, { 'term': '2', 'rate': '8' }, { 'term': '3', 'rate': '3' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '43.5' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '4.5' }] }],
    }, {
        'grade': '【湖北、广东（含深圳）、青岛、河北、江西、江苏】',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '97' }, { 'term': '2', 'rate': '15.5' }, { 'term': '3', 'rate': '5' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '97' }, { 'term': '2', 'rate': '15.5' }, { 'term': '3', 'rate': '5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '81.5' }, { 'term': '2', 'rate': '11.5' }, { 'term': '3', 'rate': '4' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '73' }, { 'term': '2', 'rate': '8' }, { 'term': '3', 'rate': '3' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '43.5' }, { 'term': '2', 'rate': '3.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '4.5' }] }],
    }],
    'companyName': '信泰人寿',
}, {
    'channel': 'FengQi',
    'id': '1685888600532250624',
    'name': '华贵大麦甜蜜家2023定期寿险（互联网专属）',
    'scene': '网销',
    'categories': ['定寿'],
    'banner': 'https://file.fxyf99.com/product_material/20230731MaHWLx.png',
    'features': ['最高300万免体检保额', '夫妻共保最多赔4倍保额', '保费豁免更贴心'],
    'coolingOff': '20',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1685888600532250624',
    'limit': '10年/20年/30年/35年/40年',
    'commissions': [{
        'grade': '',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '49' }, { 'term': '2', 'rate': '13.5' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '49' }, { 'term': '2', 'rate': '8.5' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '40.5' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '19' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '4.5' }] }],
    }],
    'companyName': '华贵保险',
}, {
    'channel': 'FengQi',
    'id': '1687272892073189376',
    'name': '信美相互家道永传终身寿险',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20230804tbimb6.jpg',
    'features': ['保额每年3%递增', '保障守护一生', '定向传承方案'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1687272892073189376',
    'limit': '终身',
    'commissions': [{
        'grade': '保终身',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '67.5' }, { 'term': '2', 'rate': '13' }, { 'term': '3', 'rate': '2.5' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '42.5' }, { 'term': '2', 'rate': '3' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '20.5' }, { 'term': '2', 'rate': '3' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '3.5' }] }],
    }],
    'companyName': '信美相互',
}, {
    'channel': 'FengQi',
    'id': '1686354922008588288',
    'name': '复星联合光辉岁月护理保险',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20230803npHn0W.jpg',
    'features': ['保单利益明确有保证', '护理保障复利稳增值', '特定疾病20年保证赔付期'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1686354922008588288',
    'limit': '终身',
    'commissions': [{
        'grade': '保终身【北京、广东（含深圳）】',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '71.5' }, { 'term': '2', 'rate': '4' }],
        }, { 'name': '15年', 'rates': [{ 'term': '1', 'rate': '68' }, { 'term': '2', 'rate': '4' }] }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '66' }, { 'term': '2', 'rate': '2.5' }],
        }, { 'name': '5年', 'rates': [{ 'term': '1', 'rate': '39.5' }] }, {
            'name': '3年',
            'rates': [{ 'term': '1', 'rate': '20' }],
        }],
    }],
    'companyName': '复星联合',
}, {
    'channel': 'FengQi',
    'id': '1686354663756902400',
    'name': '复星联合康乐一生（易核版2.0）重疾险',
    'scene': '经代',
    'categories': ['重疾'],
    'banner': 'https://file.fxyf99.com/product_material/20230803BBEeID.png',
    'features': ['覆盖多种高发疾病，保障广泛', '健告简易，快速核保', '身故/全残保障'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1686354663756902400',
    'limit': '终身',
    'commissions': [{
        'grade': '广东（含深圳）',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '91.5' }, { 'term': '2', 'rate': '5' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '91.5' }, { 'term': '2', 'rate': '5' }],
        }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '52.5' }, { 'term': '2', 'rate': '4' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '39' }, { 'term': '2', 'rate': '3' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '27' }, { 'term': '2', 'rate': '2.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '3' }] }],
    }, {
        'grade': '北京',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '85' }, { 'term': '2', 'rate': '9' }],
        }, { 'name': '20年', 'rates': [{ 'term': '1', 'rate': '85' }, { 'term': '2', 'rate': '9' }] }, {
            'name': '15年',
            'rates': [{ 'term': '1', 'rate': '52.5' }, { 'term': '2', 'rate': '4' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '39' }, { 'term': '2', 'rate': '3' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '27' }, { 'term': '2', 'rate': '2.5' }],
        }, { 'name': '1年', 'rates': [{ 'term': '1', 'rate': '3' }] }],
    }],
    'companyName': '复星联合',
}, {
    'channel': 'FengQi',
    'id': '1685974391240507392',
    'name': '信泰如意致享养老年金保险【支持双录-青岛河北江西江苏湖北北京广东（含深圳）】',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/202308092hOajd.png',
    'features': ['养老年金终身领取，金额确定', '多种领取方式/领取年龄可选', '开始领取后合同仍有现金价值'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1685974391240507392',
    'limit': '终身',
    'commissions': [{
        'grade': '保终身【北京、广东（含深圳）、湖北、青岛、河北、江西、江苏】45-69岁',
        'terms': [{
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '48.5' }, { 'term': '2', 'rate': '17' }, { 'term': '3', 'rate': '2.5' }],
        }],
    }, {
        'grade': '保终身【北京、广东（含深圳）、湖北、青岛、河北、江西、江苏】0-44岁',
        'terms': [{
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '60' }, { 'term': '2', 'rate': '5.5' }, { 'term': '3', 'rate': '2.5' }],
        }],
    }, {
        'grade': '保终身【北京、广东（含深圳）、湖北、青岛、河北、江西、江苏】',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '59.5' }, { 'term': '2', 'rate': '10.5' }, { 'term': '3', 'rate': '4.5' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '30' }, { 'term': '2', 'rate': '1' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '8' }],
        }],
    }],
    'companyName': '信泰人寿',
}, {
    'channel': 'FengQi',
    'id': '1685462796723744768',
    'name': '信美相互挚信一生终身养老保险产品计划',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20230730I69rvC.png',
    'features': ['保证领取至85周岁', '领取随心，按需选择', '信美会员，权益专享'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1685462796723744768',
    'limit': '终身',
    'commissions': [{
        'grade': 'B款',
        'terms': [{
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '50' }, { 'term': '2', 'rate': '22' }, { 'term': '3', 'rate': '4.5' }],
        }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '38.5' }, { 'term': '2', 'rate': '13.5' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '23' }] }],
    }, { 'grade': 'A款', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '8' }] }] }],
    'companyName': '信美相互',
}, {
    'channel': 'FengQi',
    'id': '1685463129088782336',
    'name': '信美相互传家有道尊享版2.0终身寿险',
    'scene': '经代',
    'categories': ['资产'],
    'banner': 'https://file.fxyf99.com/product_material/20230730CuwsKo.png',
    'features': ['保额每年3%递增', '保障守护一生', '定向传承方案'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1685463129088782336',
    'limit': '终身',
    'commissions': [{
        'grade': '保终身',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '58' }, { 'term': '2', 'rate': '19.5' }, { 'term': '3', 'rate': '14' }],
        }, { 'name': '10年', 'rates': [{ 'term': '1', 'rate': '80' }, { 'term': '2', 'rate': '9' }] }, {
            'name': '5年',
            'rates': [{ 'term': '1', 'rate': '40' }, { 'term': '2', 'rate': '2.5' }],
        }, { 'name': '3年', 'rates': [{ 'term': '1', 'rate': '25' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '7' }],
        }],
    }],
    'companyName': '信美相互',
}, {
    'channel': 'FengQi',
    'id': '1685954987228381184',
    'name': '人保寿险i无忧2.0重大疾病保险（互联网专属）',
    'scene': '网销',
    'categories': ['重疾'],
    'banner': 'https://file.fxyf99.com/product_material/20230731k3j13i.png',
    'features': ['覆盖180种疾病，保障广泛', '多种可选责任按需自由搭配', '身故责任灵活可选'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1685954987228381184',
    'limit': '保至70周岁、终身',
    'commissions': [{
        'grade': '保终身',
        'terms': [{
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '49' }, { 'term': '2', 'rate': '20' }, { 'term': '3', 'rate': '10' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '49' }, { 'term': '2', 'rate': '20' }, { 'term': '3', 'rate': '10' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '49' }, { 'term': '2', 'rate': '15' }, { 'term': '3', 'rate': '5' }],
        }],
    }, {
        'grade': '保至70岁',
        'terms': [{
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '32' }, { 'term': '2', 'rate': '12.5' }, { 'term': '3', 'rate': '2.5' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '23.5' }, { 'term': '2', 'rate': '10' }, { 'term': '3', 'rate': '2.5' }],
        }],
    }],
    'companyName': '人保寿险',
}, {
    'channel': 'FengQi',
    'id': '1683667286988210176',
    'name': '同方全球「臻爱2023」互联网定期寿险',
    'scene': '网销',
    'categories': ['定寿'],
    'banner': 'https://file.fxyf99.com/product_material/20230725EqYQRz.png',
    'features': ['免体检保额达新高', '兼顾交通意外保障', '核保宽松免责少'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1683667286988210176',
    'limit': '20年/30年/保至55周岁/保至60周岁/保至65周岁/保至70周岁/保至80周岁',
    'commissions': [{
        'grade': '',
        'terms': [{
            'name': '至80岁',
            'rates': [{ 'term': '1', 'rate': '51' }, { 'term': '2', 'rate': '10' }, { 'term': '3', 'rate': '7.5' }],
        }, {
            'name': '至70岁',
            'rates': [{ 'term': '1', 'rate': '51' }, { 'term': '2', 'rate': '10' }, { 'term': '3', 'rate': '7.5' }],
        }, {
            'name': '至65岁',
            'rates': [{ 'term': '1', 'rate': '51' }, { 'term': '2', 'rate': '10' }, { 'term': '3', 'rate': '7.5' }],
        }, {
            'name': '至60岁',
            'rates': [{ 'term': '1', 'rate': '51' }, { 'term': '2', 'rate': '10' }, { 'term': '3', 'rate': '7.5' }],
        }, {
            'name': '至55岁',
            'rates': [{ 'term': '1', 'rate': '51' }, { 'term': '2', 'rate': '10' }, { 'term': '3', 'rate': '7.5' }],
        }, {
            'name': '30年',
            'rates': [{ 'term': '1', 'rate': '51' }, { 'term': '2', 'rate': '10' }, { 'term': '3', 'rate': '7.5' }],
        }, {
            'name': '20年',
            'rates': [{ 'term': '1', 'rate': '51' }, { 'term': '2', 'rate': '10' }, { 'term': '3', 'rate': '7.5' }],
        }, {
            'name': '10年',
            'rates': [{ 'term': '1', 'rate': '51' }, { 'term': '2', 'rate': '10' }, { 'term': '3', 'rate': '7.5' }],
        }],
    }],
    'companyName': '同方全球人寿',
}, {
    'channel': 'FengQi',
    'id': '1679682495370141696',
    'name': '华泰泰然e诺百万医疗险（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20230714DGU2iu.png',
    'features': ['最高保额400万', '涵盖质子重离子医疗', '重疾绿通增值服务'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1679682495370141696',
    'limit': '经典版30天/升级版90天',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '38' }] }] }],
    'companyName': '华泰保险',
}, {
    'channel': 'FengQi',
    'id': '1679682272631627776',
    'name': '中荷超医保1号医疗保险（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/2023071492wl1b.png',
    'features': ['7大防护网，保障更广泛', '最高200万保额，疾病/意外住院均保', '可选特定药品医疗/重大疾病保险金'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1679682272631627776',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '中荷人寿',
}, {
    'channel': 'FengQi',
    'id': '1678933491055513600',
    'name': '人保健康健康金福互联网医保补充医疗保险',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20230712SUrR38.jpg',
    'features': ['保费低至78元/年起', '强有力补充医保', '可报销住院医疗/特殊门诊/门诊手术'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1678933491055513600',
    'limit': '1年',
    'commissions': [{
        'grade': '',
        'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '22' }, { 'term': '2', 'rate': '17.5' }] }],
    }],
    'companyName': '中国人保',
}, {
    'channel': 'FengQi',
    'id': '1674296706744000512',
    'name': '众惠相互惠享e生百万医疗2023（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20230705xeaMGF.png',
    'features': ['健康状况越好，免赔额越低', '五大基础保障，大病小病都包含', '五大可选责任，更多就医选择'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1674296706744000512',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '44' }] }] }],
    'companyName': '众惠相互',
}, {
    'channel': 'FengQi',
    'id': '1670620058360524800',
    'name': '泰心安百万医疗险B款（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20230619FaWkqJ.png',
    'features': ['报销门槛低，理赔更容易', '报销社保内外医疗费，最高600万', '特药升级109种，治疗便捷有效'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1670620058360524800',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '52.5' }] }] }],
    'companyName': '泰康在线',
}, {
    'channel': 'FengQi',
    'id': '1668807314185453568',
    'name': '泰爱保重疾险至尊版（互联网专属）',
    'scene': '网销',
    'categories': ['重疾'],
    'banner': 'https://file.fxyf99.com/product_material/202306140nCQLS.jpg',
    'features': ['100种重疾最高50万保额', '恶性肿瘤医疗最高100万', '增值服务看病无忧'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1668807314185453568',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '泰康在线',
}, {
    'channel': 'FengQi',
    'id': '1666012308997750784',
    'name': '人保寿险关爱e生终身癌症医疗保险计划（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20230606koD4SG.png',
    'features': ['投保宽松保终身，患病理赔还能续', '年度保额400万，终身保额1000万', '医院覆盖面广，看病理赔更便捷'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1666012308997750784',
    'limit': '1年',
    'commissions': [{
        'grade': '',
        'terms': [{
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '24.5' }, { 'term': '2', 'rate': '15' }, { 'term': '3', 'rate': '15' }],
        }],
    }],
    'companyName': '人保寿险',
}, {
    'channel': 'FengQi',
    'id': '1665598080278052864',
    'name': '泰安安境内旅意险（互联网专属）',
    'scene': '网销',
    'categories': ['意外', '团险'],
    'banner': 'https://file.fxyf99.com/product_material/20230607JxXIkV.png',
    'features': ['花少钱，买平安，低至12.57元/天', '旅行意外身故/全残最高赔付50万', '附加旅行期间家庭财产损失'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1665598080278052864',
    'limit': '1天（含）-7天（含）',
    'commissions': [{
        'grade': '1天（含）-7天（含）',
        'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '53' }] }],
    }],
    'companyName': '泰康在线',
}, {
    'channel': 'FengQi',
    'id': '1659476038372601856',
    'name': '太平洋探索之旅旅游意外险（互联网）',
    'scene': '网销',
    'categories': ['意外'],
    'banner': 'https://file.fxyf99.com/product_material/202305194iXWdU.png',
    'features': ['高保额意外伤害', '保障期限灵活可选', '高风险运动保障可附加'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1659476038372601856',
    'limit': '1-30天',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '29.5' }] }] }],
    'companyName': '太平洋财产保险',
}, {
    'channel': 'FengQi',
    'id': '1655547427030908928',
    'name': '太平洋e药保·个人在线问诊药品费用医疗保险（互联网）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20230510I14eDt.png',
    'features': ['覆盖日常疾病，药品快递到家', '线上购药直赔，最低报销70%', '免除健康告知，带病也可投保'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1655547427030908928',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '17.5' }] }] }],
    'companyName': '太平洋财产保险',
}, {
    'channel': 'FengQi',
    'id': '1655450225884209152',
    'name': '太平洋悦惠保·普惠医疗险（互联网）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20230508u1xf23.png',
    'features': ['住院医疗费用最高100万', '特种药品费用最高100万', '健康告知仅一条审核宽松'],
    'coolingOff': '10',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1655450225884209152',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '8.5' }] }] }],
    'companyName': '太平洋财产保险',
}, {
    'channel': 'FengQi',
    'id': '1653976793784258560',
    'name': '同方全球百万医疗保险（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20230504SejczB.png',
    'features': ['责任内最高400万医疗保障', '6年保证续保', '免赔额逐年递减'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1653976793784258560',
    'limit': '70周岁/终身',
    'commissions': [{
        'grade': '',
        'terms': [{
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '30.5' }, { 'term': '2', 'rate': '12' }, { 'term': '3', 'rate': '9' }],
        }],
    }],
    'companyName': '同方全球人寿',
}, {
    'channel': 'FengQi',
    'id': '1648655535832936448',
    'name': '大地保险“畅游神州”旅游意外险（互联网）',
    'scene': '网销',
    'categories': ['意外', '旅游'],
    'banner': 'https://file.fxyf99.com/product_material/20230419oIns50.png',
    'features': ['公共交通意外身故/残疾最高100万', '涵盖滑雪骑马等高风险运动', '保险期间灵活选，按需投保'],
    'coolingOff': '1',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1648655535832936448',
    'limit': '1-60天',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '30.5' }] }] }],
    'companyName': '大地保险',
}, {
    'channel': 'FengQi',
    'id': '1636607435124944896',
    'name': '泰康在线好好效保·肠康卫士（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20230317ZGjR9Z.png',
    'features': ['结直肠癌患者带病投保', '结直肠癌复发、转移保障'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1636607435124944896',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '29.5' }] }] }],
    'companyName': '泰康在线',
}, {
    'channel': 'FengQi',
    'id': '1630898353388470272',
    'name': '大地MSH欣享人生个人医疗保险2023',
    'scene': '经代',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20230301gNHrQa.jpg',
    'features': ['福利全面，方案灵活', '产品成熟，享VIP高端体验', '方便快捷，一站式服务'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1630898353388470272',
    'limit': '1年',
    'commissions': [{
        'grade': '续保',
        'terms': [{ 'name': '1年', 'rates': [{ 'term': '2', 'rate': '13' }] }],
    }, { 'grade': '新保', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '22' }] }] }],
    'companyName': '大地保险',
}, {
    'channel': 'FengQi',
    'id': '1630374380418277376',
    'name': '安盛天平卓越馨选医疗保险（2023版）互联网专属',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20230228UA2XQA.jpg',
    'features': ['免赔额可选，最低0元', '可选海南博鳌乐城特定药品保障', '重疾医疗享全流程增值服务'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1630374380418277376',
    'limit': '1年',
    'commissions': [{
        'grade': '【普通版计划三、四】 【特需版计划一、二、三、四】',
        'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '17.5' }] }],
    }, {
        'grade': '【普通版计划二】',
        'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '13' }] }],
    }, { 'grade': '【普通版计划一】', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '11' }] }] }],
    'companyName': '安盛天平',
}, {
    'channel': 'FengQi',
    'id': '1629036798306357248',
    'name': '太平洋小神童学平险（互联网专属）',
    'scene': '网销',
    'categories': ['意外'],
    'banner': 'https://file.fxyf99.com/product_material/202302247PdMzu.jpg',
    'features': ['3-25周岁学生专属', '意外身故/伤残保障', '意外疾病住院都能赔'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1629036798306357248',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '太平洋财产保险',
}, {
    'channel': 'FengQi',
    'id': '1625311332339675136',
    'name': '君龙臻爱无忧医疗保险（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20230214F6qfQY.jpg',
    'features': ['6年保证续保，贴心保障', '3年无理赔，扩展既往症', '优享全面医疗服务'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1625311332339675136',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '20' }] }] }],
    'companyName': '君龙人寿',
}, {
    'channel': 'FengQi',
    'id': '1620707419536592896',
    'name': '众安尊享e生2023（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20230201CFH0Vr.jpg',
    'features': ['投保年龄扩展至70周岁', '海量保障加油包可选', '投保享健康管理服务'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1620707419536592896',
    'limit': '',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '众安保险',
}, {
    'channel': 'FengQi',
    'id': '1619987178238214144',
    'name': '大地MSH精装旅华个人全球医疗保险',
    'scene': '经代',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20230130Z3rKfs.jpg',
    'features': ['国际保障，保额最高1600万', '责任内最高0免赔，赔付比例100%', '医院可覆盖私立医院、昂贵医院'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1619987178238214144',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '13' }] }] }],
    'companyName': '大地保险',
}, {
    'channel': 'FengQi',
    'id': '1619987006691180544',
    'name': '大地MSH经典个人全球医疗保险计划',
    'scene': '经代',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20230130ESamY4.jpg',
    'features': ['保障可覆盖全球，保额最高1600万', '医疗责任内0免赔，赔付比例100%', '医院可覆盖私立医院、昂贵医院'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1619987006691180544',
    'limit': '1年',
    'commissions': [{
        'grade': '',
        'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '17.5' }, { 'term': '2', 'rate': '13' }] }],
    }],
    'companyName': '大地保险',
}, {
    'channel': 'FengQi',
    'id': '1602227618201640960',
    'name': '永安MSH欣生代少儿医疗保险',
    'scene': '经代',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20221212pBrEAq.png',
    'features': ['便捷的直付医院网络', '贴心、周到的服务'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1602227618201640960',
    'limit': '1年',
    'commissions': [{
        'grade': '',
        'terms': [{
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '17.5' }, { 'term': '2', 'rate': '9' }, { 'term': '3', 'rate': '7' }],
        }],
    }],
    'companyName': '永安保险',
}, {
    'channel': 'FengQi',
    'id': '1595757668455075840',
    'name': '现代财险高速保驾乘意外险(互联网专属)',
    'scene': '网销',
    'categories': ['意外'],
    'banner': 'https://file.fxyf99.com/product_material/20221201jZdeU4.jpg',
    'features': ['高速出行均在保障范围内', '单次通信保费0.1元起', '5座私家车每年累计赔付高达119万'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1595757668455075840',
    'limit': '按次',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '22' }] }] }],
    'companyName': '现代财险',
}, {
    'channel': 'FengQi',
    'id': '1593427684692639744',
    'name': '众安众民保普惠百万医疗险（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20221118sV7f5y.jpg',
    'features': ['高保额覆盖广', '免健康告知', '增值服务'],
    'coolingOff': '2',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1593427684692639744',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '30.5' }] }] }],
    'companyName': '众安保险',
}, {
    'channel': 'FengQi',
    'id': '1590994365270269952',
    'name': '大地MSH精选个人医疗保险',
    'scene': '经代',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20220331a0bbgm.png',
    'features': ['覆盖公立医院和昂贵医院', '可选门诊医疗，普通门诊也能报'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1590994365270269952',
    'limit': '1年',
    'commissions': [{
        'grade': '',
        'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '17.5' }, { 'term': '2', 'rate': '13' }] }],
    }],
    'companyName': '大地保险',
}, {
    'channel': 'FengQi',
    'id': '1588342649473810432',
    'name': '安盛天平守护时光个人意外伤害保险（互联网专属）',
    'scene': '网销',
    'categories': ['意外'],
    'banner': 'https://file.fxyf99.com/product_material/20221104Sp3JEF.jpg',
    'features': ['意外伤害医疗多方位保障', '意外骨折住院津贴', '提供康复指导服务'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1588342649473810432',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '安盛天平',
}, {
    'channel': 'FengQi',
    'id': '1587724533989433344',
    'name': '太平洋蓝医保·长期医疗险（费率可调）（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/202211020c4zwE.jpg',
    'features': ['20年保证续保', '120种重疾医疗保障广', '家庭享费率优惠'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1587724533989433344',
    'limit': '1年',
    'commissions': [{
        'grade': '',
        'terms': [{
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '30.5' }, { 'term': '2', 'rate': '12' }, {
                'term': '3',
                'rate': '9',
            }, { 'term': '4', 'rate': '9' }, { 'term': '5', 'rate': '9' }, { 'term': '6', 'rate': '9' }, {
                'term': '7',
                'rate': '9',
            }, { 'term': '8', 'rate': '9' }, { 'term': '9', 'rate': '9' }, { 'term': '10', 'rate': '9' }],
        }],
    }],
    'companyName': '太平洋财产保险',
}, {
    'channel': 'FengQi',
    'id': '1572476531456536576',
    'name': '京东安联臻爱百万医疗保险专业版',
    'scene': '经代',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/202209219UXI7k.png',
    'features': ['不限社保范围最高100%赔付', '涵盖质子重离子医疗', '重疾绿通增值服务'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1572476531456536576',
    'limit': '一年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '35' }] }] }],
    'companyName': '京东安联',
}, {
    'channel': 'FengQi',
    'id': '1570297042061307904',
    'name': '京东安联申根旅行保障计划',
    'scene': '经代',
    'categories': ['旅游'],
    'banner': 'https://file.fxyf99.com/product_material/20220915DrbnCm.png',
    'features': ['畅行无忧', '提供一站式服务', '全方位保障'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1570297042061307904',
    'limit': '天/年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '30.5' }] }] }],
    'companyName': '京东安联',
}, {
    'channel': 'FengQi',
    'id': '1570296516015894528',
    'name': '京东安联留学保障计划',
    'scene': '经代',
    'categories': ['旅游'],
    'banner': 'https://file.fxyf99.com/product_material/20220915VXYABM.png',
    'features': ['留学中断等意外损失', '个人财物和证件损失', '提供专业的全球24小时紧急医疗救援'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1570296516015894528',
    'limit': '天/年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '30.5' }] }] }],
    'companyName': '京东安联',
}, {
    'channel': 'FengQi',
    'id': '1570240180943138816',
    'name': '京东安联全球高端旅行保障计划',
    'scene': '经代',
    'categories': ['旅游'],
    'banner': 'https://file.fxyf99.com/product_material/202209156P97hw.png',
    'features': ['保额额度最高200万', '覆盖常见风险', '扩展个人财务及三者保障'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1570240180943138816',
    'limit': '天/年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '39.5' }] }] }],
    'companyName': '京东安联',
}, {
    'channel': 'FengQi',
    'id': '1570239546017787904',
    'name': '京东安联纵横神州境内旅行保障计划(升级版)',
    'scene': '经代',
    'categories': ['旅游'],
    'banner': 'https://file.fxyf99.com/product_material/20220915axyatJ.png',
    'features': ['意外或疾病产生的医疗费用', '特含国内旅行延误及行李延误', '国内顶级专业医疗救援服务'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1570239546017787904',
    'limit': '天/年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '30.5' }] }] }],
    'companyName': '京东安联',
}, {
    'channel': 'FengQi',
    'id': '1561543149013774336',
    'name': '京东安联千万航意出行意外保险',
    'scene': '经代',
    'categories': ['意外'],
    'banner': 'https://file.fxyf99.com/product_material/202208226XM254.png',
    'features': ['高保额航空意外和医疗保障', '新冠身故责任', '保障范围全球'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1561543149013774336',
    'limit': '一年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '京东安联',
}, {
    'channel': 'FengQi',
    'id': '1561542407582461952',
    'name': '京东安联-出行无忧综合交通意外保障计划',
    'scene': '经代',
    'categories': ['旅游'],
    'banner': 'https://file.fxyf99.com/product_material/202208229G9bDz.png',
    'features': ['全年保障，价格更优惠', '海陆空公共交通意外伤害及医疗全保障', '提供被保险人自驾车意外伤害保障'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1561542407582461952',
    'limit': '一年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '京东安联',
}, {
    'channel': 'FengQi',
    'id': '1560433918814232576',
    'name': '京东安联-动力无限户外运动保障计划',
    'scene': '经代',
    'categories': ['旅游'],
    'banner': 'https://file.fxyf99.com/product_material/20220819ujXXk8.png',
    'features': ['户外运动专属', '承保高风险运动', '含高原反应保障'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1560433918814232576',
    'limit': '天/年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '京东安联',
}, {
    'channel': 'FengQi',
    'id': '1559456602004574208',
    'name': '安盛天平卓越宝贝少儿意外保险计划（2022版）(互联网专属)',
    'scene': '网销',
    'categories': ['意外'],
    'banner': 'https://file.fxyf99.com/product_material/20220816Ity7YJ.jpg',
    'features': ['意外+医疗多方位保障', '疫苗接种意外保障', '意外骨折定额给付'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1559456602004574208',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '22' }] }] }],
    'companyName': '安盛天平',
}, {
    'channel': 'FengQi',
    'id': '1559095977686986752',
    'name': '京东安联-商旅通商务旅行保障计划',
    'scene': '经代',
    'categories': ['旅游'],
    'banner': 'https://file.fxyf99.com/product_material/20220815mx86CL.png',
    'features': ['意外、医疗保障', '旅行延误补偿', '高尔夫球具保障'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1559095977686986752',
    'limit': '天/年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '30.5' }] }] }],
    'companyName': '京东安联',
}, {
    'channel': 'FengQi',
    'id': '1554279156144906240',
    'name': '平安e生保互联网医疗2022保险产品组合',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20211227GT1G7H.jpg',
    'features': ['投保年龄宽泛', '家庭投保有优惠', '含CAR-T疗法药品', '增值服务更多样'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1554279156144906240',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '17.5' }] }] }],
    'companyName': '中国平安',
}, {
    'channel': 'FengQi',
    'id': '1554279067766726656',
    'name': '平安e生保·互联网长期医疗保险产品组合',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20211122Q3axHZ.jpg',
    'features': ['超高性价比，0.5元/天起', '保障更持久，20年保证续保期间', '最高800万保额'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1554279067766726656',
    'limit': '1年',
    'commissions': [{
        'grade': '',
        'terms': [{
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '26' }, { 'term': '2', 'rate': '12' }, { 'term': '3', 'rate': '6' }],
        }],
    }],
    'companyName': '中国平安',
}, {
    'channel': 'FengQi',
    'id': '1552828256239550464',
    'name': '安诚未来之星学平险（互联网专属）',
    'scene': '网销',
    'categories': ['意外'],
    'banner': 'https://file.fxyf99.com/product_material/20220729l2SDLr.jpg',
    'features': ['7-25周岁学生专属', '涵盖意外和疾病保障', '含住院津贴'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1552828256239550464',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '28' }] }] }],
    'companyName': '安诚财险',
}, {
    'channel': 'FengQi',
    'id': '1552494526521520128',
    'name': '安联人寿安行万里全球旅行保险计划（互联网专属）',
    'scene': '网销',
    'categories': ['旅游'],
    'banner': 'https://file.fxyf99.com/product_material/20220728jp6yc7.jpg',
    'features': ['会员尊享旅行增值服务', '意外、急性病皆可保', '住院医疗不用愁'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1552494526521520128',
    'limit': '按天/年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '30.5' }] }] }],
    'companyName': '安联人寿',
}, {
    'channel': 'FengQi',
    'id': '1552494082869014528',
    'name': '安联人寿安行万里欧洲旅行保险计划（互联网专属）',
    'scene': '网销',
    'categories': ['旅游'],
    'banner': 'https://file.fxyf99.com/product_material/20220728ISWsjC.png',
    'features': ['会员尊享旅行增值服务', '意外、急性病皆可保', '住院医疗不用愁'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1552494082869014528',
    'limit': '按天/年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '30.5' }] }] }],
    'companyName': '安联人寿',
}, {
    'channel': 'FengQi',
    'id': '1552471769410809856',
    'name': '安联人寿安行万里亚太旅行保险计划（互联网专属）',
    'scene': '网销',
    'categories': ['旅游'],
    'banner': 'https://file.fxyf99.com/product_material/20220728qwxt7W.jpg',
    'features': ['会员尊享旅行增值服务', '意外、急性病皆可保', '住院医疗不用愁'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1552471769410809856',
    'limit': '天/年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '30.5' }] }] }],
    'companyName': '安联人寿',
}, {
    'channel': 'FengQi',
    'id': '1547864795304099840',
    'name': '华泰户外运动意外险（互联网专属）',
    'scene': '网销',
    'categories': ['意外', '旅游'],
    'banner': 'https://file.fxyf99.com/product_material/20220715K1mNhV.jpg',
    'features': ['意外医疗全保障', '1-70周岁可保', '全家出游都有保障', '可按天承保，投保灵活', '价格便宜'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1547864795304099840',
    'limit': '按天/按年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '30.5' }] }] }],
    'companyName': '华泰保险',
}, {
    'channel': 'FengQi',
    'id': '1547864380843950080',
    'name': '华泰高风险职业意外险（互联网专属）',
    'scene': '网销',
    'categories': ['意外'],
    'banner': 'https://file.fxyf99.com/product_material/20220715Nm6AoE.jpg',
    'features': ['投保职业类别广泛', '对高风险人群友好', '包含住院津贴'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1547864380843950080',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '22' }] }] }],
    'companyName': '华泰保险',
}, {
    'channel': 'FengQi',
    'id': '1547066750794534912',
    'name': '亚太“肝享保”个人癌症百万医疗保险（互联网专属）',
    'scene': '网销',
    'categories': ['医疗', '特病'],
    'banner': 'https://file.fxyf99.com/online_training/20211021Jl0MnN.jpg',
    'features': ['肝病患者专属防癌医疗险', '最高400万医疗保障', '0免赔，不限社保目录'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1547066750794534912',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '22' }] }] }],
    'companyName': '亚太财险',
}, {
    'channel': 'FengQi',
    'id': '1544931966005747712',
    'name': '众安千万家财险（互联网专属）',
    'scene': '网销',
    'categories': ['家财'],
    'banner': 'https://file.fxyf99.com/product_material/20220707VpAqRZ.jpg',
    'features': ['房屋、室内财产全都保', '管道破裂租房补贴全都有', '居家服务有保障'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1544931966005747712',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '35' }] }] }],
    'companyName': '众安保险',
}, {
    'channel': 'FengQi',
    'id': '1543767213864251392',
    'name': '泰康在线医小康百万医疗险',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20220704pyvzJN.jpg',
    'features': ['非标体可以加费承保 ', '投保年龄最高70岁', '最高600万医疗保障', '多项增值保障'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1543767213864251392',
    'limit': '1年',
    'commissions': [{
        'grade': '续保家庭单',
        'terms': [{ 'name': '1年', 'rates': [{ 'term': '2', 'rate': '26' }] }],
    }, { 'grade': '续保', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '2', 'rate': '29.5' }] }] }, {
        'grade': '新保',
        'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '29.5' }] }],
    }],
    'companyName': '泰康在线',
}, {
    'channel': 'FengQi',
    'id': '1542759811097223168',
    'name': '人保统护卫（互联网专属）',
    'scene': '网销',
    'categories': ['意外', '团险'],
    'banner': 'https://file.fxyf99.com/product_material/202207017FFSfP.png',
    'features': ['一般意外身故/伤残最高120万', '意外医疗最高0免赔100%报销', '海陆空交通工具/自乘自驾额外赔'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1542759811097223168',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '23' }] }] }],
    'companyName': '中国人保',
}, {
    'channel': 'FengQi',
    'id': '1542349648183750656',
    'name': '京东安联住院保（专业版）',
    'scene': '经代',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20220630DRF2A8.jpg',
    'features': ['意外、住院双保障', '高额意外身故/伤残保障', '成人儿童都可买'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1542349648183750656',
    'limit': '一年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '13' }] }] }],
    'companyName': '京东安联',
}, {
    'channel': 'FengQi',
    'id': '1542065420439568384',
    'name': '京东安联京彩一生防癌医疗保险',
    'scene': '经代',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20220629mUnAMu.jpg',
    'features': ['性价比高健康告知少', '可续保至102周岁', '缴费灵活投保范围广'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1542065420439568384',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '22' }] }] }],
    'companyName': '京东安联',
}, {
    'channel': 'FengQi',
    'id': '1541989721611616256',
    'name': '京东安联小幸孕母婴保障计划',
    'scene': '经代',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20220629ydvF9E.jpg',
    'features': ['保障全面、选择灵活 ', '贴心呵护、双重保障', '新生儿疾病保障'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1541989721611616256',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '17.5' }] }] }],
    'companyName': '京东安联',
}, {
    'channel': 'FengQi',
    'id': '1541593701225017344',
    'name': '众安健康宝贝少儿综合保障（互联网专属）',
    'scene': '网销',
    'categories': ['意外'],
    'banner': 'https://file.fxyf99.com/product_material/20220628pjNmeH.jpg',
    'features': ['门诊/住院不限疾病或意外', '住院0免赔最高100%比例报销', '特定疾病+疫苗接种意外保障'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1541593701225017344',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '22' }] }] }],
    'companyName': '众安保险',
}, {
    'channel': 'FengQi',
    'id': '1539421577873985536',
    'name': '复星联合超越保医疗保险（2022版）',
    'scene': '经代',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20220622OtATW1.jpg',
    'features': ['最高400万，6年保证续保', '110种特疾医疗0免赔', '可报销人工肺、ICU费用'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1539421577873985536',
    'limit': '1年',
    'commissions': [{
        'grade': '【北京、广东、深圳】',
        'terms': [{
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '44' }, { 'term': '2', 'rate': '15' }, { 'term': '3', 'rate': '3' }],
        }],
    }],
    'companyName': '复星联合',
}, {
    'channel': 'FengQi',
    'id': '1536265930033655808',
    'name': '大地MSH全球孕产医疗保险',
    'scene': '经代',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20220613Poaksb.png',
    'features': ['保障全面、选择灵活 ', '贴心呵护、多重保障'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1536265930033655808',
    'limit': '1年',
    'commissions': [{
        'grade': '',
        'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '17.5' }, { 'term': '2', 'rate': '13' }] }],
    }],
    'companyName': '大地保险',
}, {
    'channel': 'FengQi',
    'id': '1531522768911634432',
    'name': '富德生命爱相伴百万医疗保险计划',
    'scene': '经代',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20220531ubIkLG.png',
    'features': ['最高400万保障不限社保范围', '全家老小都能保', '6年保证续保'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1531522768911634432',
    'limit': '1年',
    'commissions': [{
        'grade': '',
        'terms': [{
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '22' }, { 'term': '2', 'rate': '9' }, { 'term': '3', 'rate': '6' }],
        }],
    }],
    'companyName': '富德生命',
}, {
    'channel': 'FengQi',
    'id': '1529364895364452352',
    'name': '众惠爱牙保障计划（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20220525hKVxHZ.jpg',
    'features': ['投保宽松，大人小孩都能买 ', '保障全面，治疗保健都包含 ', '理赔迅速，实时理赔不用等'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1529364895364452352',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '22' }] }] }],
    'companyName': '众惠相互',
}, {
    'channel': 'FengQi',
    'id': '1528663152495091712',
    'name': '众惠惠享e生百万医疗保险2022（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20220523hbXoUL.jpg',
    'features': ['8大特色保障，大病小病都包含', '5大可选责任，更多就医选择', '5大优质增值服务，就医更省心'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1528663152495091712',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '29.5' }] }] }],
    'companyName': '众惠相互',
}, {
    'channel': 'FengQi',
    'id': '1528641921318313984',
    'name': '安联至臻互联网医疗保险',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/202205237MnpFQ.jpg',
    'features': ['最高400万医疗保障', '100种特定疾病0免赔', '不限社保范围最高100%报销'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1528641921318313984',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '30.5' }] }] }],
    'companyName': '安联人寿',
}, {
    'channel': 'FengQi',
    'id': '1527588660004048896',
    'name': '亚太“肺无忧”肺部恶性肿瘤（重度）医疗保险',
    'scene': '经代',
    'categories': ['医疗', '特病'],
    'banner': 'https://file.fxyf99.com/product_material/20220520VILuFQ.jpg',
    'features': ['肺结节/肺部手术史患者也能投', '门急诊/住院费用0免赔100%报销', '多项增值服务实用更贴心'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1527588660004048896',
    'limit': '1年',
    'commissions': [{
        'grade': '',
        'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '30.5' }] }, {
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '30.5' }],
        }],
    }],
    'companyName': '亚太财险',
}, {
    'channel': 'FengQi',
    'id': '1522124194670374912',
    'name': '众安神盾住院保2022（互联网）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20220505BPQ5gM.jpg',
    'features': ['出生满30天-65岁可投', '意外疾病均可保', '赠送多项增值服务'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1522124194670374912',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '20' }] }] }],
    'companyName': '众安保险',
}, {
    'channel': 'FengQi',
    'id': '1522113627972890624',
    'name': '众安尊享e生2022版（互联网）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20220505uBAPW4.jpg',
    'features': ['投保年龄扩展至70周岁', '海量保障加油包可选', '赠送多项增值服务'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1522113627972890624',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '众安保险',
}, {
    'channel': 'FengQi',
    'id': '1518891773510479872',
    'name': '安盛天平卓越守护百万住院医疗保险（2022升级版）（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/202204267fkDd0.jpg',
    'features': ['最高750万保障，保障100种重疾', '承保年龄为出生满28天至65周岁', '家庭共享免赔额'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1518891773510479872',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '安盛天平',
}, {
    'channel': 'FengQi',
    'id': '1510151821921218560',
    'name': '太平洋疫苗无忧保',
    'scene': '经代',
    'categories': ['意外'],
    'banner': 'https://file.fxyf99.com/product_material/20220402ZVNVul.jpg',
    'features': ['涵盖疫苗接种身故/残疾医疗保障', '每天不到2毛钱起，接种无忧', '不限职业，30天-65周岁均可投保'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1510151821921218560',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '29.5' }] }] }],
    'companyName': '太平洋财产保险',
}, {
    'channel': 'FengQi',
    'id': '1506460297317158912',
    'name': '横琴恶性肿瘤（重度）特药费用医疗保险（互联网）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20220323dS2k76.jpg',
    'features': ['保额高达300万', '涵盖106款社保内外抗癌特药', '可药店直购，送药上门'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1506460297317158912',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '10.5' }] }] }],
    'companyName': '横琴人寿',
}, {
    'channel': 'FengQi',
    'id': '1506114006329147392',
    'name': '安联萌宝贝少儿意外险（互联网专属）',
    'scene': '网销',
    'categories': ['意外'],
    'banner': 'https://file.fxyf99.com/product_material/20220322RBMkSs.png',
    'features': ['意外身故及伤残10万保额', '自驾车意外5万保额', '少儿预防接种疫苗保障'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1506114006329147392',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '22' }] }] }],
    'companyName': '安联人寿',
}, {
    'channel': 'FengQi',
    'id': '1500706561802698752',
    'name': '泰康泰享年年互联网医疗保险（费率可调）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20210406/90d7c08dfc194363b8073b21ba07f756.jpg',
    'features': ['最高400万年度保额', '保障续保20年，理赔无忧'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1500706561802698752',
    'limit': '1年',
    'commissions': [{
        'grade': '',
        'terms': [{
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '30.5' }, { 'term': '2', 'rate': '18' }, { 'term': '3', 'rate': '18' }],
        }],
    }],
    'companyName': '泰康人寿',
}, {
    'channel': 'FengQi',
    'id': '1495650151965298688',
    'name': '泰康泰爱保2022（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20220221LSfafb.jpg',
    'features': ['600万高额医疗保障', '特定疾病/恶性肿瘤0免赔', '不限社保最高100%比例赔偿'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1495650151965298688',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '29.5' }] }] }],
    'companyName': '泰康在线',
}, {
    'channel': 'FengQi',
    'id': '1492060750045765632',
    'name': '众惠相互小叮当百万医疗险（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20220211cNMC9L.jpg',
    'features': ['最高600万保障不限社保范围', '免赔额可递减，重疾0免赔', '300万肿瘤特药保障'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1492060750045765632',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '39.5' }] }] }],
    'companyName': '众惠相互',
}, {
    'channel': 'FengQi',
    'id': '1484479650974576640',
    'name': '华保健康癌症医疗险（互联网专属）（尊享版）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20220124u7AQEe.jpg',
    'features': ['医疗费用报销最高230万', '不限社保范围最高100%比例报销', '三高等亚健康人群可投保'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1484479650974576640',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '华农保险',
}, {
    'channel': 'FengQi',
    'id': '1484479211499597824',
    'name': '华保健康癌症医疗险（互联网专属）（基础版）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20220124UNWRrA.jpg',
    'features': ['医疗费用报销最高200万', '不限社保范围最高100%比例报销', '三高等亚健康人群可投保'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1484479211499597824',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '13' }] }] }],
    'companyName': '华农保险',
}, {
    'channel': 'FengQi',
    'id': '1483322167997931520',
    'name': '众安住院保2022（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/202201201QYf5F.jpg',
    'features': ['意外身故/伤残给付10万', '意外/疾病住院医疗最高1万', '儿童成人都可买'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1483322167997931520',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '30.5' }] }] }],
    'companyName': '众安保险',
}, {
    'channel': 'FengQi',
    'id': '1480785683019014144',
    'name': '太平洋任我行驾乘意外险（互联网专属）',
    'scene': '网销',
    'categories': ['意外'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20201124/02643682c4bd4e2a8f20b6eb1ab3127b.jpg',
    'features': ['9座及以下车辆可保', '保障随车不随人', '兼具医疗保障'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1480785683019014144',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '21' }] }] }],
    'companyName': '太平洋财产保险',
}, {
    'channel': 'FengQi',
    'id': '1480785636197998592',
    'name': '太平洋爱守护境内旅游险（互联网专属）',
    'scene': '网销',
    'categories': ['旅游'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20201119/6e52a6ad3e3f470fb56f48605aa638f0.jpg',
    'features': ['医疗、财务、意外全保障', '1-80周岁可投保', '可按天承保'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1480785636197998592',
    'limit': '1天-1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '17.5' }] }] }],
    'companyName': '太平洋财产保险',
}, {
    'channel': 'FengQi',
    'id': '1480785577762955264',
    'name': '太平洋夕阳红老年意外险（互联网专属）',
    'scene': '网销',
    'categories': ['意外'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20201012/042edf3c20df42448376216504be2dea.jpg',
    'features': ['意外身故/伤残最高20万', '意外医疗+意外住院津贴', '骨折、救护车补贴'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1480785577762955264',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '17.5' }] }] }],
    'companyName': '太平洋财产保险',
}, {
    'channel': 'FengQi',
    'id': '1480731690775420928',
    'name': '瑞华医保加个人医疗保险（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20220111zc7dKq.jpg',
    'features': ['最高400万医疗保障', '110种重疾、罕见病、肺结节0免赔', '肿瘤特药及罕见病药品服务'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1480731690775420928',
    'limit': '1年',
    'commissions': [{
        'grade': '',
        'terms': [{
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '30.5' }, { 'term': '2', 'rate': '14.5' }, { 'term': '3', 'rate': '14.5' }],
        }],
    }],
    'companyName': '瑞华保险',
}, {
    'channel': 'FengQi',
    'id': '1479371673426264064',
    'name': '华泰中老年意外险（互联网专属）',
    'scene': '网销',
    'categories': ['意外'],
    'banner': 'https://file.fxyf99.com/product_material/20220107MdkOoB.jpg',
    'features': ['45-85周岁中老年专属', '意外身故/伤残保障', '意外骨折额外赔'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1479371673426264064',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '华泰保险',
}, {
    'channel': 'FengQi',
    'id': '1478180502813978624',
    'name': '平安健康乳易保·乳腺恶性肿瘤复发转移疾病险',
    'scene': '经代',
    'categories': ['医疗', '特病'],
    'banner': 'https://file.fxyf99.com/product_material/20211207esMCKm.jpg',
    'features': ['乳腺癌患者专享', '最高50万疾病保障', '高价值健康管理服务'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1478180502813978624',
    'limit': '1年',
    'commissions': [{
        'grade': '',
        'terms': [{
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '30.5' }, { 'term': '2', 'rate': '15' }, { 'term': '3', 'rate': '6' }],
        }],
    }],
    'companyName': '中国平安',
}, {
    'channel': 'FengQi',
    'id': '1478180442038513664',
    'name': '平安终身防癌医疗险（费率可调）',
    'scene': '经代',
    'categories': ['医疗', '特病'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20210430/a37cd4efe8e9462c84e8413560811dff.png',
    'features': ['保证续航一辈子', '0-70周岁可保', '三高人群可保'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1478180442038513664',
    'limit': '1年',
    'commissions': [{
        'grade': '',
        'terms': [{
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '26' }, { 'term': '2', 'rate': '12' }, { 'term': '3', 'rate': '6' }],
        }],
    }],
    'companyName': '中国平安',
}, {
    'channel': 'FengQi',
    'id': '1478180346237476864',
    'name': '平安e生保(特定慢病版)',
    'scene': '经代',
    'categories': ['医疗', '特病'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20210430/9d87701156ae4858a7d175ed4c53af4d.png',
    'features': ['特定亚健康、慢性病人群也能投保', '最高报销500万医疗费', '投保前已有特定慢性病既往症也能保'],
    'coolingOff': '10',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1478180346237476864',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '17.5' }] }] }],
    'companyName': '中国平安',
}, {
    'channel': 'FengQi',
    'id': '1478180177324466176',
    'name': '平安e生保长期医疗（费率可调）保险产品组合',
    'scene': '经代',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20211122Q3axHZ.jpg',
    'features': ['最高800万总限额', '20年保证续保', '投保费率优惠多'],
    'coolingOff': '10',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1478180177324466176',
    'limit': '1年',
    'commissions': [{
        'grade': '',
        'terms': [{
            'name': '1年',
            'rates': [{ 'term': '1', 'rate': '26' }, { 'term': '2', 'rate': '12' }, { 'term': '3', 'rate': '6' }],
        }],
    }],
    'companyName': '中国平安',
}, {
    'channel': 'FengQi',
    'id': '1478179879540404224',
    'name': '平安e生保2020',
    'scene': '经代',
    'categories': ['医疗'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20200114/20200114134712235.png',
    'features': ['保障范围更广', '质子重离子可选', '可出国治疗', '出国治疗全程服务'],
    'coolingOff': '10',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1478179879540404224',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '17.5' }] }] }],
    'companyName': '中国平安',
}, {
    'channel': 'FengQi',
    'id': '1476849860691066880',
    'name': '安盛旅行保意外保障计划（互联网专属）',
    'scene': '网销',
    'categories': ['意外'],
    'banner': 'https://static.wts999.com//FILE/20200610/20200610101357914.jpg',
    'features': ['海陆空意外全覆盖', '意外医疗+急性病医疗/身故保障', '保费低至5元'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1476849860691066880',
    'limit': '1-25天',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '安盛天平',
}, {
    'channel': 'FengQi',
    'id': '1476825304162574336',
    'name': '安盛天平盛世守护珍爱版健康保险（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20210908/852e297a63dc4614bf1ff6f03a2f4a3e.png',
    'features': ['多重风险全面抵御', '0免赔，贴心赔付', '赠送多项增值服务'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1476825304162574336',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '22' }] }] }],
    'companyName': '安盛天平',
}, {
    'channel': 'FengQi',
    'id': '1476825155570032640',
    'name': '安盛天平盛世臻选医疗保险（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/202111247fC3xy.png',
    'features': ['覆盖公立医院和昂贵私立医院', '可选门诊医疗，普通门诊也能报', '就诊全旅程健康服务+增值服务'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1476825155570032640',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '15.5' }] }] }],
    'companyName': '安盛天平',
}, {
    'channel': 'FengQi',
    'id': '1476825041279442944',
    'name': '安盛天平卓越守护慢性病专款（高血压版）互联网专属',
    'scene': '网销',
    'categories': ['医疗', '特病'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20191209/20191209172936688.jpg',
    'features': ['高血压患者可投', '报销不限社保范围', '投保范围广'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1476825041279442944',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '17.5' }] }] }],
    'companyName': '安盛天平',
}, {
    'channel': 'FengQi',
    'id': '1476824925924433920',
    'name': '安盛天平慢病医疗险（糖尿病版）互联网专属',
    'scene': '网销',
    'categories': ['医疗', '特病'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20191209/20191209164556884.jpg',
    'features': ['糖尿病患者可投', '报销不限社保范围', '投保范围较广'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1476824925924433920',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '17.5' }] }] }],
    'companyName': '安盛天平',
}, {
    'channel': 'FengQi',
    'id': '1476824770025414656',
    'name': '安盛天平卓越无忧百万防癌医疗（互联网专属）',
    'scene': '网销',
    'categories': ['医疗', '特病'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20210508/20210508110105866.jpg',
    'features': ['最高200万高额保障', '出生满28天至80周岁可投保', '质子重离子医疗可共享保额'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1476824770025414656',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '安盛天平',
}, {
    'channel': 'FengQi',
    'id': '1476797803829977088',
    'name': '众惠相互普惠版·全民医疗险（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20200703/1b8fd845e923452ea987c69a0a938ca2.jpg',
    'features': ['核保宽松，健康告知仅1条', '糖尿病、三高等都可投保', '住院安排、手术安排、专家门诊'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1476797803829977088',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '29.5' }] }] }],
    'companyName': '众惠相互',
}, {
    'channel': 'FengQi',
    'id': '1476797727775596544',
    'name': '众惠相互惠享e生百万医疗险（互联网专属）（三高版）',
    'scene': '网销',
    'categories': ['医疗', '特病'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20191025/20191025155436964.jpg',
    'features': ['三高及乙肝携带均有机会投保', '不限社保最高100%比例报销', '两档免赔额可选按需投保'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1476797727775596544',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '29.5' }] }] }],
    'companyName': '众惠相互',
}, {
    'channel': 'FengQi',
    'id': '1476797615829622784',
    'name': '众惠相互惠享e生百万医疗险（互联网专属）（三高肝病版）',
    'scene': '网销',
    'categories': ['医疗', '特病'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20210830/3eb0fe2cc5a346dd8910555651dbc33c.jpg',
    'features': ['三高及慢性肝病均有机会投保', '不限社保最高90%比例报销', '享有多项贴心增值服务'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1476797615829622784',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '众惠相互',
}, {
    'channel': 'FengQi',
    'id': '1476797545801523200',
    'name': '众惠相互惠享e生百万医疗险（互联网专属）（三高肾病版）',
    'scene': '网销',
    'categories': ['医疗', '特病'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20210830/b7344fce80524e118d81503c067c4281.jpg',
    'features': ['三高及慢性肝病均有机会投保', '不限社保最高90%比例报销', '享有多项贴心增值服务'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1476797545801523200',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '众惠相互',
}, {
    'channel': 'FengQi',
    'id': '1476391197299933184',
    'name': '众安尊享e生2021版（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20210117/beda90f7321c4d95b491c8b818e2ab61.jpg',
    'features': ['投保年龄扩展至70周岁', '海量保障加油包可选', '赠送多项增值服务'],
    'coolingOff': '2',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1476391197299933184',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '众安保险',
}, {
    'channel': 'FengQi',
    'id': '1476366702770094080',
    'name': '海保人寿互联网黄金甲医疗保险',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20210622/20210622140145575.jpg',
    'features': ['最高600万医疗保障', '一般医疗+重疾医疗400万保障', '含跨境医疗保障'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1476366702770094080',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '海保人寿',
}, {
    'channel': 'FengQi',
    'id': '1476366495793774592',
    'name': '华泰恶性肿瘤特药保险（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20191212/20191212105215100.jpg',
    'features': ['保障已上市抗癌特药', '200万保额，0免赔', '价格便宜'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1476366495793774592',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '华泰保险',
}, {
    'channel': 'FengQi',
    'id': '1476366374468190208',
    'name': '华泰中老年医疗费用保险（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20200318/20200318142134410.jpg',
    'features': ['80岁高龄可投保', '免费体检', '优选体升级保障'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1476366374468190208',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '华泰保险',
}, {
    'channel': 'FengQi',
    'id': '1476363173804343296',
    'name': '华泰住院保（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20191213/20191213172213470.jpg',
    'features': ['意外住院双保障', '高额意外身故伤残保障', '成人儿童都可买'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1476363173804343296',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '30.5' }] }] }],
    'companyName': '华泰保险',
}, {
    'channel': 'FengQi',
    'id': '1476363050168844288',
    'name': '华泰百万医疗险（互联网专属）',
    'scene': '网销',
    'categories': ['医疗'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20191213/20191213141338579.jpg',
    'features': ['最高600万保额', '赔付比例最高100%', '100种重疾0免赔'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1476363050168844288',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '26' }] }] }],
    'companyName': '华泰保险',
}, {
    'channel': 'FengQi',
    'id': '1475349431511752704',
    'name': '平安e生保·百万医疗2022升级版',
    'scene': '经代',
    'categories': ['医疗'],
    'banner': 'https://file.fxyf99.com/product_material/20211227GT1G7H.jpg',
    'features': ['28天-65岁可投，享家庭优惠费率', '含105种院外特药和质重医疗保险金', '住院就医安排，诊后出院交通安排及陪护、上门康护'],
    'coolingOff': '15',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1475349431511752704',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '17.5' }] }] }],
    'companyName': '中国平安',
}, {
    'channel': 'FengQi',
    'id': '1328591822884179968',
    'name': '京东安联臻爱无限医疗保险2021版',
    'scene': '经代',
    'categories': ['医疗'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20201117/c5bb95ac0fc54e57bc321e9125409741.jpg',
    'features': ['可家庭投保', '无理赔享优惠', '承保特定既往病症'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1328591822884179968',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '35' }] }] }],
    'companyName': '京东安联',
}, {
    'channel': 'FengQi',
    'id': '1323534035156807680',
    'name': '太平洋宠物犬主责任险',
    'scene': '网销',
    'categories': ['意外'],
    'banner': 'https://www.fxyf99.com/upload/FILE/20201103/6e7e4c43f979461597628975767a1c7e.jpg',
    'features': ['保费最低30元；', '3种保障方案可选；', '人身伤亡最高赔偿10万元'],
    'coolingOff': '0',
    'url': 'https://www.fxyf99.com/product/detail?c=bz0230730271&id=1323534035156807680',
    'limit': '1年',
    'commissions': [{ 'grade': '', 'terms': [{ 'name': '1年', 'rates': [{ 'term': '1', 'rate': '17.5' }] }] }],
    'companyName': '太平洋财产保险',
}];

const filter = atom({
    key: 'ops.products.filter',
    default: {
        words: '',
    }
});

export const useOpsProductsFilterState = () => useRecoilState(filter);
export const useOpsProductsFilterValue = () => useRecoilValue(filter);
export const useSetOpsProductsFilter = () => useSetRecoilState(filter);

const pageable = atom({
    key: 'ops.products.pageable',
    default: {
        page: 1,
        size: 10,
    },
});

export const useOpsProductsPageableState = () => useRecoilState(pageable);
export const useOpsProductsPageableValue = () => useRecoilValue(pageable);
export const useSetOpsProductsPageable = () => useSetRecoilState(pageable);

const useOpsProductsValue = () => {
    const { words } = useOpsProductsFilterValue();
    return useMemo(() => {
        const keywords = words.toLowerCase().trim();
        return products.filter(({ name, companyName }) => (
            name.toLowerCase().indexOf(keywords) >= 0
            || companyName.toLowerCase().indexOf(keywords) >= 0
        ));
    }, [words]);
};

export const useOpsProductsTotalValue = () => {
    const products = useOpsProductsValue();
    return useMemo(() => products.length, [products]);
};

export const useOpsProductsPagingValue = () => {
    const { page, size } = useOpsProductsPageableValue();
    const products = useOpsProductsValue();
    return useMemo(() => products.slice((page - 1) * size, page * size).map((product) => ({
        ...product,
        key: product.id,
    })), [page, size, products]);
};
