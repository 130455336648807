import { Table } from 'antd';
import React from 'react';

const columns = [{
    key: 'id',
    dataIndex: 'id',
    title: '订单号',
}, {
    key: 'name',
    dataIndex: 'name',
    title: '商品名称',
}, {
    key: 'createdAt',
    dataIndex: 'createdAt',
    title: '投保时间',
}, {
    key: 'amount',
    dataIndex: 'amount',
    title: '保额',
}, {
    key: 'income',
    dataIndex: 'income',
    title: '收益',
}, {
    key: 'customer',
    dataIndex: 'customer',
    title: '投/被保人',
}, {
    key: 'status',
    dataIndex: 'status',
    title: '状态',
}, {
    key: 'action',
    dataIndex: 'action',
    title: '操作',
}];

const data = [{
    'createdAt': '2023-09-21 14:59:13',
    'amount': '439.00',
    'id': '650be89d512d2e0e2a899cd7',
    'customer': '丛原',
    'incomes': '215.11',
    'name': '华贵麦兜兜少儿重大疾病保险（互联网专属）',
    'status': '保障中',
}, {
    'createdAt': '2023-08-02 11:28:53',
    'amount': '100.00',
    'id': '64c9cd76b325a34b6f08940c',
    'customer': '谢静威',
    'incomes': '0.00',
    'name': '富德生命富贵管家B款年金保险（万能型）',
    'status': '保障中',
}, {
    'createdAt': '2023-07-31 23:22:58',
    'amount': '40000.00',
    'id': '64c7d09f0296fd647edc26f9',
    'customer': '黄淑瑶',
    'incomes': '8000.00',
    'name': '弘康金玉满堂（旗舰版）终身寿险',
    'status': '保障中',
}, {
    'createdAt': '2023-07-31 23:18:04',
    'amount': '30000.00',
    'id': '64c7cece6f02491b738de9eb',
    'customer': '李影影',
    'incomes': '12600.00',
    'name': '弘康金玉满堂（旗舰版）终身寿险',
    'status': '保障中',
}, {
    'createdAt': '2023-07-31 23:05:28',
    'amount': '40000.00',
    'id': '64c7ccb89ced40546fdb53ad',
    'customer': '黄淑瑶',
    'incomes': '8000.00',
    'name': '弘康金玉满堂（旗舰版）终身寿险',
    'status': '保障中',
}, {
    'createdAt': '2023-07-31 23:02:50',
    'amount': '30000.00',
    'id': '64c7c9f60296fd647edc25d0',
    'customer': '郭丽丹',
}, {
    'createdAt': '2023-07-31 22:56:54',
    'amount': '40000.00',
    'id': '64c7c9090296fd647edc25b4',
    'customer': '黄淑瑶',
}, {
    'createdAt': '2023-07-31 22:46:16',
    'amount': '50000.00',
    'id': '64c7c77d9ced40546fdb52b3',
    'customer': '许晶',
    'incomes': '38750.00',
    'name': '弘康金玉满堂（旗舰版）终身寿险',
    'status': '保障中',
}, {
    'createdAt': '2023-07-31 22:16:09',
    'amount': '3320.00',
    'id': '64c7bec89ced40546fdb5115',
    'customer': '侯大龙',
}];

export default () => {
    return (
        <Table style={{ width: '100%' }} columns={columns} dataSource={data} />
    );
};
