import { App, ConfigProvider } from 'antd';
import { ThemeProvider } from 'antd-style';
import 'antd/dist/reset.css';
import zhCN from 'antd/locale/zh_CN';
import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';

dayjs.locale('zh-cn');

export default ({ children }) => (
    <App>
        <ThemeProvider themeMode="auto">
            <ConfigProvider locale={zhCN}>
                {children}
            </ConfigProvider>
        </ThemeProvider>
    </App>
);
