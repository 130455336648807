import { LockOutlined, LockTwoTone, MailOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Card, Form, Input, message, Tooltip } from 'antd';
import { createStyles, css } from 'antd-style';
import { useCallback } from 'react';
import { useSignIn } from 'snapshots/session';

const useStyles = createStyles({
    signIn: css`
      width: 25rem;

      .ant-input-prefix {
        color: rgba(0, 0, 0, 0.25);
      }
    `,
    action: css`
      font-size: 1.25rem;
    `,
});

export default () => {
    const { styles } = useStyles();
    const [messageBox, context] = message.useMessage();
    const login = useSignIn();
    const onFinish = useCallback(async (variables) => {
        try {
            await login(variables);
        } catch (exception) {
            messageBox.error(exception.message, 3);
        }
    }, [login, messageBox]);

    return (
        <Card className={styles.signIn}
              title="登入信息"
              actions={[
                  <Tooltip title="使用密码登入">
                      <LockTwoTone className={styles.action} />
                  </Tooltip>,
                  <Tooltip title="短信验证码方式暂不支持">
                      <MailOutlined className={styles.action} />
                  </Tooltip>,
              ]}
        >
            {context}
            <Form layout="vertical"
                  onFinish={onFinish}
            >
                <Form.Item
                    name="principal"
                    label="手机号码"
                    required
                    rules={[{
                        required: true,
                        message: '请输入11位手机号码',
                    }, {
                        type: 'string',
                        pattern: /^1\d{10}$/,
                        message: '请输入1开头的11位手机号码',
                    }]}
                >
                    <Input prefix={<UserOutlined />}
                           placeholder="请输入11位手机号码" />
                </Form.Item>
                <Form.Item
                    name="credentials"
                    label="密码"
                    required
                    rules={[{
                        required: true,
                        message: '请输入您的密码',
                    }]}
                >
                    <Input.Password prefix={<LockOutlined />}
                                    placeholder="请输入密码" />
                </Form.Item>
                <Form.Item>
                    <Button
                        type="primary"
                        htmlType="submit"
                        size="large"
                        block
                    >
                        登入
                    </Button>
                </Form.Item>
            </Form>
        </Card>
    );
};
