import { IconId, IconPhone } from '@tabler/icons-react';
import { App, Form, Input, Modal } from 'antd';
import { useCallback } from 'react';
import { useDialogProfileVisible, useDialogProfileVisibleValue, useSetProfileName } from 'snapshots/profile';
import { useShellAccountValue, useShellPhoneValue } from 'snapshots/shell';

export default () => {
    const [form] = Form.useForm();
    const visible = useDialogProfileVisibleValue();
    const { name } = useShellAccountValue();
    const phone = useShellPhoneValue();
    const { hide } = useDialogProfileVisible();
    const updateName = useSetProfileName();
    const { message } = App.useApp();
    const onOK = useCallback(async () => {
        try {
            const { name } = form.getFieldsValue();
            await updateName({ name });
            hide();
        } catch (exception) {
            message.error(exception.message);
        }
    }, [form, message, hide, updateName]);
    return (
        <Modal
            centered
            maskClosable
            title="个人信息"
            open={visible}
            onOk={onOK}
            onCancel={hide}
        >
            <Form layout="vertical"
                  form={form}
                  initialValues={{ phone, name }}
            >
                <Form.Item
                    name="phone"
                    label="手机号码"
                >
                    <Input disabled prefix={<IconPhone size={16} />} />
                </Form.Item>
                <Form.Item
                    name="name"
                    label="昵称"
                    required
                    rules={[{
                        required: true,
                        message: '请输入昵称',
                    }]}
                >
                    <Input prefix={<IconId size={16} />}
                           placeholder="为自己取一个昵称，方便同事交流" />
                </Form.Item>
            </Form>
        </Modal>
    );
};
