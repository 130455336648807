import { MessageOutlined, QuestionCircleOutlined } from '@ant-design/icons';
import { Button, Space } from 'antd';
import Account from 'views/components/Account';

export default () => {
    return (
        <Space>
            <Button type="link" icon={<MessageOutlined />}>我要吐槽</Button>
            <Button type="link" icon={<QuestionCircleOutlined />}>使用指引</Button>
            <Account />
        </Space>
    );
};
