import { Avatar } from 'antd';
import { createStyles, css } from 'antd-style';

const useStyles = createStyles({
    brand: css`
      padding-left: 1rem;
    `,

    text: css`
      font-family: Courier, monospace;
      font-size: 1.4rem;
      font-weight: 600;
      vertical-align: bottom;
      user-select: none;
    `,
});

export default () => {
    const { styles } = useStyles();
    return (
        <div className={styles.brand}>
            <Avatar src="/images/favicon.png" size="large" alt="百香云服" />
            <span className={styles.text}>百香云服</span>
        </div>
    );
};
