import { Form, Input, Select, Switch, Tag, TreeSelect } from 'antd';
import {
    useOpsDeleteEmployee,
    useOpsEmployeesPageableState,
    useOpsEmployeesPagingValue,
    useOpsSaveEmployee,
    useSetOpsEmployeesFilter,
} from 'applets/ops/Employees/state';
import {
    useOpsDepartmentsOptionsValue,
    useOpsInstitutionsOptionsValue,
    useOpsPositionsOptionsValue,
} from 'applets/ops/state';
import { useCallback, useEffect, useMemo, useState } from 'react';
import EntityTable from 'views/components/EntityTable';

const schema = [{
    key: 'id',
    dataIndex: 'id',
    title: '#',
    width: 60,
}, {
    key: 'institutionName',
    dataIndex: 'institutionName',
    title: '机构全称',
    width: 200,
}, {
    key: 'phone',
    dataIndex: 'phone',
    title: '员工手机',
    width: 200,
}, {
    key: 'name',
    dataIndex: 'name',
    title: '员工姓名',
}, {
    key: 'department',
    dataIndex: 'department',
    title: '所属部门',
    width: 200,
}, {
    key: 'position',
    dataIndex: 'position',
    title: '所属岗位',
    width: 200,
}, {
    key: 'employeed',
    dataIndex: 'employeed',
    title: '员工状态',
    width: 100,
    render: (employeed) => employeed ? (
        <Tag color="success">在职</Tag>
    ) : (
        <Tag color="warning">离职</Tag>
    ),
}, {
    key: 'updatedAt',
    dataIndex: 'updatedAt',
    title: '最后更新时间',
    width: 180,
}];

export default () => {
    const { total, items: positions } = useOpsEmployeesPagingValue();
    const setFilter = useSetOpsEmployeesFilter();
    const [pageable = 0, setPageable = null] = useOpsEmployeesPageableState();

    const [form] = Form.useForm();
    const institutionId = Form.useWatch('institutionId', form);
    const optionsInstitutions = useOpsInstitutionsOptionsValue();
    const optionsDepartments = useOpsDepartmentsOptionsValue(institutionId);
    const optionsPositions = useOpsPositionsOptionsValue(institutionId);

    const [mode, setMode] = useState(null);
    const isNewMode = useMemo(() => mode === 'new', [mode]);
    const isEditMode = useMemo(() => mode === 'edit', [mode]);
    const onNew = useCallback(() => ({ enabled: true }), []);
    const saveEmployee = useOpsSaveEmployee();
    const onSave = useCallback(async ({ id = null, institutionId = null, phone, name, departmentId, positionId, employeed, }) => {
        await saveEmployee({
            employee: {
                id,
                institutionId,
                phone,
                name,
                departmentId,
                positionId,
                enabled: employeed,
            },
        });
    }, [saveEmployee]);
    const deleteEmployee = useOpsDeleteEmployee();
    const onDelete = useCallback(async ({ id }) => {
        await deleteEmployee({ id });
    }, [deleteEmployee]);

    useEffect(() => {
        if (isNewMode) {
            form.setFieldValue('departmentId', null);
            form.setFieldValue('positionId', null);
        }
    }, [institutionId, isNewMode]);

    return (
        <EntityTable
            form={form}
            name="员工"
            columns={schema}
            total={total}
            pageable={pageable}
            setPageable={setPageable}
            items={positions}
            filters={[
                { value: 'words', label: '搜索', placeholder: '机构全称/机构简称/员工姓名/员工手机' },
            ]}
            onFilter={setFilter}
            onNew={onNew}
            onSave={onSave}
            onDelete={onDelete}
            onModeChange={setMode}
        >
            {isEditMode ? (
                <Form.Item name="id" label="员工编号">
                    <Input disabled />
                </Form.Item>
            ) : null}
            <Form.Item hidden={isEditMode} name="institutionId" label="所属机构" rules={[{ required: true }]}>
                <Select disabled={isEditMode} options={optionsInstitutions} placeholder="请选择所属机构" />
            </Form.Item>
            {isEditMode ? (
                <Form.Item name="institutionName" label="所属机构">
                    <Input disabled={isEditMode} />
                </Form.Item>
            ) : null}
            <Form.Item name="phone" label="联系方式" rules={[{ required: isNewMode }]}>
                <Input placeholder="请输入手机号码" disabled={isEditMode} />
            </Form.Item>
            <Form.Item name="name" label="员工姓名" rules={[{ required: isNewMode }]}>
                <Input placeholder="请输入员工姓名" />
            </Form.Item>
            <Form.Item name="departmentId" label="所属部门">
                {institutionId ? (
                    <TreeSelect
                        allowClear
                        treeDefaultExpandAll
                        placeholder="请选择所属部门"
                        treeData={optionsDepartments}
                    />
                ) : (
                    <Input disabled placeholder="请先选择所属机构" />
                )}
            </Form.Item>
            <Form.Item name="positionId" label="所属岗位">
                {institutionId ? (
                    <Select placeholder="请选择所属岗位" options={optionsPositions} />
                ) : (
                    <Input disabled placeholder="请先选择所属机构" />
                )}
            </Form.Item>
            <Form.Item name="employeed" label="员工状态" valuePropName="checked">
                <Switch checkedChildren="在职" unCheckedChildren="离职" />
            </Form.Item>
        </EntityTable>
    );
};
