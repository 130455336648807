import { gql } from '@apollo/client';
import { useMutate, useQueryValue } from 'helpers/graphql';
import { useMemo } from 'react';
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

const QUERY_OPS_USERS = gql`
    query OpsUsers($filter: UserFilterInput!, $pageable: PageInput!) {
        opsUsers(filter: $filter, pageable: $pageable) {
            total
            items {
                ... on UserProjection {
                    id
                    phone
                    passwordStatus
                    enabled
                    remark
                    updatedAt
                }
            }
        }
    }
`;

const filter = atom({
    key: 'ops.users.filter',
    default: {
        phone: '',
    }
});

export const useOpsUsersFilterState = () => useRecoilState(filter);
export const useOpsUsersFilterValue = () => useRecoilValue(filter);
export const useSetOpsUsersFilter = () => useSetRecoilState(filter);

const pageable = atom({
    key: 'ops.users.pageable',
    default: {
        page: 1,
        size: 50,
    }
});

export const useOpsUsersPageableState = () => useRecoilState(pageable);
export const useOpsUsersPageableValue = () => useRecoilValue(pageable);
export const useSetOpsUsersPageable = () => useSetRecoilState(pageable);

export const useOpsUsersPagingValue = () => {
    const filter = useOpsUsersFilterValue();
    const pageable = useOpsUsersPageableValue();
    const { total, items } = useQueryValue(QUERY_OPS_USERS, {
        variables: {
            filter,
            pageable,
        },
        defaultValue: {
            total: 0,
            items: [],
        },
    });
    const users = useMemo(() => items.map((item) => ({
        ...item,
        key: item.id,
    })), [items]);
    return {
        total,
        items: users,
    };
};

export const useOpsSaveUser = () => {
    const filter = useOpsUsersFilterValue();
    const pageable = useOpsUsersPageableValue();
    return useMutate(gql`
        mutation OpsSaveUser($user: UserInput!) {
            opsSaveUser(user: $user)
        }
    `, {
        refetchQueries: [{
            query: QUERY_OPS_USERS,
            variables: {
                filter,
                pageable,
            },
        }]
    });
};
