import { Result } from 'antd';
import Box from 'views/layouts/Box';

export default () => (
    <Box style={{ width: '100%', height: '100%' }} middle center>
        <Result
            status="403"
            title="403"
            subTitle="您没有访问该页面的权限，请联系管理员"
        />
    </Box>
);