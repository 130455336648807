import { useMutation, useQuery } from '@apollo/client';
import { merge } from 'helpers/function';
import { useCallback, useEffect, useMemo, useState } from 'react';

const useDocumentField = (dsl) => {
    return useMemo(() => {
        const definition = dsl.definitions[0];
        const selections = definition.selectionSet.selections;
        const isSingle = selections.length === 1;
        const field = selections[0].name.value;
        return { isSingle, field };
    }, [dsl])
};

/**
 * 增强查询结果。
 *
 * - 返回结果增加两个状态：
 *   - ready：是否就绪（已调用并且加载完成）。
 *   - success：当就绪后，是否调用成功。
 * - 添加value：未成功时，总是返回默认值。
 * - 增强error：只返回第一个GraphQLError。
 */
const useResultState = (result, defaultValue) => {
    const ready = useMemo(() => result.called && !result.loading, [result.called, result.loading]);
    const success = useMemo(() => ready && !result.error, [ready, result.error]);
    const [value, setValues] = useState(defaultValue);
    useEffect(() => {
        if (success) {
            setValues(result.data);
        }
    }, [success, result.data, setValues]);
    const error = useMemo(() => result.error?.graphQLErrors?.[0] || undefined, [result.error]);
    return {
        ...result,
        ready,
        success,
        value,
        error,
    };
};

/**
 * 增强`useQuery`。
 *
 * - 提供默认值：当状态是未调用或加载中，则返回默认值。
 *   注意，该默认值需要包含字段的名称（与useQuery的返回结果结构一致）。
 * - 返回结果增加两个状态：
 *   - ready：是否就绪（已调用并且加载完成）。
 *   - success：当就绪后，是否调用成功。
 */
export const useQueryState = (dsl, { defaultValue = null, ...options }) => {
    const result = useQuery(dsl, options);
    return useResultState(result, defaultValue);
};

/**
 * 增强`useQuery`，并且只返回结果。
 *
 * 分析DSL后，若当前请求只返回一个字段，则直接返回该字段的值；
 * 否则，返回完整的结果。
 *
 * 注意：若当前请求只返回一个字段，将自动将默认值包含在字段里。
 */
export const useQueryValue = (dsl, { defaultValue = null, ...options }) => {
    const { isSingle, field } = useDocumentField(dsl);
    const { value } = useQueryState(dsl, {
        ...options,
        defaultValue: isSingle ? {
            [field]: defaultValue,
        } : defaultValue,
    });
    return useMemo(() => (
        isSingle ? value[field] : value
    ), [isSingle, field, value]);
};

/**
 * 增强`useQuery`。
 *
 * - 提供默认值：当状态是未调用或加载中，则返回默认值。
 *   注意，该默认值需要包含字段的名称（与useQuery的返回结果结构一致）。
 * - 返回结果增加两个状态：
 *   - ready：是否就绪（已调用并且加载完成）。
 *   - success：当就绪后，是否调用成功。
 */
export const useMutationState = (dsl, { defaultValue = null, ...options }) => {
    const [fn, result] = useMutation(dsl, options);
    const mutate = useCallback(async (variables = undefined, otherOptions = {}) => (
        await fn({
            ...merge(options, otherOptions),
            variables,
        })
    ), [fn, options]);
    const state = useResultState(result, defaultValue);
    return {
        ...state,
        mutate,
    };
};

/**
 * 增强`useMutation`。
 *
 * 分析DSL后，若当前请求只返回一个字段，则直接返回该字段的值；
 * 否则，返回完整的结果。
 *
 * 注意：若当前请求只返回一个字段，将自动将默认值包含在字段里。
 */
export const useMutationValue = (dsl, { defaultValue = null, ...options }) => {
    const { isSingle, field } = useDocumentField(dsl);
    const { value, ...state } = useMutationState(dsl, {
        ...options,
        defaultValue: isSingle ? {
            [field]: defaultValue,
        } : defaultValue,
    });
    return useMemo(() => ({
        ...state,
        value: isSingle ? value[field] : value,
    }), [isSingle, field, value, state]);
};

/**
 * 仅返回Mutation请求的调用函数。
 *
 * 当不在意请求返回的结果时，可以采用该Hooks。
 */
export const useMutate = (dsl, options = {}) => {
    const { isSingle, field } = useDocumentField(dsl);
    const { mutate } = useMutationState(dsl, options);
    return useCallback(async (variables = undefined, options = {}) => {
        try {
            const { data } = await mutate(variables, options);
            return isSingle && data ? data[field] : data;
        } catch (exception) {
            if (exception?.graphQLErrors?.length) {
                throw exception.graphQLErrors[0];
            } else {
                throw exception;
            }
        }
    }, [isSingle, field, mutate]);
};
