import { gql } from '@apollo/client';
import { useMutate } from 'helpers/graphql';
import { useRecoilBoolean } from 'helpers/hooks';
import { useCallback } from 'react';
import { atom, useRecoilValue } from 'recoil';
import { useSignOff } from 'snapshots/session';
import { QUERY_SHELL_USER } from 'snapshots/shell';

// Profile

const dialogProfileVisible = atom({
    key: 'shell.dialog.profile.visible',
    default: false,
});

export const useDialogProfileVisibleValue = () => useRecoilValue(dialogProfileVisible);

export const useDialogProfileVisible = () => useRecoilBoolean(dialogProfileVisible);

export const useSetProfileName = () => useMutate(gql`
    mutation UpdateName($name: String!) {
        updateName(name: $name)
    }
`, {
    refetchQueries: [
        QUERY_SHELL_USER
    ]
});

// Password Reset

const dialogPasswordVisible = atom({
    key: 'shell.dialog.password.visible',
    default: false,
});

export const useDialogPasswordVisibleValue = () => useRecoilValue(dialogPasswordVisible);

export const useDialogPasswordVisible = () => useRecoilBoolean(dialogPasswordVisible);

export const useSetProfilePassword = () => useMutate(gql`
    mutation UpdatePassword($originPassword: String!, $newPassword: String!) {
        updatePassword(originPassword: $originPassword, newPassword: $newPassword)
    }
`);

export const useUpdatePasswordAndLogout = () => {
    const setPassword = useSetProfilePassword();
    const signOff = useSignOff();
    return useCallback(async (variables) => {
        await setPassword(variables);
        await signOff();
    }, [setPassword, signOff]);
};
