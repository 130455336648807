import { createStyles, css } from 'antd-style';
import Flex from 'views/layouts/Flex';

const useStyles = createStyles({
    box: css`
      display: inline-flex;
      flex-wrap: wrap;
    `
})

export default ({ className, children, ...props }) => {
    const { styles, cx } = useStyles();
    return <Flex className={cx(styles.box, className)} direction="horizontal" {...props}>{children}</Flex>
};
