import { Spin } from 'antd';
import Box from 'views/layouts/Box';
import Viewport from 'views/layouts/Viewport';

export default () => (
    <Viewport>
        <Box style={{ width: '100%', height: '100%' }} center middle>
            <Spin size="large" />
        </Box>
    </Viewport>
);
