import { LinkOutlined, ShareAltOutlined, StarOutlined } from '@ant-design/icons';
import { App, Avatar, Button, Input, List, Pagination, QRCode, Space, Table, Tag, Typography } from 'antd';
import { createStyles, css } from 'antd-style';
import {
    useOpsProductsPageableState,
    useOpsProductsTotalValue,
    useOpsProductsPagingValue,
    useSetOpsProductsFilter,
} from 'applets/ops/Products/state';
import { useCallback, useMemo } from 'react';
import Columns, { Content as ColumnsContent, Right } from 'views/layouts/Columns';
import Rows, { Content as RowsContent, Footer, Header } from 'views/layouts/Rows';

const useStyles = createStyles({
    products: css`
      width: 100%;
    `,
    filter: css`
        width: 400px;
    `,
    commissions: css`
      width: 40vw;
    `,
    links: css`
      width: 160px;
    `,
    side: css`
      height: 3rem;
    `,
});

const Commissions = ({ commissions }) => {
    const { styles } = useStyles();
    // const years = useMemo(() => Math.max(3, Math.max.apply(null, commissions.flatMap(({ terms }) => terms.flatMap(({ rates }) => rates.map(({ term }) => (
    //     /\d+/.test(term) ? parseInt(term) : 0
    // )))))), [commissions]);
    const years = 3;
    const columns = useMemo(() => {
        const schema = [{
            key: 'grade',
            dataIndex: 'grade',
            title: '佣金维度',
            width: 150,
            onCell: ({ span }) => ({
                rowSpan: span
            }),
        }, {
            key: 'terms',
            dataIndex: 'terms',
            title: '交费年限',
            width: 150,
        }];
        for (let term = 1; term <= years; term++) {
            schema.push({
                key: `rate-${term}`,
                dataIndex: `rate-${term}`,
                title: `第${term}年`,
                width: 100,
            });
        }
        return schema;
    }, [years]);
    const records = useMemo(() => commissions.flatMap(({ grade, terms }) => terms.map(({ name, rates }, index) => {
        const record = rates.reduce((record, { rate, term }) => ({
            ...record,
            [`rate-${term}`]: `${rate}%`,
        }), {
            key: `${grade}-${name}-${index}`,
            grade,
            span: index ? 0 : terms.length,
            terms: name,
        });
        for (let term = rates.length + 1; term <= years; term++) {
            record[`rate-${term}`] = '';
        }
        return record;
    })), [commissions, years]);
    return (
        <Table className={styles.commissions}
               bordered
               pagination={false}
               size="small"
               columns={columns}
               dataSource={records}
        />
    );
};

const Product = ({
    data: {
        channel,
        name,
        url,
        companyName,
        scene,
        categories,
        features,
        coolingOff,
        commissions,
    },
}) => {
    const { styles } = useStyles();
    const { modal } = App.useApp();
    const sceneColor = useMemo(() => {
        switch (scene) {
            case '网销':
                return 'success';
            case '经代':
                return 'warning';
            case '电销':
                return 'danger';
            default:
                return 'default';
        }
    }, [scene]);
    const onQrcodeClick = useCallback(() => {
        modal.info({
            title: `扫描分享${name}`,
            content: <QRCode value={url} />,
        });
    }, [modal, name, url]);
    return (
        <List.Item
            actions={[
                <Space>
                    <StarOutlined />
                    {`冷静期：${coolingOff}天`}
                </Space>,
            ]}
            extra={
                <Columns>
                    <ColumnsContent>
                        <Commissions commissions={commissions} />
                    </ColumnsContent>
                    <Right className={styles.links} center top>
                        <Space direction="vertical">
                            <Button icon={<ShareAltOutlined />} type="primary" onClick={onQrcodeClick}>
                                扫码分享
                            </Button>
                            <Button icon={<LinkOutlined />} type="default">
                                <a href={url} target="_blank">投保链接</a>
                            </Button>
                        </Space>
                    </Right>
                </Columns>
            }
        >
            <List.Item.Meta
                avatar={<Avatar src={`/channels/${channel}.png`} />}
                title={`【${companyName}】${name}`}
                description={
                    <Space>
                        <Tag color={sceneColor}>{scene}</Tag>
                        {categories.map((category, index) => <Tag key={index}>{category}</Tag>)}
                    </Space>
                }
            />
            <Typography.Paragraph>
                <ul>{features.map((feature, index) => <li key={index}>{feature}</li>)}</ul>
            </Typography.Paragraph>
        </List.Item>
    );
};

export default () => {
    const { styles } = useStyles();
    const total = useOpsProductsTotalValue();
    const products = useOpsProductsPagingValue();
    const [pageable, setPageable] = useOpsProductsPageableState();
    const setFilter = useSetOpsProductsFilter();
    const onSearch = useCallback((words) => {
        setFilter({ words });
    }, [setFilter]);
    const onPagination = useCallback((page, size) => {
        setPageable({ page, size });
    }, [setPageable]);

    return (
        <Rows>
            <Header className={styles.side} bordered left top>
                <Input.Search rootClassName={styles.filter}
                              allowClear
                              placeholder="输入产品名称搜索"
                              onSearch={onSearch}
                />
            </Header>
            <RowsContent>
                <List
                    className={styles.products}
                    itemLayout="vertical"
                    size="large"
                    dataSource={products}
                    renderItem={(product) => <Product data={product} />}
                />
            </RowsContent>
            <Footer className={styles.side} bordered right bottom>
                <Pagination
                    showSizeChanger
                    showQuickJumper
                    current={pageable.page}
                    pageSize={pageable.size}
                    total={total}
                    onChange={onPagination}
                />
            </Footer>
        </Rows>
    );
};
