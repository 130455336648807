import { Form, Input, Select, Switch, Tag, TreeSelect } from 'antd';
import {
    useHrmDeleteEmployee,
    useHrmEmployeesPageableState,
    useHrmEmployeesPagingValue,
    useHrmSaveEmployee,
    useSetHrmEmployeesFilter,
} from 'applets/hrm/Employees/state';
import { useHrmDepartmentsOptionsValue, useHrmPositionsOptionsValue } from 'applets/hrm/state';
import { useCallback, useMemo, useState } from 'react';
import EntityTable from 'views/components/EntityTable';

const schema = [{
    key: 'id',
    dataIndex: 'id',
    title: '#',
    width: 60,
}, {
    key: 'phone',
    dataIndex: 'phone',
    title: '员工手机',
    width: 200,
}, {
    key: 'name',
    dataIndex: 'name',
    title: '员工姓名',
}, {
    key: 'department',
    dataIndex: 'department',
    title: '所属部门',
    width: 200,
}, {
    key: 'position',
    dataIndex: 'position',
    title: '所属岗位',
    width: 200,
}, {
    key: 'employeed',
    dataIndex: 'employeed',
    title: '员工状态',
    width: 100,
    render: (employeed) => employeed ? (
        <Tag color="success">在职</Tag>
    ) : (
        <Tag color="warning">离职</Tag>
    ),
}, {
    key: 'updatedAt',
    dataIndex: 'updatedAt',
    title: '最后更新时间',
    width: 180,
}];

export default () => {
    const { total, items: positions } = useHrmEmployeesPagingValue();
    const setFilter = useSetHrmEmployeesFilter();
    const [pageable = 0, setPageable = null] = useHrmEmployeesPageableState();

    const [form] = Form.useForm();
    const optionsDepartments = useHrmDepartmentsOptionsValue();
    const optionsPositions = useHrmPositionsOptionsValue();

    const [mode, setMode] = useState(null);
    const isNewMode = useMemo(() => mode === 'new', [mode]);
    const isEditMode = useMemo(() => mode === 'edit', [mode]);
    const onNew = useCallback(() => ({ enabled: true }), []);
    const saveEmployee = useHrmSaveEmployee();
    const onSave = useCallback(async ({ id = null, phone, name, departmentId, positionId, employeed }) => {
        await saveEmployee({
            employee: {
                id,
                phone,
                name,
                departmentId,
                positionId,
                enabled: employeed,
            },
        });
    }, [saveEmployee]);
    const deleteEmployee = useHrmDeleteEmployee();
    const onDelete = useCallback(async ({ id }) => {
        await deleteEmployee({ id });
    }, [deleteEmployee]);

    return (
        <EntityTable
            form={form}
            name="员工"
            columns={schema}
            total={total}
            pageable={pageable}
            setPageable={setPageable}
            items={positions}
            filters={[
                { value: 'words', label: '搜索', placeholder: '员工姓名/员工手机' },
            ]}
            onFilter={setFilter}
            onNew={onNew}
            onSave={onSave}
            onDelete={onDelete}
            onModeChange={setMode}
        >
            {isEditMode ? (
                <Form.Item name="id" label="员工编号">
                    <Input disabled />
                </Form.Item>
            ) : null}
            <Form.Item name="phone" label="联系方式" rules={[{ required: isNewMode }]}>
                <Input placeholder="请输入手机号码" disabled={isEditMode} />
            </Form.Item>
            <Form.Item name="name" label="员工姓名" rules={[{ required: isNewMode }]}>
                <Input placeholder="请输入员工姓名" />
            </Form.Item>
            <Form.Item name="departmentId" label="所属部门">
                <TreeSelect
                    allowClear
                    treeDefaultExpandAll
                    placeholder="请选择所属部门"
                    treeData={optionsDepartments}
                />
            </Form.Item>
            <Form.Item name="positionId" label="所属岗位">
                <Select placeholder="请选择所属岗位" options={optionsPositions} />
            </Form.Item>
            <Form.Item name="employeed" label="员工状态" valuePropName="checked">
                <Switch checkedChildren="在职" unCheckedChildren="离职" />
            </Form.Item>
        </EntityTable>
    );
};
